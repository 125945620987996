import React from 'react';
import './containerblank.css';
import adminQuickReplies from '../../../assets/images/quick-replies-admin.png';

class ContainerBlank extends React.Component {
	render() {
		return (
			<div className="admin-replies__container-replies">
				<img src={adminQuickReplies} alt="img-default" className="responsive-img" />
				<p>
					Si deseas configurar las respuestas rapidas para el analista N1, realiza una búsqueda por cliente en
					la barra izquierda
				</p>
			</div>
		);
	}
}

export default ContainerBlank;
