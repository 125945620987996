import React from 'react';
import './itemAssignCompany.css';
import { connect } from 'react-redux';
import { getCompanies } from '../../../appRedux/actions/Companies';
import { getRoles } from '../../../appRedux/actions/Parameters';
import ItemRoles from '../ItemRoles/ItemRoles';

import ReactSearchBox from 'react-search-box';

class ItemAssignCompany extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			company_id: '',
			company_name: '',
			manager: '',
			analyst: '',
			admin: '',
			roles: []
		};
		this.myCheckbox = React.createRef();
	}

	componentWillReceiveProps( newProps ) {
		this.setState({
			roles: newProps.value.roles,
			company_id: newProps.value.company_id,
			company_name: newProps.value.company_name
		});

	}

	componentWillMount() {
		this.props.getCompanies();
		this.props.getRoles();
		const { value } = this.props;
		this.setState({
			roles: value.roles,
			company_id: value.company_id,
			company_name: value.company_name
		});
	}

	createUI(){
		const {  onChange } = this.props;
		return this.state.roles.map((el, i) => 
			<div key={i}>
				<ItemRoles key={i} value={el||''} onChange={onChange}/>
			</div>          
		)
	}

	render() {
		const { listJsonAssignCompanies, allCompanies, onChange, onClick , handleChangeCompany, value } = this.props;
		let listsRoles = this.state;
		let listCompanies = [];
		const refs = this;
		if (allCompanies) {
			allCompanies.map(function(item, i) {
				listCompanies.push(
					<option key={item.company_uuid} value={item.name} defaultValue={item.name}>
						{item.name}
					</option>
				);
				
			});
		}

		return (
			<div className="row">
				<div className="input-field col s12 m4">
					<div className="input-field-label">Empresa asignada {this.state.company_name}</div>
				</div>
				<div className="col s12 m4">
					<div className="input-field-common input-field select-search-float">
					      <select
								className="browser-default admin-parameters__client-default"
								onChange={handleChangeCompany}
								name="company_name"
							    value={this.state.company_name}
							>
								<option className="" datacompany="" value="" disabled selected>
									Seleccione una empresa
								</option>
						   {listCompanies}
							</select>
					</div>
				</div>
				<div className="col s12 m4">
					<div className="row">
						{this.createUI()} 
						<div className="col s12">
							<a
								id={this.state.company_id}
								className="waves-effect waves-light btn modal-trigger btn-line-royal f-right"
								onClick={onClick}>
								Desactivar asignación
							</a>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = ({ auth, companies, users , parameters}) => {
	const { authUser } = auth;
	const { allCompanies } = companies;
	const { containerUserDetail } = users;
	const { roles } = parameters;
	return {
		authUser,
		allCompanies,
		containerUserDetail,
		roles
	};
};

export default connect(mapStateToProps, {
	getCompanies,
	getRoles
})(ItemAssignCompany);
