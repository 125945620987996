import React from 'react';
import { connect } from 'react-redux';

import './itemTicket.css';
import closeInput from '../../../assets/images/close-input.png';

class ItemTicket extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { value, handleDelete, handleChangeInput } = this.props;
		let refs = this;
		let valorValue;

		valorValue = value;
		return (
			<div className="admin-parameters_ticket">
				<input onChange={handleChangeInput} value={valorValue ? valorValue : ''} className="input-default" />

				<img onClick={handleDelete} src={closeInput} alt="img-close" className="responsive-img img-close" />
			</div>
		);
	}
}

const mapStateToProps = ({ auth, users, parameters }) => {
	const { authUser } = auth;

	return {
		authUser
	};
};

export default connect(mapStateToProps, {})(ItemTicket);
