// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';
export const WINDOW_WIDTH = 'WINDOW-WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE';

//Contact Module const
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';

//Auth const
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNIN_GOOGLE_USER = 'SIGNIN_GOOGLE_USER';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'SIGNIN_GOOGLE_USER_SUCCESS';
export const SIGNIN_FACEBOOK_USER = 'SIGNIN_FACEBOOK_USER';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'SIGNIN_FACEBOOK_USER_SUCCESS';
export const SIGNIN_TWITTER_USER = 'SIGNIN_TWITTER_USER';
export const SIGNIN_TWITTER_USER_SUCCESS = 'SIGNIN_TWITTER_USER_SUCCESS';
export const SIGNIN_GITHUB_USER = 'SIGNIN_GITHUB_USER';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_success';
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const INIT_URL = 'INIT_URL';
export const USER_DATA = 'user_data';
export const USER_TOKEN_SET = 'user_token_set';
export const STATUS_CODE = 'status_code';

export const CONVERSATION_INFO = 'CONVERSATION_INFO';
export const ATTEND_CONVERSATION = 'ATTEND_CONVERSATION';
export const ADD_CONVERSATION = 'ADD_CONVERSATION';
export const INIT_CONVERSATIONS = 'INIT_CONVERSATIONS';
export const SHOW_CONVERSATION = 'SHOW_CONVERSATION';
export const INIT_QUICK_REPLIES = 'INIT_QUICK_REPLIES';
export const INIT_AUTO_GREETING = 'INIT_AUTO_GREETING';
export const INIT_WELCOME_GREETING = 'INIT_WELCOME_GREETING';
export const ADD_MESSAGE = 'ADD_MESSAGE';
export const ADD_CONNECTION = 'ADD_CONNECTION';
export const INIT_MESSAGE = 'INIT_MESSAGE';
export const START_TYPING = 'START_TYPING';
export const STOP_TYPING = 'STOP_TYPING';
export const INCREMENT = 'INCREMENT';
export const RESET = 'RESET';
export const TIMER = 'TIMER';
export const STOP_TIMER = 'STOP_TIMER';
export const SET_INPUT = 'SET_INPUT';
export const REMOVE_CONNECTION = 'REMOVE_CONNECTION';
export const REMOVE_CONVERSATION = 'REMOVE_CONVERSATION';
export const HIDE_CONVERSATION = 'HIDE_CONVERSATION';
export const GET_AUTO_GREETING = 'GET_AUTO_GREETING';
export const GET_WELCOME_GREETING = 'GET_WELCOME_GREETING';
export const SET_NOTIFY_SOUND = 'SET_NOTIFY_SOUND';
export const SHOW_EMOJI = 'SHOW_EMOJI';
export const UPDATE_MESSAGE = 'UPDATE_MESSAGE';
export const INIT_PARAMETERS = 'INIT_PARAMETERS';
export const SUCCESS_UPLOAD = 'SUCCESS_UPLOAD';
export const LINK_TICKETS = 'LINK_TICKETS';
export const SHOW_THRESHOLDS = 'SHOW_THRESHOLDS';
export const LOADING = 'LOADING';
export const LOAD_USERS_LOADING = 'LOAD_USERS_LOADING';
export const LOAD_USERS_SUCCESS = 'LOAD_USERS_SUCCESS';
export const LOAD_USERS_ERROR = 'LOAD_USERS_ERROR';
export const INIT_HISTORIAL = '	INIT_HISTORIAL';
export const PERMISSIONS = 'PERMISSIONS';
export const ADD_HISTORIAL = 'ADD_HISTORIAL';
export const LOAD_HISTORIAL = 'LOAD_HISTORIAL';
export const LOAD_SUCESS_HISTORIAL = 'LOAD_SUCESS_HISTORIAL';
export const CLEAN_HISTORIAL = 'CLEAN_HISTORIAL';
export const ASSIGNED_COMPANIES = ' ASSIGNED_COMPANIES';
export const MANAGER = 'MANAGER';
export const SHOW_DETAILS_REPLIES = 'SHOW_DETAILS_REPLIES';
export const REPLIES_WELCOME = 'REPLIES_WELCOME';
export const ADMIN_QUICK_REPLIES = 'ADMIN_QUICK_REPLIES';
export const ADMIN_AUTO_GREETING = 'ADMIN_AUTO_GREETING';
export const SHOW_ELEMENT = 'SHOW_ELEMENT';
export const REMOVE_REPLY = 'REMOVE_REPLY';
export const SHOW_OTHER_ELEMENT = 'SHOW_OTHER_ELEMENT';
export const QUICK_REPLY_GREETING = 'QUICK_REPLY_GREETING';
export const ADD_QUICK_REPLIES = 'ADD_QUICK_REPLIES';
export const ADD_AUTO_GREETING = 'ADD_AUTO_GREETING';
export const ADD_REPLY_WELCOME = 'ADD_REPLY_WELCOME';
export const MESSAGE_DASHBOARD = 'MESSAGE_DASHBOARD';
export const INIT_AGENT_DASHBOARD ='INIT_AGENT_DASHBOARD';
export const INIT_MESSAGE_DASHBOARD = 'INIT_MESSAGE_DASHBOARD';
export const ADD_MESSAGE_DASHBOARD = 'ADD_MESSAGE_DASHBOARD';
export const REMOVE_MESSAGE_DASHBOARD = 'REMOVE_MESSAGE_DASHBOARD';
export const REMOVE_MESSAGE_DASHBOARDS = 'REMOVE_MESSAGE_DASHBOARDS';
export const CANCEL_MESSAGE_DASHBOARD = 'CANCEL_MESSAGE_DASHBOARD';
export const ADD_CANCEL_MESSAGE_DASHBOARD = 'ADD_CANCEL_MESSAGE_DASHBOARD';
export const DATA_CANCEL_DASHBOARD = 'DATA_CANCEL_DASHBOARD';
export const SHOW_ADMIN_REPLIES = 'SHOW_ADMIN_REPLIES';
export const SHOW_THRESHOLDS_MANAGER = 'SHOW_THRESHOLDS_MANAGER';
export const SHOW_THRESHOLDS_MANAGERS = 'SHOW_THRESHOLDS_MANAGERS';
export const ASSIGNED_USERS = 'ASSIGNED_USERS';
export const SHOW_USER_DETAIL = 'SHOW_USER_DETAIL';
export const HIDE_USER_DETAIL = 'HIDE_USER_DETAIL';
export const ALL_COMPANIES = 'ALL_COMPANIES';

//
export const SHOW_ROLES = 'SHOW_ROLES';
export const SHOW_PLATFORMS = 'SHOW_PLATFORMS';
export const ASSIGNED_ROLES_CHECK = 'ASSIGNED_ROLES_CHECK';
export const ITEM_SESSION = 'ITEM_SESSION';
export const SHOW_ALL_COMPANIES = 'SHOW_ALL_COMPANIES';
export const THRESHOLD_COMPANY = 'THRESHOLD_COMPANY';
export const PARAMETERS_WATSON = 'PARAMETERS_WATSON';
export const SHOW_PLATFORMS_DEFAULT = 'SHOW_PLATFORMS_DEFAULT';
export const SHOW_PLATFORMS_COMPANY = 'SHOW_PLATFORMS_COMPANY';
export const PARAMETERS_PLATFORM = 'PARAMETERS_PLATFORM';
export const PARAMETERS_STATE_TICKETS = 'PARAMETERS_STATE_TICKETS';
export const PARAMETERS_PSS = 'PARAMETERS_PSS';
export const PARAMETERS_API = 'PARAMETERS_API';
export const PARAMETERS_PLATFORM_STATES = 'PARAMETERS_PLATFORM_STATES';

export const LOAD_LOADING = 'LOAD_LOADING';
export const LOAD_SUCCESS = 'LOAD_SUCCESS';
export const LOAD_ERROR = 'LOAD_ERROR';

export const INIT_MESSAGE_AGENTE_STATUS = 'INIT_MESSAGE_AGENTE_STATUS';
