import React from 'react';
import { connect } from 'react-redux';

import './parametersPSS.css';

import { getAllCompanies, getParametersPSS, putParametersPSS } from '../../../../appRedux/actions/Parameters';
import ItemParamPSS from '../ItemParamPSS/ItemParamPSS';
import Modal from '../../Modal/Modal';

class ConfigurationWatson extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			listCompanies: [],
			parametersPSS: [],
			valueCompanyId: ''
		};
	}

	async componentWillMount() {
		await this.props.getAllCompanies();
		const { listAllCompanies } = this.props;
		let initialCompanies = [];
		initialCompanies = listAllCompanies.map((company) => {
			return company;
		});
		this.setState({
			listCompanies: initialCompanies
		});
	}

	componentWillReceiveProps(newProps) {
		let ref = this;
		this.setState({
			parametersPSS: newProps.parametersPSS
		});
	}

	//change company
	async handleChangePSS(event) {
		this.setState({
			showButtonSave: true,
			parametersPSS: []
		});


		let company_id = event.target.value;
		await this.props.getParametersPSS(company_id);
		let { parametersPSS } = this.props;
		this.setState({
			parametersPSS: parametersPSS,
			valueCompanyId: company_id
		});
	}

	handleCloseModal() {
		this.setState({
			showModal: false
		});
	}
	//save
	handleSaveParemetersPSS(event) {
		event.preventDefault();
		this.setState({
			showModal: true
		});
		let datafilter = this.state.parametersPSS;
		let groupData = [];
		let dataItem;
		let ref = this;
		let company_id = ref.state.valueCompanyId;
		datafilter.map(function(items) {
			items.map(function(item) {
				if (item.param_value !== '') {
					dataItem = {
						param_id: item.param_id,
						label: item.param_label,
						value: item.param_value,
						platform_id: null
					};
					groupData.push(dataItem);
				}

				item.params.map(function(ite) {
					if (
						(ite.param_label === '' && ite.param_value === '') ||
						(ite.param_label === null && ite.param_value === null)
					) {
					} else {
						dataItem = {
							param_id: ite.param_id,
							label: ite.param_label,
							value: ite.param_value,
							platform_id: null
						};

						groupData.push(dataItem);
					}
				});

				item.headers.map(function(ite) {
					if (
						(ite.param_label === '' && ite.param_value === '') ||
						(ite.param_label === null && ite.param_value === null)
					) {
					} else {
						dataItem = {
							param_id: ite.param_id,
							label: ite.param_label,
							value: ite.param_value,
							platform_id: null
						};

						groupData.push(dataItem);
					}
				});
			});
		});
		this.props.putParametersPSS(company_id, groupData);
	}

	addClick(params, enspointId, name) {
		let parametersPSS = [ ...this.state.parametersPSS ];
		for (var i in parametersPSS) {
			for (var j in parametersPSS[i]) {
				if (parametersPSS[i][j].param_id === params[0].endpointId) {
					parametersPSS[i][j].params = params;
				}
			}
		}
		this.setState({ parametersPSS });
	}

	addHeaderClick(params, enspointId, name) {
		let parametersPSS = [ ...this.state.parametersPSS ];
		for (var i in parametersPSS) {
			for (var j in parametersPSS[i]) {
				if (parametersPSS[i][j].param_id === params[0].endpointId) {
					parametersPSS[i][j].headers = params;
				}
			}
		}
		this.setState({ parametersPSS });
	}

	removeClick(params, endpointId, name) {
		let parametersPSS = [ ...this.state.parametersPSS ];
		for (var i in parametersPSS) {
			for (var j in parametersPSS[i]) {
				if (parametersPSS[i][j].param_id === endpointId) {
					if (name === 'params') {
						parametersPSS[i][j].params = params;
					} else {
						parametersPSS[i][j].headers = params;
					}
				}
			}
		}
		this.setState({ parametersPSS });
	}

	handleChange(id, param_id, states, name) {
		let parametersPSS = [ ...this.state.parametersPSS ];
		for (var i in parametersPSS) {
			for (var j in parametersPSS[i]) {
				if (parametersPSS[i][j].param_id === param_id) {
					if (name === 'params') {
						parametersPSS[i][j].params = states;
					} else {
						parametersPSS[i][j].headers = states;
					}
				}
			}
		}
		this.setState({ parametersPSS });
	}

	handleChangeURL(states) {
		let parametersPSS = [ ...this.state.parametersPSS ];
		for (var i in parametersPSS) {
			for (var j in parametersPSS[i]) {
				if (parametersPSS[i][j].param_id === states.param_id) {
					parametersPSS[i][j].param_value = states.param_value;
				}
			}
		}
		this.setState({ parametersPSS });
	}

	createUI() {
		return this.state.parametersPSS.map((el, i) => (
			<div key={i}>
				{el.map((els, j) => (
					<div key={j}>
						<ItemParamPSS
							key={j}
							handleChangeURL={this.handleChangeURL.bind(this)}
							handleChange={this.handleChange.bind(this, i)}
							endpointId={els.param_id}
							value={els || ''}
							removeClick={(i, paramState, type) => {
								this.removeClick(i, paramState, type);
							}}
							addClick={(paramState) => {
								this.addClick(paramState);
							}}
							addHeaderClick={(paramState) => {
								this.addHeaderClick(paramState);
							}}
						/>
					</div>
				))}
			</div>
		));
	}

	render() {
		const { listAllCompanies, listParametersWatson, loadingSave, saveSuccessuful, saveError } = this.props;
		let optionsWatson = [];
		let optionsCompanies = [];
		if (this.state.listCompanies.length > 0) {
			this.state.listCompanies.map(function(itemCompany) {
				optionsCompanies.push(
					<option key={itemCompany.id} value={itemCompany.company_uuid} defaultValue={itemCompany.name}>
						{itemCompany.name}
					</option>
				);
			});
		}

		let ref = this;

		optionsWatson.push(<div>hola</div>);

		return (
			<form
				onSubmit={(e) => {
					this.handleSaveParemetersPSS(e);
				}}
			>
				<div className="admin-parameters_container-session ">
					<div className="admin-parameters__session-header">
						<p className="admin-parameters__session-title">Parámetros PSS</p>

						{this.state.showButtonSave && (
							<div>
								<input
									className="btn waves-effect admin-parameters__btn-save-input position-btn"
									type="submit"
									value="Guardar"
								/>
							</div>
						)}
						<Modal
							showModal={this.state.showModal}
							loadingSave={loadingSave}
							saveSuccessuful={saveSuccessuful}
							saveError={saveError}
							handleCloseModal={(event) => {
								ref.handleCloseModal(event);
							}}
						/>
					</div>
					<div className="admin-parameters__background">
						<div className=" admin-parameters__container-select">
							<select
								className="browser-default admin-parameters__client-default"
								onChange={(event) => this.handleChangePSS(event)}
							>
								<option className="" datacompany="" value="" disabled selected>
									Seleccione una empresa
								</option>
								{optionsCompanies}
							</select>
						</div>
					</div>
					<div className="admin-parameters__container-thresholds">
						{this.state.showResults ? optionsWatson : this.createUI()}
					</div>
				</div>
			</form>
		);
	}
}

const mapStateToProps = ({ auth, users, parameters, conversation }) => {
	const { authUser } = auth;
	const { listAllCompanies, parametersPSS, loadingSave, saveSuccessuful, saveError } = parameters;

	return {
		authUser,
		listAllCompanies,
		parametersPSS,
		loadingSave,
		saveSuccessuful,
		saveError
	};
};

export default connect(mapStateToProps, { getAllCompanies, getParametersPSS, putParametersPSS })(ConfigurationWatson);
