import React, { Component } from 'react';
import { connect } from 'react-redux';
/*import URLSearchParams from 'url-search-params'*/
import { Redirect, Route, Switch } from 'react-router-dom';
import '@babel/polyfill';
import 'core-js/es/map';
import 'core-js/es/set';
import 'raf/polyfill';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import { LocaleProvider } from 'antd';
import { IntlProvider } from 'react-intl';

import AppLocale from 'lngProvider';
import MainApp from './MainApp';
import SignIn from '../SignIn/SignIn';

import { setInitUrl } from 'appRedux/actions/Auth';
import { onLayoutTypeChange, onNavStyleChange, setThemeType } from 'appRedux/actions/Setting';
import { getParameters, getMessages } from '../../appRedux/actions/Chat';
import { getThresholds } from '../../appRedux/actions/Conversation';
import axios from 'util/Api';

import { getUser } from '../../appRedux/actions/Auth';

const RestrictedRoute = ({ component: Component, token, authUser, statusCode, ...rest }) => (
	<Route
		{...rest}
		render={(props) =>
			token && authUser ? (
				<Component {...props} />
			) : (
				<Redirect
					to={{
						pathname: '/signin',
						state: { from: props.location }
					}}
				/>
			)}
	/>
);

class App extends Component {
	componentWillMount() {
		if (this.props.initURL === '') {
			this.props.setInitUrl(this.props.history.location.pathname);
		}
	}
	componentDidMount() {
		window.history.pushState(null, document.title, window.location.href);
		window.addEventListener('popstate', function(event) {
			window.history.pushState(null, document.title, window.location.href);
		});
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.token) {
			axios.defaults.headers.common['Authorization'] = 'Bearer ' + nextProps.token;
		} else if (localStorage.getItem('token')) {
			axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');
			localStorage.getItem('user');
		}

		if (nextProps.token && !nextProps.authUser) {
			this.props.getUser();
		}
	}

	render() {
		const { match, location, authUser, locale, token, initURL, statusCode } = this.props;

		if (location.pathname === '/') {
			if (token === null) {
				return <Redirect to={'/signin'} />;
			} else if (initURL === '' || initURL === '/' || initURL === '/signin') {
				return <Redirect to={'/main'} />;
			} else {
				return <Redirect to={initURL} />;
			}
		}

		const currentAppLocale = AppLocale[locale.locale];
		return (
			<LocaleProvider locale={currentAppLocale.antd}>
				<IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
					<Switch>
						<Route exact path="/signin" statusCode={statusCode} component={SignIn} />
						<RestrictedRoute path={`${match.url}`} token={token} authUser={authUser} component={MainApp} />
					</Switch>
				</IntlProvider>
			</LocaleProvider>
		);
	}
}

const mapStateToProps = ({ settings, auth }) => {
	const { locale, navStyle, themeType, layoutType } = settings;
	const { authUser, token, initURL, statusCode } = auth;
	return {
		locale,
		token,
		navStyle,
		themeType,
		layoutType,
		authUser,
		initURL,
		statusCode
	};
};
export default connect(mapStateToProps, {
	setInitUrl,
	getUser,
	getParameters,
	getThresholds,
	setThemeType,
	onNavStyleChange,
	onLayoutTypeChange
})(App);
