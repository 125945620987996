import React from "react";
import BoxRequest from "../BoxRequest/BoxRequest";
import { connect } from "react-redux";
import ContainerItemChat from "../ContainerItemChat/ContainerItemChat";
import {
  connectSocketsN1,
  removeAllSocket,
  disconnectSocket
} from "../../../appRedux/actions/Socket";
import {
  getConversationInfo,
  getConversations,
  getThresholds
} from "../../../appRedux/actions/Conversation";
import { getParameters, getMessages } from "../../../appRedux/actions/Chat";
import { ALL } from "../../../constants/Constants";
import "./containerList.css";

class ContainerList extends React.Component {
  async componentWillMount() {
    const { authUser } = this.props;
    await this.props.removeAllSocket();
    this.props.connectSocketsN1();
    this.props.getParameters(ALL);
    this.props.getThresholds();
    this.props.getConversationInfo();
    this.props.getConversations(authUser.id);
    this.props.getMessages(authUser.id);
  }

  componentWillUnmount() {
    this.props.disconnectSocket();
  }

  render() {
    return (
      <div>
        <BoxRequest />
        <ContainerItemChat />
      </div>
    );
  }
}

const mapStateToProps = ({ socket, conversation, auth }) => {
  const { authUser } = auth;
  return { authUser };
};

export default connect(mapStateToProps, {
  connectSocketsN1,
  removeAllSocket,
  getConversationInfo,
  getConversations,
  getParameters,
  getThresholds,
  getMessages,
  disconnectSocket
})(ContainerList);
