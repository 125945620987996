import React from "react";
import "./navbar.css";
import logo from "../../assets/images/logo.png";
import iconSound from "../../assets/images/sound.png";
import silenceSound from "../../assets/images/silence-sound.png";
import logout from "../../assets/images/logout-nav.png";
import { Link } from "react-router-dom";
import { Redirect, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import {
  notificationManager,
  showConversation
} from "../../appRedux/actions/Conversation";
import { userSignOut, SendLogout } from "../../appRedux/actions/Auth";
import { socketOff } from "../../appRedux/actions/Socket";
import * as io from "socket.io-client";

class HorizontalNav extends React.Component {
  constructor(props) {
    super(props);
    this.M = window.M;
    this.state = {
      loggedIn: false
    };
  }
  componentDidMount() {
    //activación del modal
    var elems = document.querySelectorAll(".sidenav");
    var instances = this.M.Sidenav.init(elems);
  }

  handleNotify(event, silent) {
    const { notificationManager } = this.props;
    notificationManager(silent);
    let items = this.li.childNodes;
    if (silent) {
      items[0].classList.add("hide");
      items[1].classList.remove("hide");
    } else {
      items[1].classList.add("hide");
      items[0].classList.remove("hide");
    }
  }

  logout(e) {
    e.preventDefault();
    this.props.SendLogout();
    console.log("SALIR");
    alert("salir");
    localStorage.clear();
    localStorage.removeItem("token");
    this.props.logout();
    this.props.socketOff();

    //return  <Redirect to="/"/>
  }
  logoutAgent(event) {
    event.preventDefault();
    //let message = { room_id: room_id };
    //socket.emit('leave_room', message);
    //alert('salir');
    this.props.SendLogout(event);
    localStorage.clear();
    this.props.userSignOut(event);
    this.props.socketOff();
    //
  }

  render() {
    const { authUser } = this.props;
    const { containerInfo } = this.props;
  
    let parameters = {
      conversation_id: containerInfo.conversation_id
    };
    return (
      <div>
        <nav className="navbar">
          <div className="nav-wrapper">
            {" "}
            {/*<Link to="/signin" className="brand-logo">*/}
            <div>
              <img src={logo} alt="logo" className="responsive-img logo" />
              <span> OH!MEGA </span>{" "}
            </div>{" "}
            {/*</Link>*/}
            <ul className="navbar-options hide-on-med-and-down">
              <li>
                <a>{authUser.name}</a>
              </li>
              <li
                ref={e => {
                  this.li = e;
                }}
              >
                <img
                  onClick={event => {
                    this.handleNotify(event, true);
                  }}
                  id="sound-icons"
                  src={iconSound}
                  alt="sound-icon"
                  className="responsive-img img-min"
                />
                <img
                  onClick={event => {
                    this.handleNotify(event, false);
                  }}
                  id="silence-icons"
                  src={silenceSound}
                  alt="sound-icon"
                  className="responsive-img hide img-min"
                />
              </li>{" "}
              <li>
                <img
                  onClick={event => {
                    this.logoutAgent(event);
                  }}
                  src={logout}
                  alt="logout"
                  className="responsive-img"
                />
              </li>{" "}
            </ul>{" "}
          </div>{" "}
        </nav>{" "}
      </div>
    );
  }
}

const mapStateToProps = ({ conversation, auth, chat }) => {
  const { silentNotify } = conversation;
  const { authUser, token } = auth;
  const { containerInfo } = conversation;
  //const { logout } = chat;
  return { silentNotify, authUser, token, containerInfo };
};
export default connect(mapStateToProps, {
  notificationManager,
  socketOff,
  /* logout, */
  userSignOut,
  SendLogout,
  showConversation
})(HorizontalNav);
