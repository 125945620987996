import React from 'react';
import './addmessage.css';
import { connect } from 'react-redux';
import addAnswer from '../../../assets/images/add_answer.png';
import { showElementMessage, createQuickReply } from '../../../appRedux/actions/Companies';
let pruebaElement;
let itemModal;
console.log('itemModal', itemModal);
class Addmessage extends React.Component {
	constructor(props) {
		super(props);
		this.M = window.M;
		this.inputField = React.createRef();
		this.modalImages = this.modalImages.bind(this);
		this.modalImagen = React.createRef();
		this.imgModal = React.createRef();
		this.imagenFile = React.createRef();
		this.modalClose = React.createRef();
		this.modalImagenss = React.createRef();
	}
	componentDidMount() {
		//activación del modal
		var elems = document.querySelectorAll('.modal');
	}
	showElement(e) {
		e.preventDefault();
		console.log('mostar input');
		this.props.showElementMessage(e);
	}
	handleSubmit(event) {
		console.log('value-inputt---', event.target[0].value);
	}
	handleModal(e, reply) {
		e.preventDefault();
		let { itemMessage } = this.props;
		let { allReplies } = this.props;
		let quick_reply_greeting = allReplies.filter((reply) => reply.category_name === 'Saludo');
		let quick_reply_waiting = allReplies.filter((reply) => reply.category_name === 'Mensaje en espera');
		let quick_reply_farewell = allReplies.filter((reply) => reply.category_name === 'Mensaje de despedida');

		//"Mensaje de despedida", "Mensaje en espera"
		itemModal = this.props.itemMessage;
		const { autoGreetingsReplies, containerAdminReplies, repliesWelcome } = this.props;
		let company_id = containerAdminReplies.company_uuid;
		
		if (itemModal === 5) {

			let welcomeReply = {
				text: e.target[0].value,
				type: 'WelcomeGreeting',
				category_id: null
			};
			this.props.createQuickReply(company_id, welcomeReply);
		}else if (itemModal === 1) {

			let autoGreetings = {
				text: e.target[0].value,
				type: 'AutoGreeting',
				category_id: null
			};
			this.props.createQuickReply(company_id, autoGreetings);
		} else if (itemModal === 2) {
			let replyGreeting = {
				text: e.target[0].value,
				type: 'QuickReply',
				category_id: 1
			};
			this.props.createQuickReply(company_id, replyGreeting);

		} else if (itemModal === 3) {
			let replyWaiting = {
				text: e.target[0].value,
				type: 'QuickReply',
				category_id: 2
			};
			this.props.createQuickReply(company_id, replyWaiting);
		} else if (itemModal === 4) {
			let replyFarewell = {
				text: e.target[0].value,
				type: 'QuickReply',
				category_id: 3
			};
			this.props.createQuickReply(company_id, replyFarewell);
		}
		this.inputField.value = '';
	}

	modalImages(e) {

		this.modalImagen.current.style.display = 'block';
	}
	closeModal(...e) {
		itemModal = 0;
		if (e.length > 1) this.inputField.value = ''
		this.modalImagen.current.style.display = 'none';
	}

	render() {
		return (
			<div>
				<div
					ref={this.imagenFile}
					onClick={(e) => {
						this.modalImages(e);
					}}
					className="admin-replies__add-message"
				>
					<img src={addAnswer} alt="img-replies" className="responsive-img" />
					<span className="admin-replies__text-blue">Agregar mensaje</span>
				</div>
				<div>
					<div
						ref={this.modalImagen}

						class="modal-img"
					>
						<div className="admin_replies_container-modal">
							<p className="admin-modal__title">Agregar mensaje</p>

							<form
								onSubmit={(event, reply) => {
									this.handleModal(event, reply);
								}}
							>
								<textarea
									name=""
									id=""
									cols="30"
									rows="10"
									maxLength="200"
									ref={(e) => {
										this.inputField = e;
									}}
								/>
								<p className="admin_replies__max_length_text">Máximo 200 caracteres</p>
								<div className="admin-replies_container-input" />
								<div className="admin-replies__container-messages">
									<button
										className="admin-replies__btn-save"
										type="submit"
										ref={this.modalClose}
										onClick={(e) => {
											this.closeModal(e);
										}}
									>
										Agregar
									</button>
									<button
										className="admin-replies__btn-delete"
										type="button"
										ref={this.modalClose}
										onClick={(e) => {
											this.closeModal(e, true);
										}}
									>
										Cancelar
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = ({ conversation, auth, chat, companies }) => {
	const { containerInfo } = conversation;
	const { authUser } = auth;
	const { showElement, containerAdminReplies } = companies;

	return {
		containerInfo,
		authUser,
		showElement,
		containerAdminReplies
	};
};

export default connect(mapStateToProps, { showElementMessage, createQuickReply })(Addmessage);
