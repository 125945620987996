export const KEYWORDS = [
	'conf',
	'btn',
	'opc',
	'app',
	'neg',
	'plat',
	'cuenta',
	'contra',
	'contraseñas',
	'vpn',
	'correo',
	'wifi',
	'equipo',
	'acc',
	'req'
];
