import React from "react";
import "./analystStatus.css"
import moment from "moment/min/moment-with-locales";
import TableAgentStatusRow from "../dashboard/TableRow/TableAgentStatusRow";
import { connect } from "react-redux";

import {
    getAgentsConectedStates
  } from "../../appRedux/actions/Conversation";

class ContainerAnalystStatus extends React.Component {
    constructor(props){
       super(props); 
    }
    async componentDidMount() {
        this.props.getAgentsConectedStates();
        setInterval(() => {
            const { messageAgentStatus } = this.props;
        }, 1000);
    }
    render() {

        let listMessages = [];
        const {
            messageAgentStatus
          } = this.props;

        createRowsForActiveConversations(
            messageAgentStatus,
            listMessages
          );

        return (
            <div className="dashboard__container">
            <div className="row">
              <div>
                <div className="dashboard__header">
                  <div className="col s8">                
                    <span>Estados de Analistas</span>
                  </div>
                  <div className="col s4">                 
                    
                  </div>              
                </div>
              </div>
            </div>
            <div id="test2" className="col s12">
            <table className="dashboard__table">
              <tbody>
                <tr className="dashboard_table-header">
                  <th className="table__two_rows">AGENTE</th>
                  <th className="table__two_rows">ESTADO</th>
                  <th className="table__two_rows">Tiempo esperando atención</th>
                  <th className="table__two_rows">Hora de inicio de conversación</th>
                  <th className="table__two_rows">Tiempo esperando respuesta</th>
                </tr>
                {listMessages}
              </tbody>
            </table>
          </div>
        </div>
        );
      }
}

//Funcion creada para listar estados de agentes disponibles y atendiendo.
function createRowsForActiveConversations(
    messageAgentStatus,
    listMessages
  ) {
    messageAgentStatus.map(function(id, i) {
      //if(id.current_state_name == "closed-inactiveuser" ||
        //id.current_state_name === "closed-refered" ||
        //id.current_state_name === "closed-attended"){
  
        //}else{
          listMessages.push(
            <TableAgentStatusRow
              key={id}
              id={id.id}
              items={id}
              agent_first_name={id.agent_first_name}
              agent_last_name={id.agent_last_name}
              current_state_agente={id.current_state_agente}
              name={id.name}
              //name={id.user_name}
              //corporation={id.corporation_name}
              //company={id.company_name}
              company_id={id.company_id}
              //initDate={id.conv_created_at}
              last_message_created_at={id.last_message_created_at}
              queue_time={id.queue_time}
              referring_created_at={id.referring_created_at}
              //conversation_id={id.conversation_id}
              //state_name={id.current_state_name}
              //channelName={id.channel_name}
            />
          );
  
        //}
      
    });
  }

const mapStateToProps = ({ socket, conversation, auth, companies }) => {
    const { authUser } = auth;
    const {
        messageAgentStatus
      } = conversation;
    return {
      authUser,
      messageAgentStatus
    };
  };

export default connect(mapStateToProps,{
    getAgentsConectedStates
  })(ContainerAnalystStatus);