export const QUICKREPLY = "QuickReply";
export const AUTOGREETING = "AutoGreeting";
export const WELCOMEGREETING = "WelcomeGreeting";
export const ANALYST = "ANALYST";
export const MANAGER = "MANAGER";
export const ALL = "All";
export const GREETING = "Saludo";
export const WAITING = "Mensaje de despedida";
export const FAREWELL = "Mensaje de despedida";
export const THRESHOLD = "THRESHOLD";
export const PLATFORM_GENERAL = "PLATFORM-GENERAL";
export const CLIENT_AGENT_WEB = "client_agent_web";
