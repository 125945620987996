import React from 'react';
import { connect } from 'react-redux';
import './modal.css';
import {
	getAllCompanies,
	getPlatformsDefault,
	getPlatformsCompany,
	getParametersPlatform,
	putParametersPlatform,
	putEndpointPlatform
} from '../../../appRedux/actions/Parameters';


class Modal extends React.Component {
	constructor(props) {
		super(props);
		this.M = window.M;

		this.select = React.createRef();
		this.M = window.M;
		this.modalSave = React.createRef();
	}

	//
	render() {
		const { loadingSave, saveSuccessuful, saveError, showModal, handleCloseModal } = this.props;

		let variableModal;
		if (loadingSave) {
			variableModal = (
				<div>
					<p>Estamos registrando tu cambio, esto puede tomar unos segundos </p>
					<div id="loading" className="loading">
						Loading&#8230;
					</div>
				</div>
			);
		}
		if (saveSuccessuful) {
			variableModal = <div className="">¡Los cambios se guardaron con éxito!</div>;
		}
		if (saveError) {
			variableModal = (
				<div>
					<p>¡Ups!</p>
					<p>Error</p>
				</div>
			);

		}

		return (
			<div>
				{showModal && (
					<div className="modal-save" ref={this.modalSave}>
						<div class="modal-content-save">
							<p onClick={handleCloseModal} class="modal-save-close">
								&times;
							</p>
							<p className="modal-save-title">Configuración de parámetros</p>

							<p> {variableModal}</p>
						</div>
					</div>
				)}
			</div>
		);
	}
}

const mapStateToProps = ({ auth, users, parameters }) => {
	const { authUser } = auth;
	const {
		listAllCompanies,
		platformsDefault,
		platformsCompany,
		parametersPlatform,
		loadingSave,
		saveSuccessuful,
		saveError
	} = parameters;

	return {
		authUser,
		listAllCompanies,
		platformsDefault,
		platformsCompany,
		parametersPlatform,
		loadingSave,
		saveSuccessuful,
		saveError
	};
};

export default connect(mapStateToProps, {
	getAllCompanies,
	getPlatformsDefault,
	getPlatformsCompany,
	getParametersPlatform,
	putParametersPlatform,
	putEndpointPlatform
})(Modal);
