import React from 'react';
import { connect } from 'react-redux';
import { getParameters } from '../../../appRedux/actions/Chat';

import './quickreplies.css';

class QuickReplies extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			messagesQuick: []
		};
	}

	render() {
		const { handleSetMessage, reply } = this.props;


		return (
			<div className="replies-new">
				<a className="btn quick-replies" onClick={(e) => handleSetMessage(e, reply)}>
					<p className="mt-0 mb-0"> {reply}</p>
				</a>
			</div>
		);
	}
}
const mapStateToProps = ({ chat, conversation, auth }) => {
	const { quickReplies } = chat;
	return {
		quickReplies
	};
};

export default connect(mapStateToProps, {
	getParameters
})(QuickReplies);
