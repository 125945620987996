import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import Settings from './Settings';
import Auth from './Auth';
import Common from './Common';
import Conversation from './Conversation';
import Chat from './Chat';
import Companies from './Companies';
import Sockets from './Sockets';
import Users from './Users';
import Parameters from './Parameters';
/*import { Socket } from 'net';*/

const reducers = combineReducers({
	routing: routerReducer,
	settings: Settings,
	auth: Auth,
	commonData: Common,
	conversation: Conversation,
	chat: Chat,
	companies: Companies,
	socket: Sockets,
	users: Users,
	parameters: Parameters
});

export default reducers;
