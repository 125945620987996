import {
	INIT_URL,
	FETCH_START,
	FETCH_ERROR,
	FETCH_SUCCESS,
	SHOW_ROLES,
	ITEM_SESSION,
	SHOW_ALL_COMPANIES,
	THRESHOLD_COMPANY,
	PARAMETERS_WATSON,
	SHOW_PLATFORMS_COMPANY,
	SHOW_PLATFORMS_DEFAULT,
	PARAMETERS_PLATFORM,
	LOAD_SUCCESS,
	LOAD_LOADING,
	LOAD_ERROR,
	PARAMETERS_STATE_TICKETS,
	PARAMETERS_PSS,
	PARAMETERS_API,
	PARAMETERS_PLATFORM_STATES
} from '../../constants/ActionTypes';
import { THRESHOLD } from '../../constants/Constants';

import axios from 'util/Api';
import { logout } from './Chat';
import { envioGlobalError } from '../../helpers/ErrorHelper';

export const setInitUrl = (url) => {
	return {
		type: INIT_URL,
		payload: url
	};
};

export const getRoles = (roles) => {
	return async (dispatch) => {
		await axios
			.get('roles')
			.then(({ data }) => {
				if (data.success) {
					dispatch({
						type: SHOW_ROLES,
						payload: data.payload
					});
					
				}
			})
			.catch(function(error) {
				dispatch({
					type: FETCH_ERROR,
					payload: error.message
				});
				// ===============================
				// captura de error
				// ===============================
				let functionName = 'getRolesName';
				let capturaErr = error;
				envioGlobalError(capturaErr, functionName);
				// ===============================

				if (error.response !== undefined && error.response.status === 401) {
					dispatch(logout());
				}
			});
	};
};

export const putRoles = (parameters) => {
	return (dispatch) => {
		dispatch({ type: FETCH_START });
		dispatch({
			type: LOAD_LOADING
		});
		axios
			.put('roles', parameters)
			.then(({ data }) => {
				if (data.success) {
					dispatch({
						type: FETCH_SUCCESS
					});
					dispatch({
						type: LOAD_SUCCESS
					});
				} else {
					dispatch({
						type: LOAD_ERROR,
						error: 'ERROR'
					});
					dispatch({
						type: FETCH_ERROR,
						payload: data.error
					});
				}
			})
			.catch(function(error) {
				// ===============================
				// captura de error
				// ===============================
				let functioName = 'putRolesName';
				let capturaErr = error;
				envioGlobalError(capturaErr, functioName);
				// ===============================
				dispatch({
					type: FETCH_ERROR,
					payload: error.message
				});
				if (error.response !== undefined && error.response.status === 401) {
					dispatch(logout());
				}
			});
	};
};

export const getPlatformsDefault = () => {
	return (dispatch) => {
		axios
			.get('servicedeskplatforms')
			.then(({ data }) => {
				if (data.success) {
					dispatch({
						type: SHOW_PLATFORMS_DEFAULT,
						payload: data.payload
					});
				}
			})
			.catch(function(error) {
				// ===============================
				// captura de error
				// ===============================
				let functionName = 'getPlatformsDefaultName';
				let capturaErr = error;
				envioGlobalError(capturaErr, functionName);
				// ===============================
				dispatch({
					type: FETCH_ERROR,
					payload: error.message
				});

				if (error.response !== undefined && error.response.status === 401) {
					dispatch(logout());
				}
			});
	};
};

export const getPlatformsCompany = (company_id) => {
	return async (dispatch) => {
		await axios
			.get('companies/' + company_id + '/servicedeskplatforms')
			.then(({ data }) => {
				if (data.success) {
					dispatch({
						type: SHOW_PLATFORMS_COMPANY,
						payload: data.payload
					});
				}
			})
			.catch(function(error) {
				dispatch({
					type: FETCH_ERROR,
					payload: error.message
				});
				// ===============================
				// captura de error
				// ===============================
				let functionName = 'getPlatformsCompanyName';
				let capturaErr = error;
				envioGlobalError(capturaErr, functionName);
				// ===============================

				if (error.response !== undefined && error.response.status === 401) {
					dispatch(logout());
				}
			});
	};
};

export const updateItemSession = (parameters) => {
	return (dispatch, getState) => {
		dispatch({
			type: ITEM_SESSION,
			payload: parameters
		});
	};
};

export const getAllCompanies = () => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		await axios
			.get('companies')
			.then(({ data }) => {
				if (data.success) {
					dispatch({
						type: SHOW_ALL_COMPANIES,
						payload: data.payload
					});
				}
			})
			.catch(function(error) {
				dispatch({
					type: FETCH_ERROR,
					payload: error.message
				});
				// ===============================
				// captura de error
				// ===============================
				let functionName = 'getAllCompaniesName';
				let capturaErr = error;
				envioGlobalError(capturaErr, functionName);
				// ===============================

				if (error.response !== undefined && error.response.status === 401) {
					dispatch(logout());
				}
			});
	};
};

export const getThresholdsCompany = (company_id) => {
	return async (dispatch) => {
		dispatch({
			type: FETCH_START
		});
		await axios
			.get('companies/' + company_id + '/thresholds')
			.then(({ data }) => {
				if (data.success) {
					dispatch({
						type: THRESHOLD_COMPANY,
						payload: data.payload
					});
				}
			})
			.catch(function(error) {
				dispatch({
					type: FETCH_ERROR,
					payload: error.message
				});
				// ===============================
				// captura de error
				// ===============================
				let functionName = 'getThresholdsCompanyname';
				let capturaErr = error;
				envioGlobalError(capturaErr, functionName);
				// ===============================

				if (error.response !== undefined && error.response.status === 401) {
					dispatch(logout());
				}
			});
	};
};

export const putAttentionThresholds = (company_id, parameters) => {
	return (dispatch) => {
		dispatch({
			type: FETCH_START
		});
		dispatch({
			type: LOAD_LOADING
		});
		axios
			.put('companies/' + company_id + '/parameters?group=' + THRESHOLD, parameters)
			.then(({ data }) => {
				if (data.success) {
					dispatch({
						type: FETCH_SUCCESS
					});
					dispatch({
						type: LOAD_SUCCESS
					});
				} else {
					dispatch({
						type: LOAD_ERROR,
						error: 'ERROR'
					});
					dispatch({
						type: FETCH_ERROR,
						payload: data.error
					});
				}
			})
			.catch(function(error) {
				dispatch({
					type: FETCH_ERROR,
					payload: error.message
				});
				// ===============================
				// captura de error
				// ===============================
				let functionName = 'putAttentionThresholdsName';
				let capturaErr = error;
				envioGlobalError(capturaErr, functionName);
				// ===============================
				if (error.response !== undefined && error.response.status === 401) {
					dispatch(logout());
				}
			});
	};
};

export const getParametersWatson = (company_id) => {
	return async (dispatch) => {
		dispatch({
			type: FETCH_START
		});
		await axios
			.get('companies/' + company_id + '/watsonconfigs')
			.then(({ data }) => {
				if (data.success) {
					dispatch({
						type: PARAMETERS_WATSON,
						payload: data.payload
					});
				}
			})
			.catch(function(error) {
				dispatch({
					type: FETCH_ERROR,
					payload: error.message
				});
				// ===============================
				// captura de error
				// ===============================
				let functionName = 'getParametersWatsonName';
				let capturaErr = error;
				envioGlobalError(capturaErr, functionName);
				// ===============================

				if (error.response !== undefined && error.response.status === 401) {
					dispatch(logout());
				}
			});
	};
};

export const putParametersWatson = (company_id, parameters) => {
	return (dispatch) => {
		dispatch({
			type: FETCH_START
		});
		dispatch({
			type: LOAD_LOADING
		});
		axios
			.put('companies/' + company_id + '/watsonconfigs', parameters)
			.then(({ data }) => {
				if (data.success) {
					dispatch({
						type: FETCH_SUCCESS
					});
					dispatch({
						type: LOAD_SUCCESS
					});
				} else {
					dispatch({
						type: LOAD_ERROR,
						error: data.error
					});
					dispatch({
						type: FETCH_ERROR,
						payload: data.error
					});
				}
			})
			.catch(function(error) {
				dispatch({
					type: FETCH_ERROR,
					payload: error.message
				});
				// ===============================
				// captura de error
				// ===============================
				let functionName = 'putParametersWatsonName';
				let capturaErr = error;
				envioGlobalError(capturaErr, functionName);
				// ===============================
				if (error.response !== undefined && error.response.status === 401) {
					dispatch(logout());
				}
			});
	};
};
////
export const getParametersPlatform = (company_id) => {
	let platform = 'PLATFORM-GENERAL';
	return async (dispatch) => {
		dispatch({
			type: FETCH_START
		});
		await axios
			.get('companies/' + company_id + '/parameters?group=' + platform)
			.then(({ data }) => {
				if (data.success) {
					dispatch({
						type: PARAMETERS_PLATFORM,
						payload: data.payload
					});
					data.payload.map(function(i) {
					});
				}
			})
			.catch(function(error) {
				dispatch({
					type: FETCH_ERROR,
					payload: error.message
				});
				// ===============================
				// captura de error
				// ===============================
				let functionName = 'getParametersPlatformName';
				let capturaErr = error;
				envioGlobalError(capturaErr, functionName);
				// ===============================

				if (error.response !== undefined && error.response.status === 401) {
					dispatch(logout());
				}
			});
	};
};
//other
export const getPlatformStates = (company_id) => {
	let platformStates = 'PLATFORM-STATES-FILTER';
	return async (dispatch) => {
		dispatch({
			type: FETCH_START
		});
		await axios
			.get('companies/' + company_id + '/parameters?group=' + platformStates)
			.then(({ data }) => {
				if (data.success) {
					dispatch({
						type: PARAMETERS_PLATFORM_STATES,
						payload: data.payload
					});
				}
			})
			.catch(function(error) {
				dispatch({
					type: FETCH_ERROR,
					payload: error.message
				});
				// ===============================
				// captura de error
				// ===============================
				let functionName = 'getPlatformStatesName';
				let capturaErr = error;
				envioGlobalError(capturaErr, functionName);
				// ===============================

				if (error.response !== undefined && error.response.status === 401) {
					dispatch(logout());
				}
			});
	};
};
export const putPlatformStates = (company_id, parameters) => {
	let platformStates = 'PLATFORM-STATES-FILTER';
	return async (dispatch) => {
		dispatch({
			type: FETCH_START
		});
		dispatch({
			type: LOAD_LOADING
		});
		await axios
			.put('companies/' + company_id + '/parameters?group=' + platformStates, parameters)
			.then(({ data }) => {
				if (data.success) {
					dispatch({
						type: FETCH_SUCCESS
					});
					dispatch({
						type: LOAD_SUCCESS
					});
				} else {
					dispatch({
						type: LOAD_ERROR,
						error: 'djdjd'
					});
				}
			})
			.catch(function(error) {
				dispatch({
					type: FETCH_ERROR,
					payload: error.message
				});
				// ===============================
				// captura de error
				// ===============================
				let functionName = 'putPlatformStatesname';
				let capturaErr = error;
				envioGlobalError(capturaErr, functionName);
				// ===============================
				if (error.response !== undefined && error.response.status === 401) {
					dispatch(logout());
				}
			});
	};
};
export const putEndpointPlatform = (company_id, parameters) => {
	return async (dispatch) => {
		dispatch({
			type: FETCH_START
		});
		await axios
			.put('companies/' + company_id + '/servicedeskplatforms', parameters)
			.then(({ data }) => {
				if (data.success) {
					dispatch({
						type: FETCH_SUCCESS
					});
				} else {
					dispatch({
						type: FETCH_ERROR,
						payload: data.error
					});
				}
			})
			.catch(function(error) {
				dispatch({
					type: FETCH_ERROR,
					payload: error.message
				});
				// ===============================
				// captura de error
				// ===============================
				let functionName = 'putEndpointPlatformName';
				let capturaErr = error;
				envioGlobalError(capturaErr, functionName);
				// ===============================
				if (error.response !== undefined && error.response.status === 401) {
					dispatch(logout());
				}
			});
	};
};
export const putParametersPlatform = (company_id, parameters) => {
	let platform = 'PLATFORM-GENERAL';
	return async (dispatch) => {
		dispatch({
			type: FETCH_START
		});
		dispatch({
			type: LOAD_LOADING
		});
		await axios
			.put('companies/' + company_id + '/parameters?group=' + platform, parameters)
			.then(({ data }) => {
				if (data.success) {
					dispatch({
						type: FETCH_SUCCESS
					});
					dispatch({
						type: LOAD_SUCCESS
					});
				} else {
					dispatch({
						type: LOAD_ERROR,
						error: 'djdjd'
					});
				}
			})
			.catch(function(error) {
				dispatch({
					type: FETCH_ERROR,
					payload: error.message
				});
				// ===============================
				// captura de error
				// ===============================
				let functionName = 'putParametersPlatformName';
				let capturaErr = error;
				envioGlobalError(capturaErr, functionName);
				// ===============================
				if (error.response !== undefined && error.response.status === 401) {
					dispatch(logout());
				}
			});
	};
};

//parameters state tickets
export const getParametersTickets = (company_id) => {
	let stateTicket = 'STATES-EQUIVALENCE';
	return async (dispatch) => {
		dispatch({
			type: FETCH_START
		});
		await axios
			.get('companies/' + company_id + '/parameters?group=' + stateTicket)
			.then(({ data }) => {
				if (data.success) {
					dispatch({
						type: PARAMETERS_STATE_TICKETS,
						payload: data.payload
					});
				} else {
					dispatch({
						type: FETCH_ERROR,
						payload: data.error
					});
				}
			})
			.catch(function(error) {
				dispatch({
					type: FETCH_ERROR,
					payload: error.message
				});
				// ===============================
				// captura de error
				// ===============================
				let functionName = 'getParametersTicketsName';
				let capturaErr = error;
				envioGlobalError(capturaErr, functionName);
				// ===============================
				if (error.response !== undefined && error.response.status === 401) {
					dispatch(logout());
				}
			});
	};
};

export const putParametersTickets = (company_id, parameters) => {
	let stateTicket = 'STATES-EQUIVALENCE';
	return async (dispatch) => {
		dispatch({
			type: FETCH_START
		});
		dispatch({
			type: LOAD_LOADING
		});
		await axios
			.put('companies/' + company_id + '/parameters?group=' + stateTicket, parameters)
			.then(({ data }) => {
				if (data.success) {
					dispatch({
						type: FETCH_SUCCESS
					});
					dispatch({
						type: LOAD_SUCCESS
					});
				} else {
					dispatch({
						type: LOAD_ERROR,
						error: 'Error'
					});
				}
			})
			.catch(function(error) {
				dispatch({
					type: FETCH_ERROR,
					payload: error.message
				});
				// ===============================
				// captura de error
				// ===============================
				let functionName = 'putParametersTicketsName';
				let capturaErr = error;
				envioGlobalError(capturaErr, functionName);
				// ===============================
				if (error.response !== undefined && error.response.status === 401) {
					dispatch(logout());
				}
			});
	};
};

export const getParametersPSS = (company_id) => {
	let stateTicket = 'INTEGRATION-PSS';
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		await axios
			.get('companies/' + company_id + '/parameters?group=' + stateTicket)
			.then(({ data }) => {
				if (data.success) {
					var datapayload = data.payload;
					var resultFormat = [];
					var rest = [];
					for (var i in datapayload) {
						var n = datapayload[i].param_name.search('_ENDPOINT');
						if (n > -1) {
							var titlePrincipal = '';
							var title = '';
							var subtitle = '';
							var headerTitle = '';
							switch (datapayload[i].param_name) {
								case 'PSS_UNLOCK_ENDPOINT':
									titlePrincipal = 'Parámetros PSS - Desbloqueo';
									title = '';
									subtitle = 'API de desbloqueo PSS';
									headerTitle = 'Cabecera de desbloqueo PSS';
									break;
								case 'PSS_RESET_S1_ENDPOINT':
									titlePrincipal = 'Parámetros PSS - Reseteo';
									title = 'Paso 1';
									subtitle = 'API de reseteo PSS';
									headerTitle = 'Cabecera de reseteo PSS';
									break;
								case 'PSS_RESET_S2_ENDPOINT':
									titlePrincipal = '';
									title = 'Paso 2';
									subtitle = 'API de reseteo PSS';
									headerTitle = 'Cabecera de reseteo PSS';
									break;
								default:
									titlePrincipal = '';
									title = 'Nueva categoría no identificada';
									subtitle = '';
									headerTitle = 'Cabecera';
									break;
							}

							resultFormat.push({
								title: title,
								subtitle: subtitle,
								headerTitle: headerTitle,
								titlePrincipal: titlePrincipal,
								id: datapayload[i].id,
								param_name: datapayload[i].param_name,
								group: datapayload[i].param_name.substring(0, n),
								param_id: datapayload[i].param_id,
								param_label: datapayload[i].param_label,
								param_value: datapayload[i].param_value,
								required_for_platform: datapayload[i].required_for_platform,
								params: [],
								headers: []
							});
						} else {
							rest.push(datapayload[i]);
						}
					}

					for (var i in rest) {
						var n = rest[i].param_name.search('_BODY');
						var h = rest[i].param_name.search('_HEADERS');
						if (n > -1) {
							for (var j in resultFormat) {
								if (resultFormat[j].group === rest[i].param_name.substring(0, n)) {
									rest[i].endpointId = resultFormat[j].param_id;
									resultFormat[j].childid = rest[i].param_id;
									resultFormat[j].params.push(rest[i]);
								}
							}
						}
						if (h > -1) {
							for (var j in resultFormat) {
								if (resultFormat[j].group === rest[i].param_name.substring(0, h)) {
									rest[i].endpointId = resultFormat[j].param_id;
									resultFormat[j].childid = rest[i].param_id;
									resultFormat[j].headers.push(rest[i]);
								}
							}
						}
					}

					var groupReset = [];
					var group = [];

					for (var i in resultFormat) {
						if (resultFormat[i].group === 'PSS_RESET_S1' || resultFormat[i].group === 'PSS_RESET_S2') {
							groupReset.push(resultFormat[i]);
						} else {
							group.push([ resultFormat[i] ]);
						}
					}

					group.push(groupReset);

					dispatch({
						type: PARAMETERS_PSS,
						payload: group
					});
				} else {
					dispatch({ type: FETCH_ERROR, payload: data.error });
				}
			})
			.catch(function(error) {
				dispatch({
					type: FETCH_ERROR,
					payload: error.message
				});
				// ===============================
				// captura de error
				// ===============================
				let functionName = 'getParametersPSSName';
				let capturaErr = error;
				envioGlobalError(capturaErr, functionName);
				// ===============================

				if (error.response !== undefined && error.response.status === 401) {
					dispatch(logout());
				}
			});
	};
};
//parameters API
export const getParametersApi = (company_id) => {
	let parameterApi = 'PLATFORM-API-CALL';
	return async (dispatch) => {
		dispatch({
			type: FETCH_START
		});
		await axios
			.get('companies/' + company_id + '/parameters?group=' + parameterApi)
			.then(({ data }) => {
				if (data.success) {
					let result = data.payload.reduce(function(r, a) {
						r[a.param_name] = r[a.param_name] || [];
						r[a.param_name].push(a);
						return r;
					}, Object.create(null));
					dispatch({
						type: PARAMETERS_API,
						payload: result
					});
				} else {
					dispatch({
						type: FETCH_ERROR,
						payload: data.error
					});
				}
			})
			.catch(function(error) {
				dispatch({
					type: FETCH_ERROR,
					payload: error.message
				});
				// ===============================
				// captura de error
				// ===============================
				let functionName = 'getParametersApiName';
				let capturaErr = error;
				envioGlobalError(capturaErr, functionName);
				// ===============================

				if (error.response !== undefined && error.response.status === 401) {
					dispatch(logout());
				}
			});
	};
};

export const putParametersPSS = (company_id, parameters) => {
	let stateTicket = 'INTEGRATION-PSS';
	return async (dispatch) => {
		dispatch({
			type: FETCH_START
		});
		dispatch({
			type: LOAD_LOADING
		});
		await axios
			.put('companies/' + company_id + '/parameters?group=' + stateTicket, parameters)
			.then(({ data }) => {
				if (data.success) {
					dispatch({
						type: FETCH_SUCCESS
					});
					dispatch({
						type: LOAD_SUCCESS
					});
				} else {
					dispatch({
						type: LOAD_ERROR,
						error: 'djdjd'
					});
				}
			})
			.catch(function(error) {
				dispatch({
					type: FETCH_ERROR,
					payload: error.message
				});
				// ===============================
				// captura de error
				// ===============================
				let functionName = 'putParametersPSSName';
				let capturaErr = error;
				envioGlobalError(capturaErr, functionName);
				// ===============================
				if (error.response !== undefined && error.response.status === 401) {
					dispatch(logout());
				}
			});
	};
};

export const putParametersApi = (company_id, parameters) => {
	let parameterApi = 'PLATFORM-API-CALL';
	return async (dispatch) => {
		dispatch({
			type: FETCH_START
		});
		dispatch({
			type: LOAD_LOADING
		});
		await axios
			.put('companies/' + company_id + '/parameters?group=' + parameterApi, parameters)
			.then(({ data }) => {
				if (data.success) {
					dispatch({
						type: FETCH_SUCCESS
					});
					dispatch({
						type: LOAD_SUCCESS
					});
				} else {
					dispatch({
						type: LOAD_ERROR,
						error: 'djdjd'
					});
				}
			})
			.catch(function(error) {
				dispatch({
					type: FETCH_ERROR,
					payload: error.message
				});
				// ===============================
				// captura de error
				// ===============================
				let functionName = 'putParametersApiName';
				let capturaErr = error;
				envioGlobalError(capturaErr, functionName);
				// ===============================
				if (error.response !== undefined && error.response.status === 401) {
					dispatch(logout());
				}
			});
	};
};
