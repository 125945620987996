import React from "react";
import { ConnectedRouter } from "react-router-redux";
import { Provider } from "react-redux";
import { Route, Switch } from "react-router-dom";
import "assets/vendors/style";
import configureStore, { history } from "./appRedux/store";
import App from "./containers/App/index";
import HttpsRedirect from "react-https-redirect";

export const store = configureStore();

const NextApp = () => (
  <Provider store={store}>
    <HttpsRedirect>
      <ConnectedRouter history={history}>
        <Switch>
          <Route exact path="/*" component={App} handler={App} />
        </Switch>
      </ConnectedRouter>
    </HttpsRedirect>
  </Provider>
);

export default NextApp;
