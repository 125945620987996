import React from 'react';
import { connect } from 'react-redux';

import './itemSession.css';

class ItemSession extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			listItemsSession: [
				{
					name: '',
					message: ''
				}
			]
		};
	}

	render() {
		const { description, duration, name,  handleChangeSession } = this.props;
		let durationSession = parseInt(duration / 3600);
		let refs = this;
		return (
			<div id="session" className="admin-parameters__item-session">
				<p className="admin-parameters_description">{description}</p>
				<div className="admin-parameters_input">
					<input
						descriptionl={description}
						duration={duration}
						name={name}
						type="number"
						defaultValue={durationSession}
						onChange={handleChangeSession}
						className="input-default"
					/>
					<p>HRS</p>
				</div>
				<span className="admin-parameters_asterisk">*</span>
			</div>
		);
	}
}

const mapStateToProps = ({ auth, users, parameters }) => {
	const { authUser } = auth;

	return {
		authUser
	};
};

export default connect(mapStateToProps, {})(ItemSession);
