import React from 'react';
import { connect } from 'react-redux';

import './configurationWatson.css';

import { getAllCompanies, getParametersWatson, putParametersWatson } from '../../../appRedux/actions/Parameters';
import ItemWatson from '../ItemWatson/ItemWatson';
import ContainerDefaultPlatform from '../ContainerDefaultPlatform/ContainerDefaultPlatform';
import Modal from '../Modal/Modal';
class ConfigurationWatson extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			listCompanies: [],
			showResults: false,
			listParametersWatson: [],
			company_id: '',
			endpoint: '',
			username: '',
			password: '',
			workspace_id: '',
			showButtonSave: false,
			showModal: false
		};
	}

	async componentWillMount() {
		await this.props.getAllCompanies();
		const { listAllCompanies } = this.props;
		let initialCompanies = [];
		initialCompanies = listAllCompanies.map((company) => {
			return company;
		});
		this.setState({
			listCompanies: initialCompanies
		});
	}
	//change company
	async handleChangeWatson(event) {
		this.setState({
			showButtonSave: true
		});

		let company_id = event.target.value;
		await this.props.getParametersWatson(company_id);
		let { parametersWatson } = this.props;
		let endpoint;
		let username;
		let password;
		let workspace_id;
		if (parametersWatson == null) {
			endpoint = '';
			username = '';
			password = '';
			workspace_id = '';
		}
		if (parametersWatson) {
			endpoint = parametersWatson.endpoint;
			username = parametersWatson.username;
			password = parametersWatson.password;
			workspace_id = parametersWatson.workspace_id;
		} else {
			endpoint = '';
		}
		this.setState({
			company_id: company_id,
			endpoint: endpoint,
			username: username,
			password: password,
			workspace_id: workspace_id,
			showResults: true
		});

	}
	//////change input
	handleChangeWatsonParameters(event) {
		this.setState({ [event.target.name]: event.target.value });

	}
	handleCloseModal() {
		this.setState({
			showModal: false
		});
	}
	//save
	handleSaveParemetersWwatson(event) {
		event.preventDefault();
		this.setState({
			showModal: true
		});
		let itemCompanyId = this.state.company_id;
		let dataWatson = {
			username: this.state.username,
			password: this.state.password,
			endpoint: this.state.endpoint,
			workspace_id: this.state.workspace_id
		};
		this.props.putParametersWatson(itemCompanyId, dataWatson);
	}

	render() {
		
		const { loadingSave, saveSuccessuful, saveError } = this.props;
		let optionsCompanies = [];
		let optionsWatson = [];
		if (this.state.listCompanies.length > 0) {
			this.state.listCompanies.map(function(itemCompany) {
				optionsCompanies.push(
					<option key={itemCompany.id} value={itemCompany.company_uuid} defaultValue={itemCompany.name}>
						{itemCompany.name}
					</option>
				);
			});
		}
		let ref = this;
		let listWatson = this.state.listParametersWatson;

		optionsWatson.push(
			<div>
				<ItemWatson
					handleChangeWatsonParameters={(event) => {
						ref.handleChangeWatsonParameters(event);
					}}
					type="text"
					label="Endpoint"
					description={this.state.endpoint}
					name="endpoint"
				/>
				<ItemWatson
					handleChangeWatsonParameters={(event) => {
						ref.handleChangeWatsonParameters(event);
					}}
					type="text"
					label="Username"
					description={this.state.username}
					name="username"
				/>
				<ItemWatson
					handleChangeWatsonParameters={(event) => {
						ref.handleChangeWatsonParameters(event);
					}}
					type="password"
					label="Password"
					description={this.state.password}
					name="password"
				/>
				<ItemWatson
					handleChangeWatsonParameters={(event) => {
						ref.handleChangeWatsonParameters(event);
					}}
					type="text"
					label=" workspace_id"
					description={this.state.workspace_id}
					name="workspace_id"
				/>
			</div>
		);

		return (
			<form
				onSubmit={(e) => {
					this.handleSaveParemetersWwatson(e);
				}}
			>
				<div className="admin-parameters_container-session ">
					<div className="admin-parameters__session-header">
						<p className="admin-parameters__session-title">Configuración Watson</p>

						{}
						{this.state.showButtonSave && (
							<div>
								<input
									className="btn waves-effect admin-parameters__btn-save-input position-btn"
									type="submit"
									value="Guardar"
								/>
							</div>
						)}
						<Modal
							showModal={this.state.showModal}
							loadingSave={loadingSave}
							saveSuccessuful={saveSuccessuful}
							saveError={saveError}
							handleCloseModal={(event) => {
								ref.handleCloseModal(event);
							}}
						/>
					</div>
					<div className="admin-parameters__background">
						<div className=" admin-parameters__container-select">
							<select
								className="browser-default admin-parameters__client-default"
								onChange={(event) => this.handleChangeWatson(event)}
							>
								<option className="" datacompany="" value="" disabled selected>
									Seleccione una empresa
								</option>
								{optionsCompanies}
							</select>
						</div>
					</div>
					<div className="admin-parameters__container-thresholds">
						{this.state.showResults ? optionsWatson : <ContainerDefaultPlatform />}
					</div>
				</div>
			</form>
		);
	}
}

const mapStateToProps = ({ auth, users, parameters, conversation }) => {
	const { authUser } = auth;
	const { listAllCompanies, parametersWatson, loadingSave, saveSuccessuful, saveError } = parameters;

	return {
		authUser,
		listAllCompanies,
		parametersWatson,
		loadingSave,
		saveSuccessuful,
		saveError
	};
};

export default connect(mapStateToProps, { getAllCompanies, getParametersWatson, putParametersWatson })(
	ConfigurationWatson
);
