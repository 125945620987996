import React from "react";
import { connect } from "react-redux";
import "./lateralmenu.css";
import logout from "../../assets/images/logout.png";
import { Link } from "react-router-dom";

import {
  notificationManager
} from "../../appRedux/actions/Conversation";
import { userMenu, userSignOut, SendLogout } from "../../appRedux/actions/Auth";
import { socketOff } from "../../appRedux/actions/Socket";
import createBrowserHistory from "history/createBrowserHistory";

const history = createBrowserHistory();

class MenuLateral extends React.Component {
  state = {
    isNavShown: false
  };

  constructor(props) {
    super(props);
    this.M = window.M;
    this.state = {
      loggedIn: false
    };
  }
  componentWillMount() {
    const { authUser } = this.props;
    this.props.userMenu(authUser.id);
    this.activeOpctionMenu();
  }

  activeOpctionMenu() {
    const el = document.querySelectorAll(".sidenav li.option-menus");

    el.forEach(function(userItem) {
      if (!userItem.classList.contains("sidenav-menu")) {
        userItem.classList.remove("active");
      }
    });
    setTimeout(function() {
      var url = window.location.href;
      var arrayUrl = url.split("/");
      var optionMenu = arrayUrl[arrayUrl.length - 1];
      if (document.getElementsByClassName(optionMenu)[0] !== undefined) {
        document.getElementsByClassName(optionMenu)[0].className += " active";
      }
    }, 0);
  }

  componentDidMount() {
    //activación del modal
    var options = {
      edge: "left",
      draggable: false,
      inDuration: 200,
      outDuration: 200,
      onOpenStart: null,
      onOpenEnd: null,
      onCloseStart: null,
      onCloseEnd: null,
      preventScrolling: true
    };
    var elems = document.querySelectorAll(".sidenav");
  }

  handleNotify(event, silent) {
    const { notificationManager } = this.props;
    notificationManager(silent);
    let items = this.li.childNodes;
    if (silent) {
      items[0].classList.add("hide");
      items[1].classList.remove("hide");
    } else {
      items[1].classList.add("hide");
      items[0].classList.remove("hide");
    }
  }

  logout() {
    this.props.SendLogout();
    localStorage.removeItem("token");
    this.props.socketOff();
  }

  logoutAgent(event) {
    event.preventDefault();
    this.props.SendLogout(event);
    this.props.userSignOut(event);
    this.props.socketOff();
  }

  handleSHowNav() {
    const { isNavShown } = this.state;
    this.setState({ isNavShown: !isNavShown });
    this.activeOpctionMenu();
  }

  handleSHowNavContainer() {
    const el = document.querySelectorAll(".sidenav-container.min-nav");
    var menu = false;
    el.forEach(function(userItem) {
      if (userItem.classList.contains("min-nav")) {
        menu = true;
      }
    });
    if (menu) {
      const { isNavShown } = this.state;
      this.setState({ isNavShown: !isNavShown });
    }
  }

  render() {
    this.activeOpctionMenu();
    const { permissions } = this.props;

    const { isNavShown } = this.state;

    let menus = [];
    let menusOptions = [];

    if (permissions) {
      var duplicate = false;

      permissions.forEach(reply => {
        duplicate = false;
        menusOptions.forEach(option => {
          if (option === reply.permission_code) {
            duplicate = true;
          }
        });

        if (!duplicate) {
          menusOptions.push(reply.permission_code);
          menus.push(
            <li
              key={reply.permission_code}
              className={"option-menus " + reply.permission_code}
            >
              <Link
                to={reply.permission_code}
                onClick={event => {
                  this.activeOpctionMenu(reply.permission_code);
                }}
                className="link"
              >
                <i className={reply.permission_code}> </i>
                <span>{reply.permission_display_value}</span>{" "}
              </Link>
            </li>
          );
        }
      });
    }

    return (
      <div
        onClick={
          /* error corregido */
          this.isNavShown
            ? ""
            : event => {
                this.handleSHowNavContainer(event);
              }
        }
        id="sidenav-container"
        className="sidenav-container"
        className={
          isNavShown ? "sidenav-container" : "sidenav-container min-nav"
        }
      >
        <ul id="slide-out" className="sidenav">
          <li className="sidenav-menu">
            <a id="slide-out-btn">
              <i
                className="icon-menu"
                onClick={event => {
                  this.handleSHowNav(event, false);
                }}
              />
            </a>
          </li>
          {menus}
          <li className="sidenav-logout">
            <a
              alt="logout"
              src={logout}
              onClick={event => {
                this.logoutAgent(event);
              }}
            >
              <i className="icon-logout" />
              <span>Cerrar Sesión</span>
            </a>
          </li>
        </ul>
        <div
          onClick={event => {
            this.handleSHowNav(event);
          }}
          className="sidenav-overlay"
          style={{
            opacity: isNavShown ? 1 : 0,
            display: isNavShown ? "block" : "none"
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ auth, conversation, chat }) => {
  const { authUser, permissions } = auth;
  return { authUser, permissions };
};
export default connect(mapStateToProps, {
  notificationManager,
  socketOff,
  logout,
  userMenu,
  SendLogout,
  userSignOut
})(MenuLateral);
