import React from "react";
import {
  getConversationInfo,
  attendConversation,
  showConversation
} from "../../../appRedux/actions/Conversation";
import { connect } from "react-redux";
import ItemChat from "../Itemchat/ItemChat";
import { socketMessage } from "../../../appRedux/actions/Socket";
import "./containerItemChat.css";

class ContainerItemChat extends React.Component {
  handleInitConversation = (e, item) => {
    e.preventDefault();
    this.props.showConversation(item);
  };
  componentWillMount() {}

  getLastMessage(conversation_id) {
    const { messages } = this.props;
    let messagesFilter = messages.filter(
      item =>
        item.conversation_id === conversation_id &&
        item.user_type === "END-USER" &&
        (item.status === "SENT" || item.status === "READ")
    );
    let messagesOrder = messagesFilter.sort(function(a, b) {
      return b.id - a.id;
    });
    return { message: messagesOrder[0], count_message: messagesFilter.length };
  }

  getColorTime(dateCreate) {
    var moment = require("moment");
    const now = moment();
    const expiration = moment();
    const diff = expiration.diff(now);
    const diffDuration = moment.duration(diff);
    var rminutes = Math.abs(diffDuration.minutes());
    var rseconds = Math.abs(diffDuration.seconds());
  }
  render() {
    const { conversations, socketMessage } = this.props;
    const children = [];
    const ref = this;
    conversations.map(function(item, i) {
      children.unshift(
        <ItemChat
          key={item.conversation_id}
          item={item}
          user_first_name={item.user ? item.user.user_first_name : ""}
          lastMessage={ref.getLastMessage(item.conversation_id)}
          user_first_surname={item.user ? item.user.user_first_surname : ""}
          list={item.listTime}
          handleInitConversation={e => {
            ref.handleInitConversation(e, item);
          }}
        />
      );
      socketMessage(item.conversation_id);
    });
    return (
      <div className="container-listchat outline1">
        <div>{children}</div>
      </div>
    );
  }
}

const mapStateToProps = ({ conversation, auth, chat }) => {
  const { conversationInfo, conversations, listTime } = conversation;
  const { authUser } = auth;
  const { messages } = chat;

  return { conversationInfo, authUser, conversations, messages, listTime };
};

export default connect(mapStateToProps, {
  getConversationInfo,
  attendConversation,
  showConversation,
  socketMessage
})(ContainerItemChat);
