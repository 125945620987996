import * as io from "socket.io-client";
import axios from "util/Api";
import {
  CONVERSATION_INFO,
  ADD_CONNECTION,
  REMOVE_CONNECTION,
  ADD_MESSAGE,
  GET_WELCOME_GREETING,
  GET_AUTO_GREETING,
  INIT_MESSAGE,
  MANAGER,
  ADD_MESSAGE_DASHBOARD,
  INIT_MESSAGE_DASHBOARD,
  ADD_CANCEL_MESSAGE_DASHBOARD,
  INIT_AGENT_DASHBOARD,
  INIT_MESSAGE_AGENTE_STATUS
} from "../../constants/ActionTypes";

import { getMessages } from "../actions/Chat";
import {
  getConversations,
  getConversationInfo,
  notifyMe,
  notifyMessage,
  effect,
  getActiveConversationsStates,
  getCanceledConversationsStates,
  getAgentsConectedStates
} from "../actions/Conversation";
import * as CryptoJS from "crypto-js";
import { getThresholdsManager } from "../actions/Companies";
import { ANALYST } from "../../constants/Constants";

var platform = require("platform");

let socket;
//  Flag to avoid double connection
let isConnecting = false;

function getCompanyIdFromConversationId(conversation_id, conv) {
  let conversations = conv;
  let company_id = "0000";
  for (let i = 0; i < conversations.length; i++) {
    if (conversations[i].conversation_id == conversation_id) {
      company_id = conversations[i].company_id;
    }
  }
  return company_id;
}

function download(idFile, company_id, file_name) {
  return axios.get(
    "companies/" + company_id + "/files/" + idFile + "-" + file_name
  );
}

async function getUrlFromFileId(file_id, company_id, file_name) {
  let downloadResponsePure = await download(file_id, company_id, file_name);
  let downloadResponse = downloadResponsePure.data;
  let url = "";
  if (downloadResponse.payload) {
    url = downloadResponse.payload.file_url;
  }
  return url;
}

function decryptMsg(encrypted) {
  const key = CryptoJS.enc.Utf8.parse("1234123412ABCDEF"); // número hexadecimal de 16 dígitos como clave
  const iv = CryptoJS.enc.Utf8.parse("ABCDEF1234123412"); // Número hexadecimal como desplazamiento de clave

  const encryptedHexStr = CryptoJS.enc.Hex.parse(encrypted);
  const srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr);
  const decrypt = CryptoJS.AES.decrypt(srcs, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });
  const decryptedStr = JSON.parse(decrypt.toString(CryptoJS.enc.Utf8));
  return decryptedStr;
}

function encryptMsg(encrypted) {
  const key = CryptoJS.enc.Utf8.parse("1234123412ABCDEF"); // número hexadecimal de 16 dígitos como clave
	const iv = CryptoJS.enc.Utf8.parse('ABCDEF1234123412'); // Número hexadecimal como desplazamiento de clave

	let srcs = CryptoJS.enc.Utf8.parse(JSON.stringify(encrypted));
    let encrypted_srcs = CryptoJS.AES.encrypt(srcs, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
    let infoEncryptor = encrypted_srcs.ciphertext.toString().toUpperCase();
  return infoEncryptor;
}

export const connectSocketsN1 = () => {
  init();
  return (dispatch, getState) => {
    const { authUser } = getState().auth;
    let userRoles = authUser.roles;
    let filtered = userRoles.filter(ur => ur.name === ANALYST);
    filtered.forEach(function(elemento) {
      let message = { room_id: elemento.company_id };
      let message_temporal = encryptMsg(message);
      socket.emit("joinroom", message_temporal);
    });

    const { conversations } = getState().conversation;
    conversations.forEach(function(item) {
      socketMessage(item.conversation_id);
      let message = { room_id: item.conversation_id };
      let message_temporal = encryptMsg(message);
      socket.emit("joinroom", message_temporal);
    });

    socket.removeAllListeners();

    socket.on("error", (err) => {
        if (
            err.code === "invalid_token" &&
            err.type === "UnauthorizedError" &&
            err.message === "jwt expired"
        ) {
            console.log("Socket error with validation JWT: Expired");
        } else {
            console.log("Socket error with validation JWT: ", err);
        }

    });

  
    socket.on("chat message", async function(result) {
      result = decryptMsg(result);
      
      if (result.message.type === "add-conversation") {
        //logger.debug("TraceAgent socket-chat-message_add-conversation: ", result);
        let tempConversationInfo = JSON.parse(
          JSON.stringify(getState().conversation.conversationInfo)
        );
        tempConversationInfo.some(function(obj) {
          if (obj.company_id === result.message.conversation_info.company_id) {
            obj.count_conversations =
              result.message.conversation_info.count_conversations;
            obj.start_date = result.message.conversation_info.start_date;
            obj.end_date = result.message.conversation_info.end_date;
            return true; //breaks out of he loop
          }
        });
        //logger.debug("TraceAgent socket-chat-message_add-conversation temp: ", tempConversationInfo);
        dispatch({ type: CONVERSATION_INFO, payload: tempConversationInfo });
        dispatch(notifyMe());
        dispatch(effect());
      }
      if (result.message.type === "cancel-conversation") {
        //logger.debug("TraceAgent socket-chat-message_cancel-conversation: ", result);
        let tempConversationInfo = JSON.parse(
          JSON.stringify(getState().conversation.conversationInfo)
        );
        tempConversationInfo.some(function(obj) {
          if (obj.company_id === result.message.conversation_info.company_id) {
            obj.count_conversations =
              result.message.conversation_info.count_conversations;
            obj.start_date = result.message.conversation_info.start_date;
            obj.end_date = result.message.conversation_info.end_date;
            return true; //breaks out of he loop
          }
        });
        //logger.debug("TraceAgent socket-chat-message_cancel-conversation temp: ", tempConversationInfo);
        dispatch({ type: CONVERSATION_INFO, payload: tempConversationInfo });
        dispatch(effect());
      }
      if (result.message.type === "accept-conversation") {
        dispatch({ type: GET_WELCOME_GREETING });
        dispatch({ type: GET_AUTO_GREETING });

        let tempConversationInfo = JSON.parse(
          JSON.stringify(getState().conversation.conversationInfo)
        );

        tempConversationInfo.some(function(obj) {
          if (obj.company_id === result.message.conversation_info.company_id) {
            obj.count_conversations =
              result.message.conversation_info.count_conversations;
            obj.start_date = result.message.conversation_info.start_date;
            obj.end_date = result.message.conversation_info.end_date;
            return true; //breaks out of he loop
          }
        });

        dispatch({ type: CONVERSATION_INFO, payload: tempConversationInfo });
      }

      if (result.message.type === "text") {

        dispatch({ type: ADD_MESSAGE, payload: result.message.data });
        if (result.message.data.user_type === "END-USER") {
          dispatch(notifyMessage());
        }

        let { messages } = getState().chat;
        let messagesFilter = messages.filter(
          item => item.user_type === "END-USER" && item.status === "SENT"
        );
        let conversationsFilter = Array.from(
          new Set(messagesFilter.map(({ conversation_id }) => conversation_id))
        );


        let title = "Smart Agent";
        if (conversationsFilter.length === 0) {
          document.title = title;
        } else {
          document.title = "(" + conversationsFilter.length + ") " + title;
        }
      }

      if (result.message.type === "file") {
        if (result.message.data.user_type === "END-USER") {
          let company_id = getCompanyIdFromConversationId(
            result.message.data.conversation_id,
            JSON.parse(JSON.stringify(getState().conversation.conversations))
          );
          let url = await getUrlFromFileId(
            result.message.data.file_id,
            company_id,
            result.message.data.file_name
          );
          result.message.data.file_url = url;
          dispatch({ type: ADD_MESSAGE, payload: result.message.data });
          let { messages } = getState().chat;
          let messagesFilter = messages.filter(
            item => item.user_type === "END-USER" && item.status === "SENT"
          );
          let conversationsFilter = Array.from(
            new Set(
              messagesFilter.map(({ conversation_id }) => conversation_id)
            )
          );


          let title = "Smart Agent";
          if (conversationsFilter.length === 0) {
            document.title = title;
          } else {
            document.title = "(" + conversationsFilter.length + ") " + title;
          }
        } else {
          const { messages } = getState().chat;
          let newMessages = [];
          let count = 0;
          for (const element of messages) {
            if (
              element.conversation_id === result.message.data.conversation_id &&
              element.file_id === result.message.data.file_id
            ) {
              let company_id = getCompanyIdFromConversationId(
                result.message.data.conversation_id,
                JSON.parse(
                  JSON.stringify(getState().conversation.conversations)
                )
              );
              let url = await getUrlFromFileId(
                result.message.data.file_id,
                company_id,
                result.message.data.file_name
              );
              element.id = result.message.data.id;
              element.file_url = url;
              count++;
            }
            newMessages.push(element);
          }

          if (count > 0) dispatch({ type: INIT_MESSAGE, payload: newMessages });
        }
      }

    });

    socket.on("disconnect", function() {
      console.log("Disconnected");
    });
    socket.on("reconnect", function(result) {
      console.log("Reconnecting....");
    });

    socket.on("connect", function(result) {
      console.log("Connecting ANALYST...");
      const { authUser } = getState().auth;
      let userRoles = authUser.roles;
      userRoles
        .filter(mc => mc.name === ANALYST)
        .forEach(function(elemento) {
          let message = { room_id: elemento.company_id };
          let message_temporal = encryptMsg(message);
          socket.emit("joinroom", message_temporal);
        });

      const { conversations } = getState().conversation;
      conversations.forEach(function(item) {
        socketMessage(item.conversation_id);
        let message = { room_id: item.conversation_id };
        let message_temporal = encryptMsg(message);
        socket.emit("joinroom", message_temporal);
      });

      dispatch(getConversationInfo());
      dispatch(getConversations(authUser.id));
      dispatch(getMessages(authUser.id));
      isConnecting = false;
      notifyAgentConnection(authUser);
    });

    socket.on("seen_message", function(request) {
      const { messages } = getState().chat;
      request = decryptMsg(request);
      request.message.message_info.forEach(function(message) {
        const div = document.getElementById(message.id);
        var notes = null;
        if (div) {
          for (var i = 0; i < div.childNodes[0].childNodes.length; i++) {
            if (div.childNodes[0].childNodes[i].className == "time-message") {
              div.childNodes[0].childNodes[i].children[1].classList.add("read");
              let newMessages = [];
              let count = 0;
              for (const element of messages) {
                if (element.id == message.id) {
                  element.status = message.status;
                }
                count++;
                newMessages.push(element);
              }
              if (count > 0)
                dispatch({ type: INIT_MESSAGE, payload: newMessages });
              break;
            } else if (
              div.childNodes[0].childNodes[i].className == "docs file-only"
            ) {
              div.childNodes[0].childNodes[i].children[3].classList.add("read");
              let newMessages = [];
              let count = 0;
              for (const element of messages) {
                if (element.id == message.id) {
                  element.status = message.status;
                }
                count++;
                newMessages.push(element);
              }
              if (count > 0)
                dispatch({ type: INIT_MESSAGE, payload: newMessages });
            }
          }
        }
      });
    });
  };
};

export const connectSocketsDashboard = () => {
  init();
  return (dispatch, getState) => {
    console.log("Connecting to sockets MANAGER");
    const { authUser } = getState().auth;
    let userRoles = authUser.roles;
    let filtered = userRoles.filter(ur => ur.name === MANAGER);
    filtered.forEach(function(elemento) {
      let message = { room_id: elemento.company_id };
      let message_temporal = encryptMsg(message);
      socket.emit("joinroom", message_temporal);
    });

    socket.removeAllListeners();

    socket.on("conversation_state", function(request) {
      request = decryptMsg(request);
      const { messageDashboard } = getState().conversation;
      //.debug("TraceAgent socket-conversation_state: ", request);
      //logger.debug("TraceAgent socket-conversation_state dash: ", getState().conversation);
      let foundConversation = messageDashboard.find(
        m =>
          m.conversation_id ===
          request.message.conversation_info.conversation_id
      );
      //logger.debug("TraceAgent socket-conversation_state found: ", foundConversation);
      if (foundConversation) {
        let newMessages = messageDashboard.filter(
          m => m.conversation_id !== foundConversation.conversation_id
        );
        newMessages.push(request.message.conversation_info);
        dispatch({ type: INIT_MESSAGE_DASHBOARD, payload: newMessages });
      } else {
        dispatch({
          type: ADD_MESSAGE_DASHBOARD,
          payload: request.message.conversation_info
        });
        
      }
      let messagesCanceled;
      if (
        request.message.conversation_info.current_state_name ===
          "canceled-refer" ||
        request.message.conversation_info.current_state_name ===
          "closed-timeout"
      ) {
        messagesCanceled = request.message.conversation_info;
        dispatch({
          type: ADD_CANCEL_MESSAGE_DASHBOARD,
          payload: messagesCanceled
        });
      }
    });

    //*************** F.G.H. Estado de Agentes */
    socket.on("analyst_state",function(request){
      const { agentDashboard } = getState().conversation;
      let newAgentsDashboard = agentDashboard.filter(
        m => m.company_id != request.room_id
      );
    newAgentsDashboard = newAgentsDashboard.concat(request.connections);
      dispatch({ type: INIT_MESSAGE_AGENTE_STATUS, payload: newAgentsDashboard });
    })

    socket.on("connections_updated", function(request) {
      const { agentDashboard } = getState().conversation;
      let newAgentsDashboard = agentDashboard.filter(
        m => m.company_id != request.room_id
      );
      newAgentsDashboard = newAgentsDashboard.concat(request.connections);
      dispatch({ type: INIT_AGENT_DASHBOARD, payload: newAgentsDashboard });
    });

    socket.on("disconnect", function() {
      console.log("Disconnected");
    });
    socket.on("reconnect", function(result) {
      console.log("Reconnecting....");
    });

    socket.on("connect", function(result) {
      console.log("Connecting MANAGER...");
      const { authUser } = getState().auth;
      let userRoles = authUser.roles;
      userRoles
        .filter(mc => mc.name === MANAGER)
        .forEach(function(elemento) {
          let message = { room_id: elemento.company_id };
          let message_temporal = encryptMsg(message);
          socket.emit("joinroom", message_temporal);
        });

      dispatch(getThresholdsManager());
      dispatch(getActiveConversationsStates());
      dispatch(getCanceledConversationsStates());
      dispatch(getAgentsConectedStates());
      isConnecting = false;
    });
  };
};

export const socketOff = (event, room_id) => {
  return dispatch => {
    socket.off("chat message");
  };
};

export const socketTyping = (room_id, event) => {
  return dispatch => {
    let message = {
      room_id: room_id,
      message: { type: event, conversation_id: room_id }
    };
    let message_temporal = encryptMsg(message);
    socket.emit("typing", message_temporal);
  };
};

export const leaveRoom = room_id => {
  init();
  return dispatch => {
    let message = { room_id: room_id };
    console.log("REMOVE_CONNECTION");
    let message_temp = encryptMsg(message);
    socket.emit("leave_room", message_temp);
    dispatch({ type: REMOVE_CONNECTION, payload: message });
  };
};

export const removeAllSocket = () => {
  return async (dispatch, getState) => {
    if (!socket || socket.disconnected) {
      console.log("Socket off at removeAllSocket");
      return;
    }
    const { authUser } = getState().auth;
    const { conversations } = getState().conversation;
    let multiclient = authUser.roles;
    multiclient.forEach(function(elemento) {
      let message = { room_id: elemento.company_id };
      let message_temp = encryptMsg(message);
      socket.emit("leave_room", message_temp);
    });

    conversations.forEach(function(item) {
      socketMessage(item.conversation_id);
      let message = { room_id: item.conversation_id };
      let message_temp = encryptMsg(message);
      socket.emit("leave_room", message_temp);
    });
  };
};

export const socketMessage = room_id => {
  init();
  return (dispatch, getState) => {
    const { connects } = getState().conversation;
    const { authUser } = getState().auth;
    let connect = connects.filter((item, index) => item.room_id === room_id);
    if (connect.length === 0) {
      let message = { room_id: room_id };
      let message_temp = encryptMsg(message);
      socket.emit("joinroom", message_temp);
      dispatch({ type: ADD_CONNECTION, payload: message });
    }
  };
};

export const init = () => {
  console.log("iniciando socket");
  console.log(process.env.REACT_APP_URL_SOCKET);
  if (!socket || (socket.disconnected && !isConnecting)) {
    isConnecting = true;
    socket = io.connect(process.env.REACT_APP_URL_SOCKET, {
      query: `token=${localStorage.getItem("token")}`
    });
  }
};

export const disconnectSocket = () => {
  return (dispatch, getState) => {
    if (socket) {
      socket.disconnect();
    }
  };
};

function notifyAgentConnection(authUser) {
  const os = platform.os;
  const { architecture, family, version } = os;
  const setOs = family + " " + version + " " + architecture;
  const browser = platform.name + " " + platform.version;
  const user = {
    agent_id: authUser.id,
    os: setOs,
    browser: browser,
    roles: authUser.roles
  };
  let message_temporal = encryptMsg(user);
  socket.emit("agent_connected", message_temporal);
}
