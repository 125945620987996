import {
	INIT_URL,
	CONVERSATION_INFO,
	ADD_CONVERSATION,
	INIT_CONVERSATIONS,
	SHOW_CONVERSATION,
	ADD_CONNECTION,
	REMOVE_CONNECTION,
	REMOVE_CONVERSATION,
	HIDE_CONVERSATION,
	SET_NOTIFY_SOUND,
	SHOW_THRESHOLDS,
	LOADING,
	LOAD_USERS_LOADING,
	LOAD_USERS_SUCCESS,
	LOAD_USERS_ERROR,
	INIT_HISTORIAL,
	ADD_HISTORIAL,
	LOAD_HISTORIAL,
	LOAD_SUCESS_HISTORIAL,
	CLEAN_HISTORIAL,
	INIT_AGENT_DASHBOARD,
	INIT_MESSAGE_DASHBOARD,
	ADD_MESSAGE_DASHBOARD,
	REMOVE_MESSAGE_DASHBOARD,
	CANCEL_MESSAGE_DASHBOARD,
	ADD_CANCEL_MESSAGE_DASHBOARD,
	DATA_CANCEL_DASHBOARD,
	SHOW_THRESHOLDS_MANAGERS,
	INIT_MESSAGE_AGENTE_STATUS
} from '../../constants/ActionTypes';

const INIT_STATE = {
	conversationInfo: {},
	conversation: {},
	conversations: [],
	connects: [],
	initURL: '',
	showContainer: false,
	containerInfo: {},
	silentNotify: false,
	thresholds: [],
	loading: false,
	error: '',
	loadingError: false,
	loadingTicket: true,
	loadingTickets: true,
	permissions: [],
	historialList: [],
	loadingHistorial: false,
	messageDashboard: [],
	agentDashboard: [],
	cancelmessageDashboard: [],
	dataCancelDashboard: [],
	thresholdsManagersList: [],
	messageAgentStatus: []
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case INIT_URL: {
			return { ...state, initURL: action.payload };
		}

		case INIT_CONVERSATIONS: {
			return {
				...state,
				conversations: action.payload
			};
		}

		case CONVERSATION_INFO: {
			return {
				...state,
				conversationInfo: action.payload
			};
		}

		case SHOW_CONVERSATION: {
			return {
				...state,
				showContainer: true,
				containerInfo: action.payload
			};
		}

		case HIDE_CONVERSATION: {
			return {
				...state,
				showContainer: false
			};
		}

		case SET_NOTIFY_SOUND: {
			return {
				...state,
				silentNotify: action.payload
			};
		}

		case ADD_CONVERSATION: {
			return {
				...state,
				conversations: [ ...state.conversations, action.payload ]
			};
		}

		case REMOVE_CONVERSATION: {
			return {
				...state,
				conversations: state.conversations.filter((x) => x.conversation_id !== action.payload)
			};
		}

		case ADD_CONNECTION: {
			return {
				...state,
				connects: [ ...state.connects, action.payload ]
			};
		}

		case REMOVE_CONNECTION: {
			return {
				...state,
				connects: state.connects.filter((x) => x.room_id !== action.payload.room_id)
			};
		}
		case SHOW_THRESHOLDS: {
			return {
				...state,
				thresholds: action.payload
			};
		}
		case LOADING: {
			return {
				...state,
				loading: action.payload,
				loadingError: action.payload,
				loadingTicket: action.payload
			};
		}
		case LOAD_USERS_LOADING: {
			return {
				...state,
				loading: true,
				loadingTickets: false,
				error: ''
			};
		}
		case LOAD_USERS_SUCCESS: {
			return {
				...state,
				data: action.payload,
				loading: false,
				loadingTickets: true,
				error: false
			};
		}
		case LOAD_USERS_ERROR: {
			return {
				...state,
				loading: false,
				loadingTickets: false,
				error: action.error
			};
		}
		case INIT_HISTORIAL: {
			return {
				...state,
				historialList: action.payload
			};
		}
		case ADD_HISTORIAL: {
			return {
				...state,
				historialList: [ ...state.historialList, action.payload ]
			};
		}
		case CLEAN_HISTORIAL: {
			return {
				...state,
				historialList: []
			};
		}
		/** */
		case LOAD_HISTORIAL: {
			return {
				...state,
				loadingHistorial: true
			};
		}
		case LOAD_SUCESS_HISTORIAL: {
			return {
				...state,
				loadingHistorial: false
			};
		}

		

		/*DASHBOARD */
		case INIT_AGENT_DASHBOARD: {
			return {
				...state,
				agentDashboard: action.payload
			};
		}
		case INIT_MESSAGE_DASHBOARD: {
			return {
				...state,
				messageDashboard: action.payload
			};
		}
		case ADD_MESSAGE_DASHBOARD: {
			return {
				...state,

				messageDashboard: [ ...state.messageDashboard, action.payload ]
			};
		}
		case REMOVE_MESSAGE_DASHBOARD: {
			return {
				...state,

				messageDashboard: state.messageDashboard.filter((x) => x.id !== action.payload)
			};
		}
		case CANCEL_MESSAGE_DASHBOARD: {
			return {
				...state,
				cancelmessageDashboard: action.payload
			};
		}
		case ADD_CANCEL_MESSAGE_DASHBOARD: {
			return {
				...state,
				cancelmessageDashboard: [ ...state.cancelmessageDashboard, action.payload ]
			};
		}
		case DATA_CANCEL_DASHBOARD: {
			return {
				...state,
				dataCancelDashboard: action.payload
			};
		}
		case SHOW_THRESHOLDS_MANAGERS: {
			return {
				...state,
				thresholdsManagersList: action.payload
			};
		}
		case INIT_MESSAGE_AGENTE_STATUS: {
			return {
				...state,
				messageAgentStatus: action.payload
			};
		}
		//CANCEL_MESSAGE_DASHBOARD
		//Fuera de tiempo
		//PERMISSIONS

		default:
			return state;
	}
};
