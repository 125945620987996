import React from 'react';
import { connect } from 'react-redux';

/*import {Link} from "react-router-dom";*/
import 'material-design-icons/iconfont/material-icons.css';
import './login.css';

import background_login from '../../assets/images/background_login-compressor.png';
import { userSignIn, getUser, userMenu } from '../../appRedux/actions/Auth';
import createBrowserHistory from 'history/createBrowserHistory';
import { message } from 'antd';
/*import IntlMessages from "util/IntlMessages";*/
var platform = require('platform');

const history = createBrowserHistory();

let variableText;
class SignIn extends React.Component {
	constructor() {
		super();
		this.state = {
			isPasswordShown: false
		};
	}
	getClass(statusCode) {
		let className = '';
		switch (statusCode) {
			case 401: {
				className = 'message-error';
				variableText = 'Credenciales incorrectas';
				break;
			}
			case 403: {
				className = 'message-error';
				variableText = 'Usuario inactivo';
				break;
			}
			default:
				className = 'default';
				variableText = '';
		}

		return className;
	}


	togglePasswordVisibility = (event) => {
		const { isPasswordShown } = this.state;

		this.setState({ isPasswordShown: !isPasswordShown });
	};

	handleSubmit = async (e) => {
		e.preventDefault();
		const os = platform.os;
		const { architecture, family, version } = os;
		const setOs = family + ' ' + version + ' ' + architecture;
		const browser = platform.name + ' ' + platform.version;
		let values = {
			email: e.target[0].value,
			password: e.target[1].value,
			os: setOs,
			browser: browser
		};

		await this.props.userSignIn(values);
		this.props.getUser(values);

	
	};

	componentDidUpdate() {
		if (this.props.token !== null && this.props.token !== '') {
			this.props.history.push('/');
		}
	}
	componentDidMount() {
		//history.replace({ pathname: '/main' })
	}



	render() {
		const { isPasswordShown } = this.state;
		const { statusCode } = this.props;
		let variableError;
		if (statusCode === undefined) {
			variableError = '';
		} else {
			variableError = (
				<div className={` input-field col s12 ${this.getClass(this.props.statusCode)}`}>
					<p>{variableText}</p>
				</div>
			);
		}
		return (
			<div className="row">
				<div className="col s6 login">
					<div className="container-login">
						<h3> Iniciar sesión</h3>{' '}
						<form onSubmit={this.handleSubmit} autoComplete="off">
							<div className="input-field col s12">
								<label className="label" htmlFor="email">
									Email{' '}
								</label>{' '}
								<input name="username" id="email" type="email" className="input-login input-default" />
							</div>{' '}
							<div className="input-field col s12">
								<label className="label" htmlFor="password">
									Contraseña{' '}
								</label>{' '}
								<input
									name="password"
									id="password"
									type={isPasswordShown ? 'text' : 'password'}
									className="input-login input-default"
								/>
								<i
									className="i-eyes material-icons icon-eye"
									onClick={() => {
										this.togglePasswordVisibility();
									}}
								>
									remove_red_eye{' '}
								</i>
							</div>
							<button className="btn waves-effect waves-light btn-login" type="submit" name="action">
								Iniciar sesión
							</button>
							<div>{variableError}</div>
						</form>
						<span className="version"> v .3 </span>{' '}
					</div>
				</div>
				<div className="col s6">
					<img src={background_login} alt="login" className="responsive-img" />
				</div>
			</div>
		);
	}
}

const mapStateToProps = ({ auth }) => {
	const { token, authUser, userMenu, statusCode } = auth;
	return { token, authUser, userMenu, statusCode };
};

export default connect(mapStateToProps, { userSignIn, getUser, userMenu })(SignIn);
