import React from "react";
import "./dateDisplay.css";

export default class DateDisplay extends React.Component {
  state = {
    date: ""
  };

  componentDidMount() {
    this.getDate();
  }

  getDate = () => {
    let today = new Date();
    let date=today.getDate() + "/"+ (parseInt(today.getMonth()+1) < 10? "0"+parseInt(today.getMonth()+1):parseInt(today.getMonth()+1)) +"/"+today.getFullYear();
    this.setState({ date });
  };

  render() {
    const { date } = this.state;

    return <div className="date__format">Fecha: {date}</div>;
  }
}