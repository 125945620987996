/* eslint-disable no-useless-constructor */
import React from 'react';
import Moment from 'react-moment';
import moment from 'moment/min/moment-with-locales';
import iconClose from '../../../assets/images/close-img.png';
import '../InputField/inputfield.css';
import './fileImage.css';
let prueba = '0';
class FileImage extends React.Component {
	constructor(props) {
		super(props);
		this.M = window.M;
		this.modalImages = this.modalImages.bind(this);
		this.modalImagen = React.createRef();
		this.imgModal = React.createRef();
		this.imagenFile = React.createRef();
		this.modalClose = React.createRef();

		this.state = {
			date: new Date()
		};
	}
	componentDidMount() {
		//activación del modal
		var elems = document.querySelectorAll('.materialboxed');
		var instances = this.M.Materialbox.init(elems);
	}
	//upload
	//

	getClass(userType) {
		let className = '';
		switch (userType) {
			case 'in': {
				className = 'agent';
				break;
			}
			case 'out': {
				className = 'user';
				break;
			}
			default:
				className = userType;
		}

		return className;
	}
	modalImages(e) {

		this.modalImagen.current.style.display = 'block';
		this.imgModal.current.src = this.imagenFile.current.src;
		prueba = this.props.direction;

	}
	closeModal(e) {
		prueba = '4';
		this.modalImagen.current.style.display = 'none';
	}
	render() {
		const getFormat = (d) => {
			const expiration = moment(d);
			let hours = expiration.hours();
			let minutes = expiration.minutes();
			if (minutes <= 9) {
				minutes = '0' + minutes;
			}
			return hours + ':' + minutes;
		};
		const reactStringReplace = require('react-string-replace');
		const text = this.props.message;
		const message_id = this.props.message_id;

		let Component =
			message_id === undefined ? (
				<div className="loader" />
			) : (
				<div>
					<img
						ref={this.imagenFile}
						onClick={(e) => {
							this.modalImages(e);
						}}
						src={this.props.file_url}
						width="100%"
						alt="user-img-container"
					/>
					<div
						ref={this.modalImagen}
						onClick={(e) => {
							this.closeModal(e);
						}}
						className="modal-img"
					>
						<span
							ref={this.modalClose}
							onClick={(e) => {
								this.closeModal(e);
							}}
							className="close"
						>
							<img src={iconClose} alt="modal-img-close" className="responsive-img" />
						</span>
						<img ref={this.imgModal} className="modal-content-img" id="img01" />
					</div>
				</div>
			);

		let replacedText;
		replacedText = reactStringReplace(text, /(https?:\/\/\S+)/g, (match, i) => (
			<a key={match + i} href={match} target="_blank" rel="noopener noreferrer">
				{match}
			</a>
		));


		return (
			<div id={this.props.id} className="message__row">
				<span className={`messages message--${this.props.direction}`}>
					<span id="meli">{Component}</span> <br />
					<span id="meli">{replacedText}</span>
					<p className="time-message">
						<Moment filter={getFormat} interval={1000} date={this.props.createdAt} />
						<i
							className={
								this.props.user_type === 'ANALYST' ? this.props.status === 'SENT' ? (
									'icon-read'
								) : this.props.status === 'ANSWERED' || this.props.status === 'READ' ? (
									'icon-read read'
								) : (
									''
								) : (
									''
								)
							}
						/>
					</p>
				</span>
			</div>
		);
	}
}
export default FileImage;
