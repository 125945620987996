/* eslint-disable default-case */
import React from "react";
import "./tableRow.css";
import CanvasJSReact from "../../../assets/vendors/canvasjs.react";
import Moment from "react-moment";
import moment from "moment/min/moment-with-locales";
import { connect } from "react-redux";
import { getThresholdsManager } from "../../../appRedux/actions/Companies";
import {
  //getThresholds,
  //updateConversationState,
  //removeMessage,
  getAgentsConectedStates
} from "../../../appRedux/actions/Conversation";


var CanvasJSChart = CanvasJSReact.CanvasJSChart;

class TableAgentStatusRow extends React.Component {
  constructor() {
    super();
    this.M = window.M;
    this.stateItem = React.createRef();
    this.rowItem = React.createRef();
    this.stateDefault = React.createRef();
    this.stateMessage = React.createRef();
  }

  componentDidMount() {
    this.M.Tabs.init(this.Tabs);
  }
  componentWillMount() {
    //this.props.getThresholds();
    //this.props.getThresholdsManager();
    this.props.getAgentsConectedStates();
  }
  getClassState(stateClient) {
    let className = "";
    switch (stateClient) {
      case "Atendiendo": {
        className = "attend";
        break;
      }
      case "En cola": {
        className = "without-attend";
        break;
      }
      case "Cerrada": {
        className = "word";
        break;
      }
      case "Disponible": {
          className = "disponible"
          break
      }
      default:
        className = "";
    }
    return className;
  }
  getClassStateClose(stateClose) {
    let className = "";
    switch (stateClose) {
      case "closed-timeout": {
        className = "close";
        break;
      }
      case "canceled-refer": {
        className = "close";
        break;
      }
      default:
        className = "default";
    }
    return className;
  }
  render() {
    const {
      //state,
      current_state_agente,
      corporation,
      company,
      queue_time,
      last_message_created_at,
      referring_created_at,
      id,
      company_id,
      state_name,
      agent_first_name,
      agent_last_name,
      thresholdsManager,
      channelName,
      name
    } = this.props;
    //let stateItem = state;
    let stateItem = current_state_agente;
    let idRow = id + "-row";
    let nameAgent;
    let lastNameAgent;
    if (agent_first_name) {
      nameAgent = agent_first_name;
    } else {
      nameAgent = "--";
    }
    if (agent_last_name) {
      lastNameAgent = agent_last_name;
    } else {
      lastNameAgent = "--";
    }
    let fullNameAgent = nameAgent + " " + lastNameAgent + " (" + name + ") ";
    let idTime = id + "first";
    /*row */
    const getFormatInit = d => {
      const expiration = moment(d);

      let hours = expiration.hours() + 5;
      //let hours = expiration.hours();
      if (hours == 24) hours = "00";
      let minutes = expiration.minutes();
      if (minutes <= 9) {
        minutes = "0" + minutes;
      }
      return hours + ":" + minutes;
    };
    var result = "";
    let resultSeconds;
    const getFormatSecond = d => {
      var moment = require("moment");
      const now = moment();

      const expiration = moment(d);

      const diff = expiration.diff(now);
      const diffDuration = moment.duration(diff);

      var rhours = Math.abs(diffDuration.hours() + 5);
      var rminutes = Math.abs(diffDuration.minutes());
      var rseconds = Math.abs(diffDuration.seconds());

      if (rhours <= 9) {
        rhours = "0" + rhours;
      } else if (rminutes <= 9) {
        rminutes = "0" + rminutes;
      } else if (rseconds <= 9) {
        rseconds = "0" + rseconds;
      }
      result = rhours + "h ";
      result += rminutes + "m ";
      result += rseconds + "s";
      if (result === "") result = "-";
      resultSeconds = rminutes * 60 + rseconds;
      return result;
    };

    const getFormat = d => {
      var moment = require("moment");
      const now = moment();
      const expiration = moment(d);
      const diff = expiration.diff(now);
      const diffDuration = moment.duration(diff);

      var rhours = Math.abs(diffDuration.hours() + 5);
      //var rhours = Math.abs(diffDuration.hours());
      var rminutes = Math.abs(diffDuration.minutes());
      var rseconds = Math.abs(diffDuration.seconds());
      if (rhours <= 9) {
        rhours = "0" + rhours;
      } else if (rminutes <= 9) {
        rminutes = "0" + rminutes;
      } else if (rseconds <= 9) {
        rseconds = "0" + rseconds;
      }
      result = rhours === 0 ? "" : rhours + ":";
      result += rminutes === 0 ? "" : rminutes + ":";
      result = rseconds ? rseconds : 0;
      result = rhours + "h " + rminutes + "m " + rseconds + "s";

      if (result === "") result = "-";
      let resultTime = rminutes * 60 + rseconds;

      if (thresholdsManager.length > 0) {
        let companyThresholds = thresholdsManager.filter(
          t => t.company_id === company_id
        );

        let warning1 = companyThresholds.find(
          ct => ct.param_name === "THRESHOLD_WARNING_1"
        );
        let warning2 = companyThresholds.find(
          ct => ct.param_name === "THRESHOLD_WARNING_2"
        );
        if (resultTime > warning2.param_value) {
          if (this.rowItem.current) {
            this.rowItem.current.classList.remove("threshold-none");
            this.rowItem.current.classList.remove("threshold-low");
            this.rowItem.current.classList.add("threshold-high");
            this.stateDefault.current.classList.add("hide-threshold");
            this.stateMessage.current.textContent = "En Demora";
            this.stateMessage.current.classList.remove("threshold-first");
            this.stateMessage.current.classList.remove("hide-threshold");
            this.stateMessage.current.classList.add("threshold-second");
          }

        } else if (resultTime > warning1.param_value) {
          if (this.rowItem.current) {
            this.rowItem.current.classList.remove("threshold-high");
            this.rowItem.current.classList.remove("threshold-none");
            this.rowItem.current.classList.add("threshold-low");
            this.stateDefault.current.classList.add("hide-threshold");

            this.stateMessage.current.textContent = "En espera";
            this.stateMessage.current.classList.remove("hide-threshold");
            this.stateMessage.current.classList.remove("threshold-second");
            this.stateMessage.current.classList.add("threshold-first");
            if (this.rowItem.current.classList.contains("threshold-low")) {
            }
          }
        } else {
          this.rowItem.current.classList.remove("threshold-high");
          this.rowItem.current.classList.remove("threshold-low");
          this.rowItem.current.classList.add("threshold-none");
          this.stateDefault.current.classList.remove("hide-threshold");
          this.stateMessage.current.classList.remove("threshold-first");
          this.stateMessage.current.classList.remove("threshold-second");
          this.stateMessage.current.classList.add("hide-threshold");
        }
      }
      return result;
    };

    let waitingTime;
    if (queue_time !== null) {
      let queue_hours = queue_time.hours ? queue_time.hours : 0;
      let queue_minutes = queue_time.minutes ? queue_time.minutes : 0;
      let queue_seconds = queue_time.seconds ? queue_time.seconds : 0;
      waitingTime =
        queue_hours + "h " + queue_minutes + "m " + queue_seconds + "s";
    } else {
      /*waitingTime = (
        <Moment
          filter={getFormatSecond}
          interval={1000}
          date={referring_created_at}
        />
      );*/
      waitingTime = "----";
    }
    if (
      state_name === "closed-attended" ||
      state_name === "closed-refered" ||
      state_name === "closed-inactiveuser"
    ) {
      let idItem = id;
      var item = document.getElementById(idItem);

    }
    let momentTime;
    let classItem;
    let classOther;
    let classFirst;
    if (last_message_created_at !== null) {
      momentTime = (
        <Moment
          filter={getFormat}
          interval={1000}
          date={last_message_created_at}
        />
      );
    } else {

      momentTime = "----";
      classFirst = "show-item";
      classOther = "hide-threshold";
      classItem = "box-color";
    }

    let timerIniConversation;
    if(referring_created_at!=null){
      timerIniConversation = (
              <Moment
                filter={getFormatInit}
                interval={1000}
                date={referring_created_at}
              />);
    } else {
      timerIniConversation = "----";
    }
    

    return (
      <tr
        id={idRow}
        ref={this.rowItem}
        className={`${classItem} dashboard__row dashboard__column--${this.getClassStateClose(
          state_name
        )}`}
      >
        <td>{fullNameAgent}</td>
        <td>
         <p
            ref={this.stateDefault}
            id={id}
            className={`${classFirst} dashboard__stateAgente dashboard__stateAgente--${this.getClassState(
              this.props.current_state_agente
            )}`}
          > 
            {stateItem}
          </p>
          <p
            id={idTime}
            ref={this.stateMessage}
            className={`hide-threshold ${classOther} dashboard__stateAgente`}
          >
            demora
          </p>
        </td>
        <td>{waitingTime}</td>
        <td>
            {timerIniConversation}
        </td>
        <td>{momentTime}</td>
      </tr>
    );
  }
}


const mapStateToProps = ({ conversation, companies }) => {
  const { thresholds, thresholdsManagersList } = conversation;
  const { thresholdsManager } = companies;
  return { thresholds, thresholdsManager, thresholdsManagersList };
};

export default connect(mapStateToProps, {
  //getThresholds,
  //getThresholdsManager,
  //updateConversationState,
  //removeMessage,
  getAgentsConectedStates
})(TableAgentStatusRow);