import React from 'react';
import './containerWelcome.css';
import pageWelcome from '../../../assets/images/page-welcome.png';
class ContainerWelcome extends React.Component {
	render() {
		return (
			<div className="Rectangle">
				<img src={pageWelcome} className="Imagen" alt="" />
				<p className="Inicia-una-conversac">
					{' '}
					¡Bienvenido a la Plataforma N1!<br /> Comienza a explorar las secciones que tenemos<br /> para ti en
					el Menú de la izquierda.
				</p>
			</div>
		);
	}
}

export default ContainerWelcome;
