import React from 'react';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import './itemChat.css';
import CirclePerson from '../CirclePerson/CirclePerson';
import NamePerson from '../NamePerson/NamePerson';
import {
	getConversationInfo,
	attendConversation,
	showConversation,
	getThresholds
} from '../../../appRedux/actions/Conversation';


class ItemChat extends React.Component {
	constructor() {
		super();
		this.titles = React.createRef();
	}
	componentDidMount() {}

	render() {
		const {
			user_first_name,
			user_first_surname,
			handleInitConversation,
			lastMessage,
			item,
			containerInfo,
			thresholds
		} = this.props;
		const { logo } = item;
		var result = '';
		let resultSeconds;
		/*umbral */
		const getFormat = (d) => {
			var moment = require('moment');
			const now = moment();
			const expiration = moment(d);
			const diff = expiration.diff(now);
			const diffDuration = moment.duration(diff);
			var rhours = Math.abs(diffDuration.hours());
			var rminutes = Math.abs(diffDuration.minutes());
			var rseconds = Math.abs(diffDuration.seconds());

			result = rhours === 0 ? '' : rhours + ' h ';
			result += rminutes === 0 ? '' : rminutes + ' m ';
			result += rhours > 0 ? '' : rseconds + ' s ';
			resultSeconds = rminutes * 60 + rseconds;

			if (result === '') result = '-';
			let umbrales = [];

			thresholds.forEach((itemThreshold) => {
				if (itemThreshold.company_id === item.company_id) {
					umbrales.push(itemThreshold.value);
					if (resultSeconds > itemThreshold.param_value) {
						if (itemThreshold.param_name === 'THRESHOLD_WARNING_1') {

							if (this.titles.current) {
								this.titles.current.classList.add('threshold-low');
							}
						} else if (itemThreshold.param_name === 'THRESHOLD_WARNING_2') {
							if (this.titles.current) {
								this.titles.current.classList.remove('threshold-low');
								this.titles.current.classList.add('threshold-high');
							}
						}
					}
				}
			});
			return result;
		};
		let moment;
		let classItem;
		if (lastMessage.message !== undefined) {
			moment = <Moment filter={getFormat} interval={1001} date={lastMessage.message.created_at} />;
		} else {
			moment = '';
			let ref = this;
			classItem = 'box-color';
		}

		return (
			<div className="box-chat">
				<li id="item" ref={this.titles} className={classItem} onClick={handleInitConversation}>
					<CirclePerson
						user_first_name={user_first_name}
						user_first_surname={user_first_surname}
						logo={logo}
					/>
					<div className="personal-information ">
						<NamePerson fullName={user_first_name + ' ' + user_first_surname} />
						<p>{lastMessage.message === undefined ? '' : lastMessage.message.message_text}</p>
					</div>
					<div className="extra-information">
						<span id="time" className="time">
							{moment}
						</span>
						{lastMessage.message === undefined ? (
							''
						) : (
							<div className="message ">
								<p className="mt-0 mb-0">{lastMessage.count_message}</p>
							</div>
						)}
					</div>
				</li>
			</div>
		);
	}
}

const mapStateToProps = ({ conversation, auth, chat }) => {
	const { conversationInfo, conversations, containerInfo, thresholds } = conversation;
	const { authUser } = auth;
	const { messages } = chat;

	return {
		conversationInfo,
		authUser,
		conversations,
		containerInfo,
		messages,
		thresholds
	};
};

export default connect(mapStateToProps, {
	getConversationInfo,
	attendConversation,
	showConversation,
	getThresholds
})(ItemChat);
