import React from 'react';
import './containerblank.css';
import adminQuickReplies from '../../../assets/images/page-user-detail.png';

class ContainerBlank extends React.Component {
	render() {
		return (
			<div className="admin-replies__container-replies">
				<img src={adminQuickReplies} alt="img-default" className="responsive-img" />
				<p>
					Si deseas configurar a tus usuarios. inicia una búsqueda en la barra izquierda
				</p>
			</div>
		);
	}
}

export default ContainerBlank;
