import React from 'react';
import Moment from 'react-moment';
import moment from 'moment/min/moment-with-locales.js';
import './message.css';

class MessageAgent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			date: new Date()
		};
	}
	componentDidMount() {
		this.getDate();
	}
	getDate() {
		const expiration = moment();
		let seconds = expiration.seconds();
	}
	render() {
		const getFormat = (d) => {
			const expiration = moment(d);
			let hours = expiration.hours();
			let minutes = expiration.minutes();
			if (minutes <= 9) {
				minutes = '0' + minutes;
			}
			return hours + ':' + minutes;
		};

		const reactStringReplace = require('react-string-replace');
		const text = this.props.message;
		let replacedText;
		replacedText = reactStringReplace(text, /(https?:\/\/\S+)/g, (match, i) => (
			<a key={match + i} href={match} target="_blank" rel="noopener noreferrer">
				{match}
			</a>
		));
	return (
			<div id={this.props.id} className="message__row">
				<span className={`messages message--${this.props.direction}`}>
					<span id="meli">{replacedText}</span>

					<p className="time-message">
						<Moment filter={getFormat} interval={1000} date={this.props.createdAt} />

						<i
							className={
								this.props.user_type === 'ANALYST' ? this.props.status === 'SENT' ? (
									'icon-read'
								) : this.props.status === 'ANSWERED' || this.props.status === 'READ' ? (
									'icon-read read'
								) : (
									''
								) : (
									''
								)
							}
						/>
					</p>
				</span>
			</div>
		);
	}
}

export default MessageAgent;
