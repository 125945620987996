import React from 'react';
import './itemCompany.css';
import { connect } from 'react-redux';

class ItemCompany extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { name, logo, handleInitConversation } = this.props;
		return (
			<div className="admin-replies__item-company" onClick={handleInitConversation}>
				<div className="admin-replies__img-company">
					<img src={logo} alt="logo-company" className="responsive-img" />
				</div>

				<p>{name}</p>
			</div>
		);
	}
}

const mapStateToProps = ({ conversation, auth, chat }) => {
	const { conversationInfo, containerInfo, thresholds } = conversation;
	const { authUser } = auth;
	const { welcomeGreetings } = chat;
	return {
		conversationInfo,
		containerInfo,
		authUser,
		welcomeGreetings,
		thresholds
	};
};

export default connect(mapStateToProps, {})(ItemCompany);
