/* eslint-disable no-useless-constructor */
import React from 'react';
import Moment from 'react-moment';
import moment from 'moment/min/moment-with-locales';

import '../../modulo-chat/InputField/inputfield.css';
class FileDocs extends React.Component {
	constructor(props) {
		super(props);
	}
	//upload
	saveFile(value1,value2){
		let a = document.createElement('a');
		a.href = value2;
		a.setAttribute('target', '_blank');
		a.click();
	}
	getClass(extension) {
		let className = '';
		switch (extension) {
			case 'docx': {
				className = 'word';
				break;
			}
			case 'pdf': {
				className = 'pdf';
				break;
			}
			case 'xlsx': {
				className = 'xlsx';
				break;
			}
			default:
				className = extension;
		}

		return className;
	}
	IsJsonString(text) {
		try {
			JSON.parse(text);
		} catch (e) {
			return false;
		}
		return true;
	}
	render() {
		const reactStringReplace = require('react-string-replace');
		let text = this.props.message;
		let isJson = this.IsJsonString(text);
		if (isJson) {
			text = '';
		}
		const message_id = this.props.message_id;
		let replacedText;
		replacedText = reactStringReplace(text, /(https?:\/\/\S+)/g, (match, i) => (
			<a key={match + i} href={match} target="_blank" rel="noopener noreferrer">
				{match}
			</a>
		));
		let file_name = this.props.file_name;
		let file_url = this.props.file_url;
		let extension = this.props.extension;

		const getFormat = (d) => {
			const expiration = moment(d);
			let hours = expiration.hours();
			let minutes = expiration.minutes();
			if (minutes <= 9) {
				minutes = '0' + minutes;
			}
			return hours + ':' + minutes;
		};

		let componentFile =
			message_id === undefined ? (
				<div className="loader" />
			) : (
				<div className="docs file-only">
					<div data={file_url}>
						<div className="description-file">
							<div>
								<div
									ref={(e) => {
										this.boxextension = e;
									}}
									id="box-extension"
									className={`box-extension box-extension--${this.getClass(this.props.extension)}`}
								>
									<span>{extension}</span>
								</div>
							</div>
							<div>
								<a href='#' onClick={() => { this.saveFile(file_name,file_url) }} className="download file">
									{file_name}
								</a>
							</div>
						</div>
					</div>
					<span id="meli" className="text-file-doc">
						{replacedText}
					</span>
					<p className="time-message mb-0">
						<Moment filter={getFormat} interval={1000} date={this.props.createdAt} />
					</p>
					<i
						class={
							this.props.user_type === 'ANALYST' ? this.props.status === 'SENT' ? (
								'icon-read'
							) : this.props.status === 'ANSWERED' || this.props.status === 'READ' ? (
								'icon-read read'
							) : (
								''
							) : (
								''
							)
						}
					/>
				</div>
			);


		return (
			<div id={this.props.id} className="message__row">
				<span className={`messages message--${this.props.direction}`}>{componentFile}</span>
			</div>
		);
	}
}
export default FileDocs;
