import React from "react";
import { connect } from "react-redux";
import { showConversation } from "../../../appRedux/actions/Conversation";

import {
  getAssignedCompanies,
  showContainerReplies,
  showAdminReplies
} from "../../../appRedux/actions/Companies";
//showConversation

import "./containerQuickReplies.css";
import ItemCompany from "../ItemCompany/ItemCompany";
import ContainerBlank from "../ContainerBlank/ContainerBlank";
import ContainerDetailsReplies from "../ContainerDetailsReplies/ContainerDetailsReplies";
import {
  removeAllSocket,
  connectSocketsDashboard
} from "../../../appRedux/actions/Socket";

class ContainerQuickReplies extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showReply: false
    };
  }
  replieClick(e) {
    e.preventDefault();
  }
  handleInitConversation = (e, item) => {
    e.preventDefault();
    this.props.showAdminReplies(item);
  };

  componentWillMount() {
    const { authUser } = this.props;
    this.props.getAssignedCompanies(authUser.id);
    this.props.removeAllSocket();
    this.props.connectSocketsDashboard();
  }

  render() {
    const { companiesAssigned, containerAdminReplies } = this.props;
    let listCompanies = [];
    const ref = this;
    companiesAssigned.map(function(item, i) {
      listCompanies.push(
        <ItemCompany
          key={item.id}
          item={item}
          name={item.name}
          logo={item.logo_uri}
          handleInitConversation={e => {
            ref.handleInitConversation(e, item);
          }}
          replieClick={e => {
            ref.replieClick(e, item);
          }}
        />
      );
    });
    let containerRepliesw;
    if (this.props.showAdmin) {
      containerRepliesw = (
        <ContainerDetailsReplies containerInfo={containerAdminReplies} />
      );
    } else {
      containerRepliesw = <ContainerBlank />;
    }

    return (
      <div className="row  ml-10">
        <div className="col s12">
          <div className="admin-quick-replies__body">
            <div className="admin-quick-replies-navbar ">
              <p> Configuración de respuesta rápidas </p>{" "}
            </div>{" "}
            <div className="col s12 ">
              <div className="row ">
                <div className="col s12 m4 l3 pl-0">
                  <div className="admin-replies__container-companies">
                    {" "}
                   {" "}
                    <div className="admin-replies__title-companies">
                      <p> Mis clientes </p>{" "}
                    </div>{" "}
                    <div> {listCompanies} </div>{" "}
                  </div>{" "}
                </div>{" "}
                <div className="col s12 m8 l9 "> {containerRepliesw} </div>{" "}
              </div>{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
      </div>
    );
  }
}

const mapStateToProps = ({ auth, conversation, companies }) => {
  const { authUser } = auth;
  const { showContainer, containerInfo } = conversation;
  const {
    companiesAssigned,
    containerReplies,
    containerAdminReplies,
    showAdmin
  } = companies;
  return {
    authUser,
    companiesAssigned,
    showContainer,
    containerInfo,
    containerReplies,
    containerAdminReplies,
    showAdmin
  };
};

export default connect(
  mapStateToProps,
  {
    getAssignedCompanies,
    showContainerReplies,
    showConversation,
    removeAllSocket,
    connectSocketsDashboard,
    showAdminReplies
  }
)(ContainerQuickReplies);
