import axios from "../util/Api";
import { CLIENT_AGENT_WEB } from "../constants/Constants";

export function envioGlobalError(capturaErr, functionName) {
  const name = capturaErr.response.data.error.name;
  const message = capturaErr.response.data.error.message;
  const url_path = capturaErr.response.data.error.url_path;
  const parameters = capturaErr.response.data.error.parameters;
  const exception_code = capturaErr.response.data.error.exception_code;
  const user_id = capturaErr.response.data.error.user_id;
  let errEnv = {
    exception_code: exception_code,
    application: CLIENT_AGENT_WEB,
    user_id: user_id,
    exception_name: name,
    exception_msg: message,
    exception_stacktrace: functionName,
    url_path: url_path,
    parameters: parameters
  };
  let errEnvio = JSON.stringify(errEnv);

  return axios.post("exceptions", errEnvio).then(data => console.log(data));
}
