import React from 'react';
import { connect } from 'react-redux';

import './itemWatson.css';


class ItemWatson extends React.Component {
	constructor(props) {
		super(props);
		const { description } = this.props;
		this.state = {
			value: ''
		};
	}
	componentDidMount() {
		
	}

	handleChange(event) {
		
	}

	render() {
		const { label, description, type, handleChangeWatsonParameters, name } = this.props;
		return (
			<div className="row">
				<div className="col s5">
					<label className="admin-parameters__title-form" htmlFor="">
						{label}
					</label>
				</div>
				<div className="col s7">
					<div className="admin-parameters__container">
						<div className="admin-parameters_inputs">
							<input
								ref={(input) => {
									this.nameInput = input;
								}}
								required
								name={name}
								type={type}
								onChange={handleChangeWatsonParameters}
								value={description}
								className="input-default"
							/>
						</div>
						<span className="admin-parameters_asterisk">*</span>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = ({ auth, users, parameters }) => {
	const { authUser } = auth;

	return {
		authUser
	};
};

export default connect(mapStateToProps, {})(ItemWatson);
