import React from 'react';
import imgChnWeb from '../../../assets/images/ico-channel-web.png';
import imgChnWsp from '../../../assets/images/ico-channel-wsp.png';
import  './channelinfo.css'

class ChannelInfo extends React.Component {

	render() {
		const { channelname, channelid } = this.props;
		let logo = "";
		switch (channelid) {
			case '1':
				logo = imgChnWeb;
				break;
			case '2':
				logo = imgChnWsp;
					break;
			default:
				break;
		}

		return (
			<>
				<div className="channel-container">
					<p class="channel_title">Canal</p>
					<p class="channel_detail">{channelname}</p>
				</div>
				<div className="channel-logo">
					<img src={logo} alt="" className="responsive-img" />
				</div>
			</>
		);
	}
}

export default ChannelInfo;

