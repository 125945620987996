import React from 'react';
import Moment from 'react-moment';
import moment from 'moment/min/moment-with-locales';

const getFormat = (d) => {
	const now = moment();
	const expiration = moment(d);
	const diff = expiration.diff(now);
	const diffDuration = moment.duration(diff);
	var rhours = Math.abs(diffDuration.hours());
	var rminutes = Math.abs(diffDuration.minutes());
	var rseconds = Math.abs(diffDuration.seconds());

	var result = '';
	result = rhours === 0 ? '' : rhours + ' h ';
	result += rminutes === 0 ? '' : rminutes + ' m ';

	result += rhours > 0 ? '' : rseconds + ' s ';
	if (result === '') result = '-';

	return result;

};

const CustomMoment = ({ interval, date }) => {
	return <Moment filter={getFormat} interval={interval} date={date} />;
};
export default CustomMoment;

CustomMoment.defaultProps = {

};
