import React from 'react';
import  './timerchat.css';
import Moment from "react-moment";
import moment from "moment/min/moment-with-locales";

class TimerChat extends React.Component {

	render() {
		const { timerinit } = this.props;
        let horaInicio = timerinit;
        
        const getFormatInit = d => {
            const expiration = moment(d);      
            let hours = expiration.hours();
            if (hours == 24) hours = "00";
            let minutes = expiration.minutes();
            if (minutes <= 9) {
            minutes = "0" + minutes;
            }
            return hours + ":" + minutes;
        };

        let resultSeconds;
        let result;
        const getFormatSecond = d => {
            var moment = require("moment");
            const now = moment();
        
            const expiration = moment(d);
        
            const diff = expiration.diff(now);
            const diffDuration = moment.duration(diff);
        
            var rhours = Math.abs(diffDuration.hours());
            var rminutes = Math.abs(diffDuration.minutes());
            var rseconds = Math.abs(diffDuration.seconds());
        
            if (rhours <= 9) {
                rhours = "0" + rhours;
            } else if (rminutes <= 9) {
                rminutes = "0" + rminutes;
            } else if (rseconds <= 9) {
                rseconds = "0" + rseconds;
            }
            result = rhours + "h ";
            result += rminutes + "m ";
            result += rseconds + "s";
            if (result === "") result = "-";
            resultSeconds = rminutes * 60 + rseconds;
            return result;
        };

        let tiempoAtencion = (
            <Moment
              filter={getFormatSecond}
              interval={1000}
              date={horaInicio}
            />
          );

		return (
			<>
				<div className="timercount-chat-container">
					<p class="timercount-chat_title">Inicio Conversación</p>
					<p class="timercount-chat_detail">
                        <Moment
                            filter={getFormatInit}
                            interval={1000}
                            date={horaInicio}
                        />
                    </p>
				</div>
				<div className="timercount-chat-container">
					<p class="timercount-chat_title">Tiempo Conversación</p>
					<p class="timercount-chat_detail">{tiempoAtencion}</p>
				</div>
			</>
		);
	}
}

export default TimerChat;

