import React from 'react';
import { connect } from 'react-redux';
import { message } from 'antd';
import {
	updateHistorialRead,
	addMessage,
	startTypingIndicator,
	stopTypingIndicator,
	updateMessage
} from '../../../appRedux/actions/Chat';
import emoji from '../../../assets/images/emoji.png';
import send from '../../../assets/images/send.png';
import clip from '../../../assets/images/clip.png';

import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';
import { setInput, showEmoji, uploadFile } from '../../../appRedux/actions/Chat';
import * as uuidv1 from 'uuid/v1';
import './inputfield.css';

class InputField extends React.Component {
	constructor(props) {
		super(props);
		this.M = window.M;
		this.state = { message_text: '', fileName: '' };
		this.handleClickOutside = this.handleClickOutside.bind(this);
		this.handleEmojin = this.handleEmojin.bind(this);
		this.handleFileSelect = this.handleFileSelect.bind(this);
		this.inputField = React.createRef();
	}

	componentDidUpdate() {
		document.getElementById('body-message').scrollTop = document.getElementById('body-message').scrollHeight;
		}

	handleEmojin(e, isShowEmoji) {
		e.preventDefault();
		this.props.showEmoji(isShowEmoji);
		let items = this.div.childNodes;
		if (isShowEmoji) {
			items[0].classList.add('hide');
			items[1].classList.remove('hide');
		} else {
			items[1].classList.add('hide');
			items[0].classList.remove('hide');
		}
	}

	handleClickOutside(e) {
		e.preventDefault();
		if (this.emojiPicker && !this.emojiPicker.contains(e.target)) {
			this.props.showEmoji(false);
		}
	}

	//handleAddMessage(e, conversation_id, agent_id, user_name, channel_id, channel_name, bot_number, user, company_id) {
	handleAddMessage(e, parameterEvento) {
		const { conversation_id, agent_id, user_name, channel_id, channel_name, bot_number, user, company_id, msteamsActivityContext } = parameterEvento;
		const { containerInfo } = this.props;		
		const { uploadFile } = this.props;
		e.preventDefault();


		let message_text = e.target[1].value;
		let message_files = e.target[0].files;

		if (message_text === '' && message_files.length === 0) return true;

		const { setInput } = this.props;
		setInput('');

		if (message_files.length > 0) {
			for (let i = 0; i < message_files.length; i++) {
				let parameters = {
					conversation_id: conversation_id,
					user_id: agent_id,
					user_type: 'ANALYST',
					status: 'SENT',
					user_name: user_name,
					message_type: '',
					message_text: '',
					file_id: '',
					file_name: '',
					channel_id: channel_id,
					from: bot_number,
					to: user.user_phone,
					company_id: company_id,
					msteamsActivityContext: msteamsActivityContext
				};

				if (i + 1 === message_files.length) {
					parameters.message_text = e.target[1].value;
				}

				let uid = uuidv1();
				var file = message_files[i];

				let file_name = file.name;
				let extension = file_name.split('.');
				let file_name_cloud = uid + '.' + extension.pop();
				parameters.message_type = 'file';
				parameters.file_id = uid;
				parameters.file_name = file_name;
				parameters.file_url = '';
				if (message_files && file) {
					var reader = new FileReader();
					reader.onload = function(readerEvt) {
						let binaryString = readerEvt.target.result;
						let imgConvert = btoa(binaryString);

						uploadFile(imgConvert, file_name, parameters,containerInfo.company_id);
					};
					reader.readAsBinaryString(file);
					//
				}
			}
		}

		if (message_files.length === 0) {
			let parameters = {
				conversation_id: conversation_id,
				user_id: agent_id,
				user_type: 'ANALYST',
				status: 'SENT',
				user_name: user_name,
				message_text: e.target[1].value,
				message_type: '',
				file_id: '',
				file_name: '',
				channel_id: channel_id,
				channel_name: channel_name,
				msteamsActivityContext: msteamsActivityContext,
				from: bot_number,
				to: user.user_phone,
				company_id: company_id,
			};
			parameters.message_type = 'text';
			this.props.addMessage(parameters);
			this.inputField.value = '';
		}

		this.props.updateMessage(conversation_id);
		while (document.getElementById('container-file').firstChild) {
			document.getElementById('container-file').removeChild(document.getElementById('container-file').firstChild);
		}
		this.inputField.value = '';
		this.inputFile.value = '';
	}
	handleTypingIndicator(e) {

		const { startTypingIndicator, stopTypingIndicator, containerInfo } = this.props;
		let parameter = { conversation_id: containerInfo.conversation_id };
		startTypingIndicator(parameter);
		if (e.key === 'Enter') {
			stopTypingIndicator(parameter);
		} else if (e.key === 'Backspace') {
			if (this.inputField.value.trim().length === 1) {
				stopTypingIndicator(parameter);
			}
		}

		return true;
	}

	apiHistorialRead(e) {
		const { containerInfo } = this.props;
		this.props.updateHistorialRead(containerInfo.conversation_id);
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.message_text !== this.props.message_text) {
			this.setInputValue(nextProps.message_text);
		}
		if (nextProps.dataFile !== this.props.dataFile) {
		}
		this.inputField.focus();
	}

	setInputValue(message_text) {
		const { containerInfo, authUser } = this.props;
		let replaceText = message_text.replace('«agent»', authUser.name);
		replaceText = message_text.replace('«user»', containerInfo.user.user_first_name);
		this.inputField.value = replaceText;
	}

	componentDidMount() {
		this.inputField.focus();
		document.addEventListener('mouseup', this.handleClickOutside);
		document.addEventListener('visibilitychange', this.visibilitychange);
	}

	componentWillUnmount() {
		document.removeEventListener('mouseup', this.handleClickOutside);
		document.addEventListener('visibilitychange', this.visibilitychange);
	}

	visibilitychange = () => {
		this.apiHistorialRead();
	};

	addEmoji = (e) => {
		let emoji = e.native;
		this.inputField.value += emoji;
		this.inputField.focus();
	};


	handleFileSelect(evt) {
		var files = evt.target.files;
		var file = files[0];
		this.handleEachFileSelect(files);
	}

	handleEachFileSelect(files) {
	if (files.length >= 5) {
			var instance = this.M.Modal.getInstance(document.getElementById('modal-file'));
			instance.open();
		} else {
			for (let i = 0; i < files.length; i++) {
				var file = files[i];
				if (files && file) {
					var reader = new FileReader();
					reader.onload = function(readerEvt) {
						let binaryString = readerEvt.target.result;
						let imgConvert = btoa(binaryString);
					};
					reader.readAsBinaryString(file);
				}

				let imageType = /^image\//;
				if (imageType.test(file.type)) {
					this.typeFileImage(file, i);
				} else {
					this.typeFileDoc(file, i);
				}

				if (i + 1 === files.length) {
					this.createDeteleFileButton(imageType, file, i);
				}
			}
		}

		document.querySelectorAll('.file__delete-button').forEach(function(elem) {
			elem.addEventListener('click', function(e) {
				var i = this.getAttribute('orden');
				document.getElementById('file').value = '';
				document.getElementById('container-file').innerHTML = '';
			});
		});
	}

	createDeteleFileButton(imageType, file, i) {
		var deleteFileButton = document.createElement('div');
		deleteFileButton.setAttribute('id', 'file__delete-button' + i);
		deleteFileButton.setAttribute('orden', i);
		deleteFileButton.classList.add('file__delete-button');
		deleteFileButton.appendChild(document.createTextNode('x'));
			document.getElementById('container-file').appendChild(deleteFileButton);
		if (imageType.test(file.type)) {
			deleteFileButton.classList.add('file__delete-button-img');
		} else {
			deleteFileButton.classList.add('file__delete-button-file');
		}
	}

	typeFileImage(file, i) {
		var reader = new FileReader();
		document.getElementById('container-file').innerHTML +=
			'<div id="img-content-file' +
			i +
			'"><img id="img-file' +
			i +
			'" class="chatbot-conversation__file-image" src="" alt=""></div>';
		// visualización de la imagen
		reader.onloadend = function() {
			document.getElementById('img-file' + i).src = reader.result;
		};
		if (file) {
			reader.readAsDataURL(file);

		} else {
			document.getElementById('img-file' + i).src = '';
		}
	}

	typeFileDoc(file, i) {
		document.getElementById('container-file').innerHTML +=
			'<div class="content-file" id="content-extension' +
			i +
			'"><div id="extension' +
			i +
			'" class="thumb-ext">' +
			file.name.split('.').pop() +
			'</div><div class="file-info">' +
			file.name +
			'</div></div>';
		let divThumb = document.getElementById('extension' + i);
		let extension = file.name.split('.').pop();

		// validacíon para el tipo de doc:.txt,word,
		switch (extension) {
			case 'pdf': {
				divThumb.classList.add('thumb-ext-pdf');
				break;
			}
			case 'docx': {
				divThumb.classList.add('thumb-ext-word');
				break;
			}
			case 'xlsx': {
				divThumb.classList.add('thumb-ext-excel');
				break;
			}
			default:
				divThumb.classList.add('thumb-ext-other');
		}
	}

	render() {
		const { containerInfo, authUser, isShowEmoji } = this.props;
		const { conversation_id, agent_id, channel_id, channel_name, bot_number, user, company_id, msteamsActivityContext } = containerInfo;
		const { name } = authUser;
		const props = {
			name: 'file',
			multiple: true,
			action: '//jsonplaceholder.typicode.com/posts/',
			onChange(info) {
				const status = info.file.status;
				if (status !== 'uploading') {
				}
				if (status === 'done') {
					message.success(`${info.file.name} file uploaded successfully.`);
				} else if (status === 'error') {
					message.error(`${info.file.name} file upload failed.`);
				}
			}
		};
		let file = null;
		let parameterEvento = {
			conversation_id: conversation_id,
			agent_id: agent_id,
			user_name: name,
			channel_id : channel_id,
			channel_name : channel_name,
			bot_number : bot_number,
			user : user,
			company_id : company_id,
			msteamsActivityContext: msteamsActivityContext
		};
		
		return (
			<div>
				<form
					onSubmit={(event) => {
						//this.handleAddMessage(event, conversation_id, agent_id, name, channel_id, channel_name, bot_number, user, company_id);
						this.handleAddMessage(event, parameterEvento);
					}}
				>
					<div className="prueba-container-file">
						<div className="container-file" id="container-file" />
						<div className="chat-input">
							<div
								ref={(e) => {
									this.div = e;
								}}
							>
								<img
									src={emoji}
									alt="emoji"
									className="responsive-img"
									onClick={(e) => {
										this.handleEmojin(e, true);
									}}
								/>

								<img
									src={emoji}
									alt="emoji"
									className="responsive-img hide"
									onClick={(e) => {
										this.handleEmojin(e, false);
									}}
								/>
							</div>
							<label htmlFor="file">{file}</label>
							<input
								type="file"
								id="file"
								name="selectedFile"
								multiple="multiple"
								ref={(e) => {
									this.inputFile = e;
								}}
								onChange={(e) => {
									this.handleFileSelect(e);
								}}
								className="input-default"
							/>
							<label className="chatbot-conversation__clip" htmlFor="file">
								<img className="responsive-img" src={clip} alt="icon-clip" />
							</label>
							<input
								id="input-hist"
								autoComplete="off"
								maxLength="1000"
								onFocus={(e) => {
									this.apiHistorialRead(e);
								}}
								onKeyDown={(e) => {
									this.handleTypingIndicator(e);
								}}
								ref={(e) => {
									this.inputField = e;
								}}
								id="input"
								placeholder="Escribe un mensaje"
								type="text"
								name="message"
								className="input-default"
							/>
							<button className="send" type="submit">
								<img src={send} alt="emoji" className="responsive-img " />
							</button>
						</div>
					</div>
				</form>
				{isShowEmoji && (
					<span className="emoji-box" style={styles.emojiPicker} ref={(el) => (this.emojiPicker = el)}>
						<Picker onSelect={this.addEmoji} native={true} emojiSize={28} perLine={7} />
					</span>
				)}
				<div>
					<div id="modal-file" className="modal modal-ticket ">
						<a
							href="#!"
							id="modal-register-close"
							className="modal-close position waves-effect waves-green btn-flat"
						>
							x
						</a>
						<div className="modal-content container-modal ">Sólo puedes enviar 4 archivos a la vez</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = ({ conversation, auth, chat }) => {
	const { containerInfo } = conversation;
	const { authUser } = auth;
	const { message_text, isShowEmoji, dataFile } = chat;
	return { containerInfo, authUser, message_text, isShowEmoji, dataFile };
};

export default connect(mapStateToProps, {
	updateMessage,
	uploadFile,
	addMessage,
	startTypingIndicator,
	stopTypingIndicator,
	setInput,
	showEmoji,
	updateHistorialRead
})(InputField);

const styles = {
	emojiPicker: {

		cssFloat: 'left',
		marginLeft: '20px'
	}
};
