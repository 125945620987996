import React from 'react';
import { connect } from 'react-redux';

import './configurationTickets.css';
import { getAllCompanies, getParametersTickets, putParametersTickets } from '../../../appRedux/actions/Parameters';

import ContainerDefaultPlatform from '../ContainerDefaultPlatform/ContainerDefaultPlatform';
import ItemStateTickets from '../ItemStateTickets/ItemStateTickets';
import Modal from '../Modal/Modal';
class ConfigurationTickets extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			listCompanies: [],
			showResults: false,
			listParametersWatson: [],
			showButtonSave: false,
			showModal: false,
			firstStateTicket: [],
			secondStateTicket: [],
			thirdStateTicket: [],
			parametersTickets: [],
			valueCompanyId: ''
		};
	}

	async componentWillMount() {
		await this.props.getAllCompanies();
		const { listAllCompanies } = this.props;
		let initialCompanies = [];
		initialCompanies = listAllCompanies.map((company) => {
			return company;
		});

		this.setState({
			listCompanies: initialCompanies
		});
	}
	//change company
	async handleChangeTickets(event) {
		let valueCompany = event.target.value;

		this.setState({
			showButtonSave: true,
			showResults: true,
			parametersTickets: [],
			firstStateTicket: [],
			secondStateTicket: [],
			thirdStateTicket: []
		});
		await this.props.getParametersTickets(valueCompany);
		const { parametersTickets } = this.props;
		this.setState({
			parametersTickets: parametersTickets,
			valueCompanyId: valueCompany
		});
		let filterFirstState = this.state.parametersTickets.filter((item) => item.param_value === '1');
		let filterSecondState = this.state.parametersTickets.filter((item) => item.param_value === '2');
		let filterThirdState = this.state.parametersTickets.filter((item) => item.param_value === '3');
		this.setState({
			firstStateTicket: filterFirstState,
			secondStateTicket: filterSecondState,
			thirdStateTicket: filterThirdState
		});
	}
	//////change input

	handleCloseModal() {
		this.setState({
			showModal: false
		});
	}

	handleSaveParemetersTickets(event) {
		event.preventDefault();
		this.setState({
			showModal: true
		});
		let stateFirst = this.state.firstStateTicket;
		let stateSecond = this.state.secondStateTicket;
		let stateThird = this.state.thirdStateTicket;
		let allParameters = [].concat(stateFirst, stateSecond, stateThird);
		let company_id = this.state.valueCompanyId;
		let dataTicket = [];
		allParameters.map(function(item) {
			let itemTicket = {
				param_id: item.param_id,
				label: item.param_label,
				value: item.param_value,
				platform_id: item.platform_id
			};

			dataTicket.push(itemTicket);
		});
		this.props.putParametersTickets(company_id, dataTicket);
	}
	handleadd(e, state) {
		let valuesFirstState = [ ...this.state.firstStateTicket ];
		let valuesSecondState = [ ...this.state.secondStateTicket ];
		let valuesThirdState = [ ...this.state.thirdStateTicket ];
		
		// necessary param_id, label, value and platform_id
		if (state === 1) {
			let platform_id, param_id;
			let param_names;
			if (this.state.firstStateTicket.length > 0) {
				platform_id = this.state.firstStateTicket[0].platform_id;
				param_id = this.state.firstStateTicket[0].param_id;
				param_names = this.state.firstStateTicket[0].param_name;
			} else {
				platform_id = this.state.parametersTickets[0].platform_id;
				param_id = this.state.parametersTickets[0].param_id;
				param_names = this.state.parametersTickets[0].param_name;
			}
			valuesFirstState.push({
				id: '',
				company_id: '',
				company_name: '',
				platform_id: platform_id,
				platform_name: '',
				param_id: param_id,
				param_name: param_names,
				param_description: '',
				param_label: '' /**valor del input */,
				param_value: '1',
				required_for_platform: '',
				company_platform_id: '',
				param_group_id: '',
				param_group_name: ''
			});
			this.setState({
				firstStateTicket: valuesFirstState
			});

	} else if (state === 2) {
			let platform_id, param_id;
			let param_names;
			if (this.state.secondStateTicket.length > 0) {
				platform_id = this.state.secondStateTicket[0].platform_id;
				param_id = this.state.secondStateTicket[0].param_id;
				param_names = this.state.secondStateTicket[0].param_name;
			} else {
				platform_id = this.state.parametersTickets[0].platform_id;
				param_id = this.state.parametersTickets[0].param_id;
				param_names = this.state.parametersTickets[0].param_name;
			}
			valuesSecondState.push({
				id: '',
				company_id: '',
				company_name: '',
				platform_id: platform_id,
				platform_name: '',
				param_id: param_id,
				param_name: param_names,
				param_description: '',
				param_label: '' /**valor del input */,
				param_value: '2',
				required_for_platform: '',
				company_platform_id: '',
				param_group_id: '',
				param_group_name: ''
			});
			this.setState({
				secondStateTicket: valuesSecondState
			});
	} else if (state === 3) {
			let platform_id, param_id;
			let param_names;
			if (this.state.thirdStateTicket.length > 0) {
				platform_id = this.state.thirdStateTicket[0].platform_id;
				param_id = this.state.thirdStateTicket[0].param_id;
				param_names = this.state.thirdStateTicket[0].param_name;
			} else {
				platform_id = this.state.parametersTickets[0].platform_id;
				param_id = this.state.parametersTickets[0].param_id;
				param_names = this.state.parametersTickets[0].param_name;
			}
			valuesThirdState.push({
				id: '',
				company_id: '',
				company_name: '',
				platform_id: platform_id,
				platform_name: '',
				param_id: param_id,
				param_name: param_names,
				param_description: '',
				param_label: '' /**valor del input */,
				param_value: '3',
				required_for_platform: '',
				company_platform_id: '',
				param_group_id: '',
				param_group_name: ''
			});
			this.setState({
				thirdStateTicket: valuesThirdState
			});
		}
	}
	handleChildDelete(dataFromChild, type) {}
	connectChildChange(params, type) {
		if (type === 1) {
			this.setState(
				{
					firstStateTicket: params
				},
				() => console.log('Updated State ticket-1:', this.state)
			);
		} else if (type === 2) {
			this.setState(
				{
					secondStateTicket: params
				},
				() => console.log('Updated State ticket-2:', this.state)
			);
		} else if (type === 3) {
			this.setState(
				{
					thirdStateTicket: params
				},
				() => console.log('Updated State ticket-3:', this.state)
			);
		}
	}

	render() {
		
		const { loadingSave, saveSuccessuful, saveError } = this.props;

		let optionsCompanies = [];
		let optionsWatson = [];
		if (this.state.listCompanies.length > 0) {
			this.state.listCompanies.map(function(itemCompany) {
				optionsCompanies.push(
					<option key={itemCompany.id} value={itemCompany.company_uuid} defaultValue={itemCompany.name}>
						{itemCompany.name}
					</option>
				);
			});
		}
		let ref = this;
		let ticketPrueba;

		optionsWatson.push(
			<div className="row">
				<div className="col s12">
					<p className="mt-0  text-blue-low ">Configuración de equivalentes de estado ticket</p>
					<ItemStateTickets
						label="Estado 1: Nuevo"
						itemTicket={this.state.firstStateTicket}
						handleadd={(event) => {
							ref.handleadd(event, 1);
						}}
		
						connectChildChange={(paramState, type) => {
							ref.connectChildChange(paramState, type);
						}}
					/>
					<ItemStateTickets
						label="Estado 2: En proceso"
						itemTicket={this.state.secondStateTicket}
						handleadd={(event) => {
							ref.handleadd(event, 2);
						}}
						connectChildChange={(paramState, type) => {
							ref.connectChildChange(paramState, type);
						}}
					/>
					<ItemStateTickets
						label="Estado 3: Resuelto"
						itemTicket={this.state.thirdStateTicket}
						handleadd={(event) => {
							ref.handleadd(event, 3);
						}}
						connectChildChange={(paramState, type) => {
							ref.connectChildChange(paramState, type);
						}}
					/>
				</div>
			</div>
		);

		return (
			<form
				onSubmit={(e) => {
					this.handleSaveParemetersTickets(e);
				}}
			>
				<div className="admin-parameters_container-session ">
					<div className="admin-parameters__session-header">
						<p className="admin-parameters__session-title">Configuración de estado de ticket</p>
						{this.state.showButtonSave && (
							<div>
								<input
									className="btn waves-effect admin-parameters__btn-save-input position-btn"
									type="submit"
									value="Guardar"
								/>
							</div>
						)}
						<Modal
							showModal={this.state.showModal}
							loadingSave={loadingSave}
							saveSuccessuful={saveSuccessuful}
							saveError={saveError}
							handleCloseModal={(event) => {
								ref.handleCloseModal(event);
							}}
						/>
					</div>
					<div className="admin-parameters__background">
						<div className=" admin-parameters__container-select">
							<select
								className="browser-default admin-parameters__client-default"
								onChange={(event) => this.handleChangeTickets(event)}
							>
								<option className="" datacompany="" value="" disabled selected>
									Seleccione una empresa
								</option>
								{optionsCompanies}
							</select>
						</div>
					</div>
					<div className="admin-parameters__container-thresholds">
						{this.state.showResults ? optionsWatson : <ContainerDefaultPlatform />}
					</div>
				</div>
			</form>
		);
	}
}

const mapStateToProps = ({ auth, parameters }) => {
	const { authUser } = auth;
	const { listAllCompanies, parametersTickets, loadingSave, saveSuccessuful, saveError } = parameters;

	return {
		authUser,
		listAllCompanies,
		parametersTickets,
		loadingSave,
		saveSuccessuful,
		saveError
		//listThresholdCompany
	};
};

export default connect(mapStateToProps, { getAllCompanies, getParametersTickets, putParametersTickets })(
	ConfigurationTickets
);
