
import React from 'react';
import { connect } from 'react-redux';
import './itemParamApiPSS.css';

class ItemParamApiPSS extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			id: '',
			param_label: '',
			param_value: '',
			endpointId: ''
		};
	}

	componentWillReceiveProps( newProps ) {
		this.setState({
			id: newProps.value.id,
			param_label: newProps.value.param_label,
			param_value: newProps.value.param_value,
			endpointId: newProps.value.endpointId
		});
		
	}

	componentWillMount() {
		const { value } = this.props;
		this.setState({
			id: value.id,
			param_label: value.param_label,
			param_value: value.param_value,
			endpointId: value.endpointId
		});
		
	}

	render() {
		const { onClick,handleChange  } = this.props;
		
		return (
				<div class="row">
					
					<div class="col s5">
						<div class="admin-parameters_ticket w-100">
							<input name={'param_label'} id={this.state.endpointId+ 'param_label'+this.state.id} 
							value={this.state.param_label} type="text" onChange={handleChange}/>
						</div>
					</div>
					<div class="col s1">
						:
					</div>
					<div class="col s5">
					    <div class="admin-parameters_ticket w-100">
							<input name={'param_value'} id={this.state.endpointId+ 'param_label'+this.state.id} 
							value={this.state.param_value} type="text" onChange={handleChange}/>
						</div>
					</div>
					<div class="col s1">
						<button type="button" onClick={onClick} class="btn-hide">
							<img class="responsive-img" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAABQZJREFUaAXlmj1sFEcUx9+sufNBsCPFsUJoANFQRE7K1HYVKZZCmS4QAlL4EAKbMooog7EQkEhYBNKlDJIjRYlE6pQQpXATAULiQwdIxpY5fMlO/v+5mfN6b3dv9m59PisjWbcfb978/rOzM2/eWmSLF1UUv9ZaHZmVD0TLnjCUd+F3t/1jE4/4FwTyOAjl/ty03FVKad7otnQl4ORlPbhUlwkJ5ROATAJ+lxeQkiewm5dAbg2V5PaVU+q1V70Eo44EHP5eD4UvZBp9eBrdOJTg1/sSAJa0kkvBW3LhxudqybuiNcwl4Og1Xaq/lGPo6a8APpq3sSx7gFRFyfnSsFybO6bqWbbRe94Cjs7oA6tafgL8gaiDwo+VLJSVHJybUgs+vgMfo8Mz+qN6KH9sODxh0EFsi236sLUV8NkFfUaH8jOGzJs+DouwYVtsk22385cpAL1wFj1yEQ4z7do10sl90ybaNgwZDlLfAT5C2/M9h4/yAjBUgXx8Y0r9Er3ujhMF8IXlOOzlsHFASb+AXCwF8mHSi93Su5wqOdv0CzwFkYVMZIsLbBFg5/mNnSrjFD7nnJ24BsXKuiHEFVY/l7+huNBFKtZmx6eAraoR2R9dsdc9AYYH/QpP1WQjY7QHmk+AgdlyTaow6iq2iTrfiGMAL+2syKgLAJtPgFFlp/B0CthaDuCarZOjSsOUjCYCtjWbAmxInNshQSo7Zc8OkX0Ixv5s6wA228qyl3U6FRFlNQK4GUHDk20bTzBAj5Tqq1L+7px6giBsIlME4Glz/bR6qpZlkHUTXPpcmrTMjRDB7qT8NiOt7iv/1OU3LH5vY6F5lirCwtOGtitKfoWrSqs7jyvYOBlmmDaGELaBHtXSTbSMYaG5nSoiBk9bTClj6Q497lhmI+Dfxv7Vo1aGSZqIjYAHhmM2AlRjE55B53krQQSHlBs2hfS8RXHM2+w5MwjFFIoQM5wMOJ1yaBUJb0ENc+MdKAZ9U7w4AczbFFMiY/74VT1y6LIe5RB6Y7uMKyV3i2nEeDHMxQqIwa+8wmzzWn6niG9PqOc7tstEgSLWBDBj1nXPJMBrLe/j7724CCx2d7ptzzE3noCSB105TIF3PuMiBgbMit2VCKYo6Z8hhHBZPjSD/KVvapCV1kqNsQ3DA455Dhv2/NrttSMMn79kUMZvnlJVDiuEf/cRTiCMylmQmrw5JbuZXzVPwCZa53O6MebogXqpLKtHLul3suBp7J7El9/oXaVBWUUEhhm3ozLvksNuHWBQcQvL2xd53aEHh2rL8sAGZm1jG4pYEbknLwGvZThve8aerLa4WUiYJUZv5k6u0g9F4KctvG2TP5VO4clIVuerKYA7HGaJ3Y1+/SWj242RsSnAnCDFDYXVfoUnG9PwUb51AsxuHynuqEFfHYMtmpEg2zoBvMD8PCZXr9Q27XtWwGTYYg22CODHBYTAB/G4FmO2m3ZKFjIlffhoEUBKBF8LSKh+iorhplHbhslAFjIlsSQKoCGzwah4LqlSL69h1plOy0yTI1UAb6LiRQRNU5vxJNgm2/5hWs2SJa3Arn2x3wp+xILVk680gFrksMnqeUftJYDG2BZu3Y98FMCXqDwsY4goT0J14YsdfdI320h7YckRL95PIFpxy37ojorg8Zb9V4O4EJ5/rXXwcBYbGWTMmHSyeRuXrnmEncfjAf7TB3Z/18/IHRfPJ/n6X137Dz1UD9lMLj3TAAAAAElFTkSuQmCC" alt="img-close-error"/>
						</button>
					</div>
				</div>
		)
	}
}

const mapStateToProps = ({ auth}) => {
	const { authUser } = auth;
	return {
		authUser
	};
};

export default connect(mapStateToProps, {
})(ItemParamApiPSS);
