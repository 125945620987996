import React from 'react';
import { connect } from 'react-redux';
import circleError from '../../../../assets/images/circle-error.png';

import './itemApi.css';

class ItemApi extends React.Component {
	constructor(props) {
		super(props);
		const { param_label, param_value } = this.props;
		this.state = {
			param_label: '',
			param_value: ''
		};
	}

	componentWillReceiveProps(newProps) {
		this.setState({
			id: newProps.value.id,
			company_id: newProps.value.company_id,
			company_name: newProps.value.company_name,
			platform_id: newProps.value.platform_id,
			platform_name: newProps.value.platform_name,
			param_id: newProps.value.param_id,
			param_name: newProps.value.param_name,
			param_description: newProps.value.param_description,
			param_label: newProps.value.param_label,
			param_value: newProps.value.param_value,
			required_for_platform: newProps.value.required_for_platform,
			company_platform_id: newProps.value.company_platform_id,
			param_group_id: newProps.value.param_group_id,
			param_group_name: newProps.value.param_group_name
		});
	}

	componentWillMount() {
		const { value } = this.props;
		this.setState({
			id: value.id,
			company_id: value.company_id,
			company_name: value.company_name,
			platform_id: value.platform_id,
			platform_name: value.platform_name,
			param_id: value.param_id,
			param_name: value.param_name,
			param_description: value.param_description,
			param_label: value.param_label,
			param_value: value.param_value,
			required_for_platform: value.required_for_platform,
			company_platform_id: value.company_platform_id,
			param_group_id: value.param_group_id,
			param_group_name: value.param_group_name
		});
	}
	render() {
		const { handleChangeconnectChild, value } = this.props;
		let ref = this;
		return (
			<div className="container_item-api">
				<input
					ref={this.props.refInputLabel}
					className="api"
					value={this.state.param_label ? this.state.param_label : ''}
					name={'param_label'}
					onChange={handleChangeconnectChild}
				/>:
				<input
					className="api"
					value={this.state.param_value ? this.state.param_value : ''}
					name={'param_value'}
					onChange={handleChangeconnectChild}
					ref={this.props.refInputValue}
				/>
				<img
					className=""
					src={circleError}
					alt="img-close-error"
					className="responsive-img"
					onClick={(event) => this.props.handleDelete(event)}
				/>
			</div>
		);
	}
}

const mapStateToProps = ({}) => {};

export default connect(mapStateToProps, {})(ItemApi);
