import React from 'react';
import { connect } from 'react-redux';

import './itemParametersApi.css';
import { getAllCompanies, getParametersApi } from '../../../../appRedux/actions/Parameters';
import ItemApi from '../ItemApi/ItemApi';
import Modal from '../../Modal/Modal';

class ItemParametersApi extends React.Component {
	constructor(props) {
		super(props);
		const { valores } = this.props;
		this.inputLabel = React.createRef();
		this.inputValueParam = React.createRef();
		this.state = {
			valores: [],
			param_label: '',
			label_sub: '',
			value_sub: '',
			otherStates1: '',
			otherStates2: '',
			param_label: '',
			param_value: '',
			label: '',
			platformId: '',
			showError: false,
			paramId: ''
		};

		this.title = React.createRef();
	}
	componentWillMount() {
		const { valores, label, platformId, paramId } = this.props;
		this.setState({
			valores: valores,
			label: label,
			platformId: platformId,
			paramId: paramId
		});
	}

	componentWillReceiveProps(newProps) {
		this.setState({
			valores: newProps.valores,
			label: newProps.label,
			platformId: newProps.platformId,
			paramId: newProps.paramId
		});
	}
	async handleChangeconnectChild(index, label, els) {

		let params = [ ...this.state.valores ];
		params.forEach(function(j, el) {
			let newElement = parseInt(j, 10);

			if (el === index) {
				params[el][els.target.name] = els.target.value;
			} else {
			}
		});
		/*}*/
		/*	await this.setState({
			valores: params
		});*/
		this.props.handleChangeParent(params, this.state.label);

	}
	createUI() {
		const { label } = this.props;
		return this.state.valores.map((item, index) => (
			<div key={index}>
				<ItemApi
					key={index}
					value={item}
					handleChangeconnectChild={this.handleChangeconnectChild.bind(this, index, label)}
					handleDelete={(event) => {
						this.handleDelete(event, index);
					}}
					refInputLabel={this.inputLabel}
					refInputValue={this.inputValueParam}
				/>
				{this.state.showError && <span>Al menos debe quedar uno</span>}
			</div>
		));
	}
	async addClick(label) {
		let valores = [ ...this.state.valores ];
		let labelPrueba = this.state.label;
		let paramIds;
		let platformIds;
		let ref = this;
		valores.map(function(id) {
			paramIds = id.param_id;
			platformIds = id.platform_id;
		});
		const { addSection, paramId } = this.props;
	
		valores.push({
			id: '',
			company_id: '',
			company_name: '',
			platform_id: /*ref.state.platformId*/ platformIds,
			platform_name: '',
			param_id: paramIds,
			param_name: '',
			param_description: '',
			param_label: '',
			param_value: '',
			required_for_platform: '',
			company_platform_id: '',
			param_group_id: '',
			param_group_name: ''
		});
		/*	await this.setState({
			valores: valores,
			labelPrueba: labelPrueba
		});
*/
		addSection(valores, labelPrueba);

		}

	handleDelete(event, index) {
		let valuesNew = [ ...this.state.valores ];
		if (valuesNew.length > 1) {
			let valores = valuesNew.filter((x, item) => item !== index);

			const { deleteParent } = this.props;
			deleteParent(valores, this.state.label);
		} else {
			//let valoresUnique = valuesNew.filter((x, item) => item !== index);
			let ref = this;
		
			if (valuesNew[0].param_name === ref.state.label) {
				valuesNew[0].param_label = '';
				valuesNew[0].param_value = '';
				this.props.deleteParent(valuesNew, ref.state.label);
			}

			const { deleteParent } = this.props;
		}

	}
	//createUI

	// connect handlechange

	render() {
		const { label, handleAddValue, paramId } = this.props;
		const { addSection } = this.props;
		return (
			<div className="container-parameters-api">
				<div className="container-item_options">
					<div className="parameters-api_label item_api">{this.state.label}</div>
					<div className="">{this.createUI()}</div>
				</div>
				<div className="container_ticket_add">
					<p className="ticket_add_state mr-ticket" onClick={this.addClick.bind(this, this.state.label)}>
						+ Agregar campo
					</p>
				</div>
			</div>
		);
	}
}

const mapStateToProps = ({ auth, parameters }) => {
	const { authUser } = auth;
	const { listAllCompanies, parametersApi, loadingSave, saveSuccessuful, saveError } = parameters;

	return {
		authUser,
		listAllCompanies,
		parametersApi,
		loadingSave,
		saveSuccessuful,
		saveError
		//listThresholdCompany
	};
};

export default connect(mapStateToProps, { getAllCompanies, getParametersApi })(ItemParametersApi);
