/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
import React from "react";
import { connect } from "react-redux";

import CirclePerson from "../CirclePerson/CirclePerson";
import NamePerson from "../NamePerson/NamePerson";
import InfoPerson from "../InfoPerson/InfoPerson";
import MessageContainer from "../MessageContainer/MessageContainer";
import InputField from "../InputField/InputField";
import QuickReplies from "../QuickReplies/QuickReplies";
import { setInput } from "../../../appRedux/actions/Chat";
import {
  attendConversation,
  finishConversation,
  registerTicket,
  returnTicket,
  getThresholds
} from "../../../appRedux/actions/Conversation";
import { getParameters } from "../../../appRedux/actions/Chat";
import iconDragDrop from "../../../assets/images/icon-replies.png";
import exportIcon from "../../../assets/images/export.png";
import closeModal from "../../../assets/images/close-modal.png";
import "./containerchat.css";
import ChannelInfo from "../ChannelChat/ChannelInfo";
import TimerChat from "../TimeCountChat/TimerChat";

class ContainerChat extends React.Component {
  constructor() {
    super();
    this.M = window.M;
    this.myRef = React.createRef();
    this.handleRegisterTicket = this.handleRegisterTicket.bind(this);
    this.state = { disabled: false };
    this.buttonIncident = React.createRef();
    this.instance = "";
  }
  state = {
    isTicketShown: false,
    isDownloadShown: false,
    isErrorTicketShown: false
  };

  componentDidMount() {
    //activación del modal
    var elems = document.querySelectorAll(".modal");
    var instance = this.M.Modal.init(elems);
  }
  handleAttend(e, parameters) {
    e.preventDefault();
    this.props.attendConversation(parameters);
  }
  handleRegisterTicket(e, parameters) {
    e.preventDefault();
    this.props.registerTicket(parameters);
  }
  handleReturnTicket(e) {
    e.preventDefault();
    this.props.returnTicket();
  }

  handleAttendClose(e, parametersClose) {
    e.preventDefault();
    this.props.finishConversation(
      parametersClose,
      parametersClose.conversation_id
    );
    this.props.containerInfo.status = parametersClose.status;
  }
  handleAttendDerived(e, parametersDerived) {
    e.preventDefault();
    this.props.finishConversation(
      parametersDerived,
      parametersDerived.conversation_id
    );
    this.props.containerInfo.status = parametersDerived.status;
  }
  handleAttendInactive(e, parametersInactive) {
    e.preventDefault();
    this.props.finishConversation(
      parametersInactive,
      parametersInactive.conversation_id
    );
    this.props.containerInfo.status = parametersInactive.status;
  }

  handleSetMessage(e, message_text) {
    const { setInput, authUser } = this.props;
    var message_text = message_text.replace("«agent»", authUser.name);
    setInput(message_text);
  }

  titleCase(str) {
    if(str == undefined) return "";
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  }

  render() {

    const { quickReplies, containerInfo, authUser } = this.props;
    const {
      user_first_name,
      user_first_surname,
      user_job,
      user_email,
      user_phone,
      user_location
    } = containerInfo.user;
    const { logo } = containerInfo;
    const { incidents_endpoint, requests_endpoint } = containerInfo;

    const { conversation_id } = containerInfo;
    const { company_id } = containerInfo;
    const { channel_name, channel_id } = containerInfo;
    const { start_date } = containerInfo;

    let name_user;
    if (user_first_name) {
      name_user = user_first_name;
    } else {
      name_user = "--";
    }

    let parametersClose = {
      status: "closed-attended",
      conversation_id: conversation_id,
      agent_id: authUser.id
    };
    let parametersDerived = {
      status: "closed-refered",
      conversation_id: conversation_id,
      agent_id: authUser.id
    };
    let parametersInactive = {
      status: "closed-inactiveuser",
      conversation_id: conversation_id,
      agent_id: authUser.id
    };


    let replies = [];
    let repliesWelcome = [];
    let repliesWaiting = [];
    let repliesfarewell = [];
    let titleWelcome;
    let titleWaiting;
    let titlefarewell;
    quickReplies.forEach(reply => {
      if (reply.company_id === company_id) {
        if (reply.category_id === 1) {
          titleWelcome = reply.category_name;
          repliesWelcome.push(
            <QuickReplies
              messageReply={reply}
              key={reply.id}
              handleSetMessage={this.handleSetMessage.bind(this)}
              reply={reply.value}
            />
          );
        } else if (reply.category_id === 2) {
          titleWaiting = reply.category_name;
          repliesWaiting.push(
            <QuickReplies
              messageReply={reply}
              key={reply.id}
              handleSetMessage={this.handleSetMessage.bind(this)}
              reply={reply.value}
            />
          );
        } else if (reply.category_id === 3) {
          titlefarewell = reply.category_name;
          repliesfarewell.push(
            <QuickReplies
              messageReply={reply}
              key={reply.id}
              handleSetMessage={this.handleSetMessage.bind(this)}
              reply={reply.value}
            />
          );
        }

      }
    });

    let variableModal;
    let modal;
    if (this.props.loading) {
      variableModal = (
        <div>
          <p>
            Estamos creando tu ticket automático, esto puede tomar unos segundos{" "}
          </p>
          <div id="loading" className="loading">
            Loading&#8230;
          </div>
        </div>
      );
    }
    if (this.props.loadingTickets) {
      modal = (
        <div>
          <div>
            <p>¿Qué tipo de ticket deseas registrar?</p>
          </div>

          <div className="container-btn">
            <button
              onClick={e => {
                this.handleRegisterTicket(e, 0);
              }}
              className=" btn btn-royal-blue btn-size-modal"
            >
              <p className="mb-0 mt-0">Incidencia</p>
            </button>
            <button
              onClick={e => {
                this.handleRegisterTicket(e, 1);
              }}
              className="btn btn-royal-blue btn-size-modal "
            >
              <p className="mb-0 mt-0">Solicitud</p>
            </button>
          </div>
        </div>
      );
    }
    if (this.props.error) {
      variableModal = (
        <div>
          <p>¡Ups!</p>
          <p>
            Parece que la creación automática está tomando mucho tiempo.Puedes
            crear tu ticket dando click en el siguiente botón
          </p>
          <a className="waves-effect waves-light btn modal-trigger btn-royal-blue size-ticket mr-2">
            <p
              onClick={e => {
                this.handleReturnTicket(e);
              }}
              className="mb-0 mt-0 "
            >
              Crear ticket
            </p>
          </a>
        </div>
      );
    }
    return (
      <div className="container-chat">
        <div className="header-chat">
          <div className="item">
            <CirclePerson
              conversation_id={containerInfo}
              user_first_name={name_user}
              user_first_surname={user_first_surname}
              logo={logo}
            />
            <div>
              <NamePerson
                fullName={
                  name_user +
                  " " +
                  user_first_surname +
                  " - " +
                  this.titleCase(user_job)
                }
              />
              <InfoPerson
                info={
                  (user_email ? user_email : "-") +
                  " / Telf: " +
                  (user_phone ? user_phone : "-") +
                  " / Sede: " +
                  (user_location ? user_location : "-")
                }
              />
            </div>
            <ChannelInfo
              channelname={channel_name}
              channelid={channel_id}
            />
            <TimerChat
              timerinit={start_date}
            />
          </div>
          <div className="">
            <div className="finish-conversation">
              <a
                className="waves-effect waves-light btn modal-trigger btn-line-royal"
                href="#modal-finish"
              >
                <p className="mb-0 mt-0">Finalizar conversación</p>
              </a>
              <a
                className="waves-effect waves-light btn modal-trigger btn-royal-blue size-ticket mr-2  "
                href="#modal-register"
              >
                <p className="mb-0 mt-0 ">Registrar ticket</p>
              </a>
            </div>
            {/*modal registrar ticket */}
            <div
              id="modal-register"
              className="modal modal-ticket "
              ref={this.buttonIncident}
            >
              <a
                href="#!"
                id="modal-register-close"
                className="modal-close position waves-effect waves-green btn-flat"
              >
                x
              </a>
              <div className="modal-content container-modal ">
                {modal}
                <div>{variableModal}</div>
              </div>
            </div>
            {/*modal finalizar conversación */}
            <div id="modal-finish" className="modal modal-finish-conversation">
              <a
                href="#!"
                className="modal-close position waves-effect waves-green btn-flat"
              >
                x
              </a>
              <div className="modal-content container-modal column ">
                <p>¿Cuál es el motivo de la finalización de la conversación?</p>
                <div className="container-modal-finish">
                  <button
                    onClick={e => {
                      this.handleAttendClose(e, parametersClose);
                    }}
                    className="btn btn-royal-blue modal-close"
                  >
                    <p className="mb-0 mt-0">Atención resuelta</p>
                  </button>
                  <button
                    onClick={e => {
                      this.handleAttendDerived(e, parametersDerived);
                    }}
                    className="btn btn-royal-blue"
                  >
                    <p className="mb-0 mt-0">Ticket transferido</p>
                  </button>
                  <button
                    onClick={e => {
                      this.handleAttendInactive(e, parametersInactive);
                    }}
                    className="btn btn-line-royal mr-0"
                  >
                    <p className="mb-0 mt-0">Inactividad del usuario</p>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-flex">
          <div className="body">
            <MessageContainer containerInfo={containerInfo} />
            <InputField
              ref={this.myRef}
              disabled={this.state.disabled ? "disabled" : ""}
            />
          </div>

          <div className="container-quick-replies">
            <div>
              <img
                src={exportIcon}
                alt="icon-drag-drop"
                className="responsive-img"
              />
              <a
                className="modal-trigger hist-ticket text-royal-blue "
                href="#modal2"
              >
                <p className="mb-0 mt-0">Historial ticket</p>
              </a>
            </div>
            <div>
              <img
                src={iconDragDrop}
                alt="icon-drag-drop"
                className="responsive-img"
              />
              <span className="text-royal-blue"> Respuestas rápidas </span>
            </div>
            <p className="title-quick-replies">{titleWelcome}</p>
            {repliesWelcome}
            <p className="title-quick-replies">{titleWaiting}</p>
            {repliesWaiting}
            <p className="title-quick-replies">{titlefarewell}</p>
            <p>{repliesfarewell}</p>
          </div>
        </div>

        <div id="modal2" className="modal modal-ticket">
          <a
            href="#!"
            className="modal-close position waves-effect waves-green btn-flat"
          >
            <img
              src={closeModal}
              alt="close-modal"
              className="responsive-img"
            />
          </a>

          <div className="modal-content container-modal column ">
            <p>¿Qué historial de ticket deseas visualizar?</p>
            <div className="container-btn">
              <a
                href={incidents_endpoint}
                target="_blank"
                className="btn btn-royal-blue btn-size-modal modal-close"
              >
                <p className="mb-0 mt-0">Incidencia</p>
              </a>

              <a
                href={requests_endpoint}
                target="_blank"
                className="modal-close btn btn-royal-blue btn-size-modal"
              >
                <p className="mb-0 mt-0">Solicitud</p>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ chat, conversation, auth }) => {
  const { quickReplies } = chat;
  const {
    containerInfo,
    loading,
    error,
    loadingTickets,
    loadingError,
    loadingTicket,
    thresholds
  } = conversation;
  const { authUser } = auth;
  return {
    quickReplies,
    containerInfo,
    authUser,
    loading,
    loadingError,
    loadingTicket,
    error,
    loadingTickets,
    thresholds
  };
};

export default connect(mapStateToProps, {
  setInput,
  attendConversation,
  finishConversation,
  getParameters,
  registerTicket,
  returnTicket,
  getThresholds
})(ContainerChat);
