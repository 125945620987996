import {
	INIT_URL,
	SIGNOUT_USER_SUCCESS,
	USER_DATA,
	USER_TOKEN_SET,
	PERMISSIONS,
	STATUS_CODE
} from '../../constants/ActionTypes';

const INIT_STATE = {
	token: localStorage.getItem('token'),
	initURL: '',
	authUser: JSON.parse(localStorage.getItem('user')),
	permissions: [],
	statusCode: ''
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case INIT_URL: {
			return { ...state, initURL: action.payload };
		}

		case SIGNOUT_USER_SUCCESS: {
			return {
				...state,
				token: '',
				authUser: '',
				initURL: '',
				statusCode: ''
			};
		}

		case USER_DATA: {
			return {
				...state,
				authUser: action.payload
			};
		}

		case USER_TOKEN_SET: {
			return {
				...state,
				token: action.payload
			};
		}

		case STATUS_CODE: {
			return {
				...state,
				statusCode: action.payload
			};
		}

		case PERMISSIONS: {
			return {
				...state,
				permissions: action.payload.slice()
			};
		}

		default:
			return state;
	}
};
