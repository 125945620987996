import React from 'react';
import { connect } from 'react-redux';


import './sessionValidation.css';
import { getRoles, putRoles } from '../../../appRedux/actions/Parameters';
import ItemSession from '../ItemSession/ItemSession';
import Modal from '../Modal/Modal';
class SessionValidation extends React.Component {
	constructor(props) {
		super(props);
		const { roles } = this.props;

		let allRoles = roles.slice(0, 3);
		this.state = {
			valuesList: [],
			showModal: false
		};
	}
	async componentWillMount() {
		await this.props.getRoles();
		const { authUser, roles } = this.props;
		let allRoles = roles.slice(0, 3);
		let listData = '';
		let ref = this;
		if (allRoles.length > 0) {
			allRoles.map(function(item, i) {
				var data = {
					id: item.id,
					description: item.description,
					session_duration: item.session_duration
				};

				ref.setState((prevState) => ({
					valuesList: [ ...prevState.valuesList, data ]
				}));
			});
		}
	}

	handleChangeSession(i, event, description, id) {
		let ref = this;
		let valuesList = ref.state.valuesList;
		let value = event.target.value * 3600;
		if (valuesList[i].description === description) {
			valuesList[i].session_duration = value;
		}


		this.setState({ valuesList });

	}
	handleCloseModal(event) {
		this.setState({
			showModal: false
		});
	}
	handleSaveSession(event) {
		let dataALL = this.state.valuesList;
		event.preventDefault();

		this.props.putRoles(dataALL);
		this.setState({
			showModal: true
		});
	}
	render() {
		const { roles, loadingSave, saveSuccessuful, saveError } = this.props;
		let allRoles = roles.slice(0, 3);
		let copyRoles = [ ...allRoles ];
		let ref = this;
		let listRoles = [];
		if (this.state.valuesList) {
			this.state.valuesList.map(function(itemRol, i) {
				listRoles.push(
					<ItemSession
						copyRoles={copyRoles}
						key={i}
						id={itemRol.id}
						name={itemRol.description + '-' + i}
						description={itemRol.description}
						duration={itemRol.session_duration}
						handleChangeSession={(event) => {
							ref.handleChangeSession(i, event, itemRol.description, itemRol.id);
						}}
					/>
				);
			});
		}

		return (
			<div className="admin-parameters_container-session ">
				<div className="admin-parameters__session-header">
					<p className="admin-parameters__session-title">Vigencia de sesión</p>
					<button
						className="btn waves-effect admin-parameters__btn-save"
						onClick={(e) => {
							this.handleSaveSession(e);
						}}
					>
						<p>Guardar</p>
					</button>
					<Modal
						handleCloseModal={(event) => {
							ref.handleCloseModal(event);
						}}
						showModal={this.state.showModal}
						loadingSave={loadingSave}
						saveSuccessuful={saveSuccessuful}
						saveError={saveError}
					/>
				</div>
				<div className="mt-10">
					<p className="admin-parameters__session-subtitle">
						Configurar parámetros generales de inicio de sesión para todos los clientes asignados
					</p>
					{listRoles}
				</div>
			</div>
		);
	}
}

const mapStateToProps = ({ auth, users, parameters }) => {
	const { authUser } = auth;
	const { roles, loadingSave, saveSuccessuful, saveError } = parameters;

	return {
		authUser,
		roles,
		loadingSave,
		saveSuccessuful,
		saveError
	};
};

export default connect(mapStateToProps, { getRoles, putRoles })(SessionValidation);
