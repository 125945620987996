import React from "react";
import "./messageReplie.css";
import { connect } from "react-redux";
import deleteTrash from "../../../assets/images/delete-trash.png";
import { deleteQuickReply } from "../../../appRedux/actions/Companies";
let initModal = 0;
let initModal2 = 0;
let itemModal;
class MessageReplie extends React.Component {
  constructor(props) {
    super(props);
    this.M = window.M;
    this.modalImages = this.modalImages.bind(this);
    this.modalImagen = React.createRef();
    this.imgModal = React.createRef();
    this.imagenFile = React.createRef();
    this.modalClose = React.createRef();
    this.modalImagenss = React.createRef();
  }
  componentDidMount() {
    //activación del modal
    var elems = document.querySelectorAll(".modal");
    const {
      autoGreetingsReplies,
      allReplies,
      repliesWelcome,
      containerInfo
    } = this.props;
    let repliesWelcomeFilter = repliesWelcome.filter(
      reply => reply.company_id === containerInfo.company_uuid
    );
    let autoGreetingFilter = autoGreetingsReplies.filter(
      reply => reply.company_id === containerInfo.company_uuid
    );
    let allRepliesFilter = allReplies.filter(
      reply => reply.company_id === containerInfo.company_uuid
    );
  }

  handleDelete(e) {
    const {
      autoGreetingsReplies,
      allReplies,
      company_id,
      id,
      containerInfo
    } = this.props;

    //reply.company_id === containerInfo.company_uuid
    //|| allRepliesFilter.length < 2
    let autoGreetingFilter = autoGreetingsReplies.filter(
      reply => reply.company_id === containerInfo.company_uuid
    );
    initModal = autoGreetingFilter.length;
    this.props.deleteQuickReply(company_id, id);

  }

  modalImages(e) {
    console.log("imagen modal");
    //this.modalImagen.current.style.display = 'block';
    this.modalImagen.current.style.display = 'block';
  }
  closeModal(e) {
    itemModal = 0;
		if (e.length > 1) this.inputField.value = ''
		this.modalImagen.current.style.display = 'none';

  }
  render() {
    const {
      autoGreetingsReplies,
      allReplies,
      containerInfo
    } = this.props;
    let autoGreetingFilter = autoGreetingsReplies.filter(
      reply => reply.company_id === containerInfo.company_uuid
    );
    let allRepliesFilter = allReplies.filter(
      reply => reply.company_id === containerInfo.company_uuid
    );
    let allRepliesFilters = allRepliesFilter.filter(
      reply => reply.category_id === 1
    );


    initModal = autoGreetingFilter.length;
    initModal2 = allRepliesFilters.length;
    return (
      <div>

        <div
          className="admin-replies_container">
          <span className="admin-replies_message">{this.props.value}</span>
          <img
            src={deleteTrash}
            alt="img-replies"
            className="responsive-img modal-trigger"
            href={"#" + this.props.id}
            onClick={(e) => {
              this.modalImages(e);
            }}
          />
        </div>
        <div
          ref={this.modalImagen}

          class="modal-img"
        >
          <div id={this.props.id} class="admin_replies_container-modal">
            <div class="modal-content">
              <p className="admin-modal__title">
                Configuración de respuestas rápidas
              </p>
              <p className="admin_modal__subtitle">¿Eliminar respuesta rápida?</p>
              <div className="admin-replies__container-messages">
                <button
                  onClick={e => {
                    this.handleDelete(e);
                  }}
                  className="admin-replies__btn-save modal-close"
                >
                  Eliminar
                </button>
                <button  
                className="admin-replies__btn-delete modal-close"
                type="button"
                ref={this.modalClose}
                onClick={(e) => {
                  this.closeModal(e, true);
                }}
               >
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }
}

const mapStateToProps = ({ conversation, auth, chat }) => {
  const { containerInfo } = conversation;
  const { authUser } = auth;
  return {
    containerInfo,
    authUser
  };
};

export default connect(
  mapStateToProps,
  { deleteQuickReply }
)(MessageReplie);
