import React from 'react';
import section from '../../assets/images/section.png';
import './reportes.css';

class Reportes extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			messagesQuick: []
		};
	}
	render() {
		return (
			<div className="row">
				<div className="col s6 offset-s3 text-report">
					<img className="responsive-img" src={section} alt="" />
					<p className="center-align mb-0">Esta sección esta construcción</p>
					<p className="center-align mt-0">Pronto tendremos más novedades</p>
				</div>
			</div>
		);
	}
}
export default Reportes;
