import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  CONVERSATION_INFO,
  ADD_CONVERSATION,
  REMOVE_CONVERSATION,
  INIT_CONVERSATIONS,
  SHOW_CONVERSATION,
  SET_INPUT,
  HIDE_CONVERSATION,
  SET_NOTIFY_SOUND,
  INIT_MESSAGE,
  SHOW_THRESHOLDS,
  LOADING,
  LOAD_USERS_LOADING,
  LOAD_USERS_SUCCESS,
  LOAD_USERS_ERROR,
  ADD_HISTORIAL,
  LOAD_HISTORIAL,
  LOAD_SUCESS_HISTORIAL,
  CLEAN_HISTORIAL,
  MANAGER,
  INIT_MESSAGE_DASHBOARD,
  CANCEL_MESSAGE_DASHBOARD,
  REMOVE_MESSAGE_DASHBOARD,
  DATA_CANCEL_DASHBOARD,
  INIT_AGENT_DASHBOARD,
  INIT_MESSAGE_AGENTE_STATUS
} from "../../constants/ActionTypes";
import axios from "util/Api";

import { leaveRoom } from "../actions/Socket";
import { addMessage, logout, getMessages } from "../actions/Chat";
import { ANALYST } from "../../constants/Constants";
import { envioGlobalError } from "../../helpers/ErrorHelper";

export const setInitUrl = url => {
  return {
    type: INIT_URL,
    payload: url
  };
};
async function download(idFile, company_id, file_name) {
  const res = await axios.get(
    "companies/" + company_id + "/files/" + idFile + "-" + file_name
  );
  return res;
}

async function getUrlFromFileId(file_id, company_id, file_name) {
  let downloadResponsePure = await download(file_id, company_id, file_name);
  let downloadResponse = downloadResponsePure.data;
  let url = "";
  if (downloadResponse.payload) {
    url = downloadResponse.payload.file_url;
  }
  return url;
}

export const getConversations = agent_id => {
  return (dispatch, getState) => {
    dispatch({
      type: FETCH_START
    });
    axios
      .get("agents/" + agent_id + "/conversations")
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: FETCH_SUCCESS
          });
          dispatch({
            type: INIT_CONVERSATIONS,
            payload: data.payload
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: data.error
          });
        }
      })
      .catch(function(error) {
        dispatch({
          type: FETCH_ERROR,
          payload: error.message
        });
        // ===============================
        // captura de error
        // ===============================
        let functionName = "getConversationsName";
        let capturaErr = error;
        envioGlobalError(capturaErr, functionName);
        // ===============================

        if (error.response !== undefined && error.response.status === 401) {
          dispatch(logout());
        }
      });
  };
};


export const getConversationInfo = company_id => {
  return (dispatch, getState) => {
    dispatch({
      type: FETCH_START
    });
    const { authUser } = getState().auth;
    let client = authUser.roles;
    let company = "";
    client
      .filter(c => c.name === ANALYST)
      .forEach(function(element) {
        company += element.company_id + ",";
      });
    company = company.slice(0, -1);
    axios
      .get("queues/conversations/summaries?companies=" + company)
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: FETCH_SUCCESS
          });
          dispatch({
            type: CONVERSATION_INFO,
            payload: data.payload
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: data.error
          });
        }
      })
      .catch(function(error) {
        dispatch({
          type: FETCH_ERROR,
          payload: error.message
        });
        // ===============================
        // captura de error
        // ===============================
        let functionName = "getConversationInfoName";
        let capturaErr = error;
        envioGlobalError(capturaErr, functionName);
        // ===============================
        if (error.response !== undefined && error.response.status === 401) {
          dispatch(logout());
        }
      });
  };
};

export const notifyMe = () => {
  return (dispatch, getState) => {
    const { silentNotify } = getState().conversation;
    var options = {
      silent: silentNotify
    };
    console.log("TraceAgent notifyMe: ", getState().conversation);
    if (!("Notification" in window))
      console.log("This browser does not support desktop notification");
    else if (Notification.permission === "granted")
      new Notification("Tienes una nueva solicitud en espera 😃", options);
    else if (Notification.permission !== "denied") {
      Notification.requestPermission(function(permission) {
        if (permission === "granted") {
          new Notification("Tienes una nueva solicitud en espera 😃", options);
        }
      });
    }
  };
};

export const notifyMessage = () => {
  return (dispatch, getState) => {
    const { silentNotify } = getState().conversation;
    var options = {
      silent: silentNotify
    };

    if (!("Notification" in window))
      console.log("This browser does not support desktop notification");
    else if (Notification.permission === "granted")
      new Notification("Tienes un nuevo mensaje", options);
    else if (Notification.permission !== "denied") {
      Notification.requestPermission(function(permission) {
        if (permission === "granted") {
          new Notification("Tienes un nuevo mensaje", options);
        }
      });
    }
  };
};
export const loading = event => {
  return dispatch => {
    dispatch({
      type: LOADING,
      payload: event
    });
  };
};

/*Efcto al momento que hay un solicitud */
export const effect = () => {
  return dispatch => {};
};

export const registerTicket = type => {
  return (dispatch, getState) => {
    dispatch({
      type: FETCH_START
    });
    dispatch({
      type: LOAD_USERS_LOADING
    });
    const { authUser } = getState().auth;
    const { containerInfo } = getState().conversation;

    let company_id = containerInfo.company_id;
    let parameter = {
      conversation_id: containerInfo.conversation_id,
      type: "INCIDENT",
      code: "GENERAL_INCIDENT",
      user_id: containerInfo.user.user_id,
      company: containerInfo.user.user_company_id,
      location_id: containerInfo.user.user_location_id,
      sender_email: authUser.email
    };

    if (type === 1) {
      parameter = {
        conversation_id: containerInfo.conversation_id,
        type: "REQUEST",
        code: "GENERAL_REQUEST",
        user_id: containerInfo.user.user_id,
        company: containerInfo.user.user_company_id,
        location_id: containerInfo.user.user_location_id,
        sender_email: authUser.email
      };
    }

    axios
      .post("companies/" + company_id + "/servicedesks/tickets", parameter)
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: FETCH_SUCCESS
          });
          let url = data.payload.ticket_uri;
          dispatch({
            type: LOAD_USERS_SUCCESS,
            data
          });
          if (url) {
            window.open(url, "_blank");
            var els = document.getElementById("modal-register-close");
            els.click();
          } else {
            dispatch({
              type: LOAD_USERS_ERROR,
              error: "Unexpected Error!!!"
            });
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: data.error
          });
        }
      })
      .catch(function(error) {
        dispatch({
          type: FETCH_ERROR,
          payload: error.message
        });
        // ===============================
        // captura de error
        // ===============================
        let functionName = "registerTicketName";
        let capturaErr = error;
        envioGlobalError(capturaErr, functionName);
        // ===============================
        if (error.response !== undefined && error.response.status === 401) {
          dispatch(logout());
        }
      });
  };
};
export const returnTicket = type => {
  return (dispatch, getState) => {
    dispatch({
      type: LOAD_USERS_SUCCESS
    });
  };
};

let attendIsBusy = false;
export const attendConversation = parameters => {
  return (dispatch, getState) => {
    if (attendIsBusy) return;
    dispatch({
      type: FETCH_START
    });
    attendIsBusy = true;
    axios
      .put("queues/conversations", parameters)
      .then(({ data }) => {
       // console.log(data.payload)
        attendIsBusy = false;
        if (data.success) {
          dispatch({
            type: FETCH_SUCCESS
          });
          if (parameters.status === "bussy") {
            dispatch({
              type: ADD_CONVERSATION,
              payload: data.payload
            });
            const conversation = data.payload;
            const { authUser } = getState().auth;
            const { autoGreetings, welcomeGreetings } = getState().chat;
            dispatch(getMessages(authUser.id));

            let companyAutoGreetings = autoGreetings.filter(
              a => a.company_id === data.payload.company_id
            );
            let companyWelcomeGreetings = welcomeGreetings.filter(
              a => a.company_id === data.payload.company_id
            );
            let autoGreeting =
              companyAutoGreetings[
                Math.floor(Math.random() * companyAutoGreetings.length)
              ];
            let welcomeGreeting =
              companyWelcomeGreetings[
                Math.floor(Math.random() * companyWelcomeGreetings.length)
              ];

            let welcome_message = welcomeGreeting.value.replace(
              "«user»",
              conversation.user ? conversation.user.user_first_name : "Usuario"
            );
            welcome_message = welcome_message.replace("«agent»", authUser.name);
            let greet_message = autoGreeting.value.replace(
              "«user»",
              conversation.user ? conversation.user.user_first_name : "Usuario"
            );
            greet_message = greet_message.replace("«agent»", authUser.name);

            let message_one = null;
            let message_two = null;

            if(conversation.channel_name === "WHATSAPP") {
              message_one = {
                conversation_id: conversation.conversation_id,
                user_id: authUser.id,
                user_type: "ANALYST",
                user_name: authUser.name,
                message_text: welcome_message,
                message_type: "accept-request",
                company_id: conversation.company_id,
                channel_id: conversation.channel_id,
                channel_name: conversation.channel_name,
                to: conversation.user ? conversation.user.user_phone : "0",
                from: conversation.bot_number
              };

              message_two = {
                conversation_id: conversation.conversation_id,
                user_id: authUser.id,
                user_type: "ANALYST",
                user_name: authUser.name,
                message_text: greet_message,
                message_type: "text",
                company_id: conversation.company_id,
                channel_id: conversation.channel_id,
                channel_name: conversation.channel_name,
                to: conversation.user ? conversation.user.user_phone : "0",
                from: conversation.bot_number
              };
              
            } 
            else if(conversation.channel_id === "3"){

              message_one = {
                conversation_id: conversation.conversation_id,
                user_id: authUser.id,
                user_type: "ANALYST",
                user_name: authUser.name,
                message_text: welcome_message,
                message_type: "accept-request",
                company_id: conversation.company_id,
                channel_id: conversation.channel_id,
                channel_name: conversation.channel_name,
                msteamsActivityContext: conversation.msteamsActivityContext
              };

              message_two = {
                conversation_id: conversation.conversation_id,
                user_id: authUser.id,
                user_type: "ANALYST",
                user_name: authUser.name,
                message_text: greet_message,
                message_type: "text",
                company_id: conversation.company_id,
                channel_id: conversation.channel_id,
                channel_name: conversation.channel_name,
                msteamsActivityContext: conversation.msteamsActivityContext
              };

            }
            else {
              message_one = {
                conversation_id: conversation.conversation_id,
                user_id: authUser.id,
                user_type: "ANALYST",
                user_name: authUser.name,
                message_text: welcome_message,
                message_type: "accept-request"
              };

              message_two = {
                conversation_id: conversation.conversation_id,
                user_id: authUser.id,
                user_type: "ANALYST",
                user_name: authUser.name,
                message_text: greet_message,
                message_type: "text"
              };
            }

            dispatch(addMessage(message_one));
            setTimeout(() => {
              dispatch(addMessage(message_two));
            }, 500);
            setTimeout(() => {
              dispatch(showConversation(conversation));
            }, 600);
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: data.error
          });
        }
      })
      .catch(function(error) {
        attendIsBusy = false;
        dispatch({
          type: FETCH_ERROR,
          payload: error.message
        });
        // ===============================
        // captura de error
        // ===============================
        let functionName = "attendConversationName";
        let capturaErr = error;
        envioGlobalError(capturaErr, functionName);
        // ===============================
        if (error.response !== undefined && error.response.status === 401) {
          dispatch(logout());
        }
      });
  };
};

let finishIsBusy = false;
export const finishConversation = (parameters, room_id) => {
  return (dispatch, getState) => {
    if (finishIsBusy) return;
    dispatch({
      type: FETCH_START
    });
    finishIsBusy = true;
    axios
      .put("queues/conversations", parameters)
      .then(({ data }) => {
        finishIsBusy = false;
        if (data.success) {
          dispatch({
            type: FETCH_SUCCESS
          });
          if (
            parameters.status === "closed-refered" ||
            parameters.status === "closed-attended" ||
            parameters.status === "closed-inactiveuser"
          ) {
            const { messages } = getState().chat;
            var filtered = messages.filter(function(item, index, arr) {
              return item.conversation_id !== data.payload.conversation_id;
            });
            const { connects, containerInfo } = getState().conversation;
            let connect = connects.filter(
              (item, index) => item.room_id === room_id
            );

            dispatch(
              attachConversation(
                containerInfo.company_id,
                data.payload.conversation_id
              )
            );

            dispatch({
              type: INIT_MESSAGE,
              payload: filtered
            });
            dispatch(leaveRoom(room_id));
            dispatch({
              type: REMOVE_CONVERSATION,
              payload: data.payload.conversation_id
            });
            dispatch({
              type: HIDE_CONVERSATION,
              payload: false
            });
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: data.error
          });
        }
      })
      .catch(function(error) {
        finishIsBusy = false;
        dispatch({
          type: FETCH_ERROR,
          payload: error.message
        });
        // ===============================
        // captura de error
        // ===============================
        let capturaErr = error;
        let functionName = "finishConversationName";
        envioGlobalError(capturaErr, functionName);
        // ===============================
        if (error.response !== undefined && error.response.status === 401) {
          dispatch(logout());
        }
      });
  };
};

export const showConversation = parameters => {
  return dispatch => {
    dispatch({
      type: CLEAN_HISTORIAL,
      payload: []
    });
    dispatch({
      type: SHOW_CONVERSATION,
      payload: parameters
    });
    dispatch({
      type: SET_INPUT,
      payload: ""
    });
  };
};

export const notificationManager = event => {
  return dispatch => {
    dispatch({
      type: SET_NOTIFY_SOUND,
      payload: event
    });
  };
};

export const attachConversation = (company_id, conversation_id) => {
  let body = JSON.stringify(conversation_id);
  return dispatch => {
    dispatch({
      type: FETCH_START
    });
    axios
      .post("/companies/" + company_id + "/tickets/attachedconversations", body)
      .then(({ data }) => {
      });
  };
};

function getThresholdsSync(company_id) {
  return axios.get("companies/" + company_id + "/thresholds");
}

export const getThresholds = company_id => {
  return async (dispatch, getState) => {
    dispatch({
      type: FETCH_START
    });
    const { authUser } = getState().auth;
    let roles = authUser.roles;
    let rolesAnalyst = roles.filter(ur => ur.name === ANALYST);
    let thresholds_total = [];
    const promises = rolesAnalyst.map(async function(element) {
      let company_id = element.company_id;
      let thresholds = await getThresholdsSync(company_id);
      let thresholdsList = thresholds.data.payload;
      let thresholds_concat = thresholds_total.concat(thresholdsList);
      thresholds_total = thresholds_concat;
    });

    await Promise.all(promises);

    dispatch({
      type: SHOW_THRESHOLDS,
      payload: thresholds_total
    });
  };
};

export const historialConversation = historialList => {
  return async (dispatch, getState) => {
    const { containerInfo } = getState().conversation;
    let user_id = containerInfo.user.user_id;
    let conversation_id;
    conversation_id =
      historialList.length > 0 && historialList[0].conversation_info
        ? historialList[historialList.length - 1].conversation_info
            .conversation_id
        : containerInfo.conversation_id;

    dispatch({
      type: LOAD_HISTORIAL
    });
    await axios
      .get(
        "users/" +
          user_id +
          "/conversations/messages?since_conversation_id=" +
          conversation_id
      )
      .then(async ({ data }) => {
        if (data.success) {
          console.log(data);
          let company_id = "";
          if(data.payload.conversation_info != null){
            company_id = data.payload.conversation_info.company_id;
          }
          
          await Promise.all(
            data.payload.messages.map(async element => {
              if (element.message_type == "FILE") {
                let url = await getUrlFromFileId(
                  element.file_id,
                  company_id,
                  element.file_name
                );
                element.file_url = url;
                return element;
              } else {
                return element;
              }
            })
          );

          dispatch({
            type: LOAD_SUCESS_HISTORIAL
          });
          dispatch({
            type: ADD_HISTORIAL,
            payload: data.payload
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: data.error
          });
        }
      });
  };
};

export const getAgentStates = () => {
  return async (dispatch, getState) => {
    const { authUser } = getState().auth;
    let userRoles = authUser.roles;
    let company = "";
    userRoles
      .filter(c => c.name === MANAGER)
      .forEach(function(element) {
        company += element.company_id + ",";
      });
    company = company.slice(0, -1);
    await axios
      .get(`agents/connections?company_ids=${company}`)
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: INIT_AGENT_DASHBOARD,
            payload: data.payload
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: data.error
          });
        }
      })
      .catch(function(error) {
        dispatch({
          type: FETCH_ERROR,
          payload: error.message
        });
        // ===============================
        // captura de error
        // ===============================
        let functionName = "getAgentStates";
        let capturaErr = error;
        envioGlobalError(capturaErr, functionName);
        // ===============================
        if (error.response !== undefined && error.response.status === 401) {
          dispatch(logout());
        }
      });
  };
};

export const getActiveConversationsStates = () => {
  return async (dispatch, getState) => {
    const { authUser } = getState().auth;
    let userRoles = authUser.roles;
    let company = "";
    userRoles
      .filter(c => c.name === MANAGER)
      .forEach(function(element) {
        company += element.company_id + ",";
      });
    company = company.slice(0, -1);
    await axios
      .get(`companies/${company}/conversations/states`)
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: INIT_MESSAGE_DASHBOARD,
            payload: data.payload
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: data.error
          });
        }
      })
      .catch(function(error) {
        dispatch({
          type: FETCH_ERROR,
          payload: error.message
        });
        // ===============================
        // captura de error
        // ===============================
        let functionName = "getActiveConversationsStatesName";
        let capturaErr = error;
        envioGlobalError(capturaErr, functionName);
        // ===============================
        if (error.response !== undefined && error.response.status === 401) {
          dispatch(logout());
        }
      });
  };
};

export const getCanceledConversationsStates = () => {
  return async (dispatch, getState) => {
    const { authUser } = getState().auth;
    let userRoles = authUser.roles;
    let company = "";
    userRoles
      .filter(c => c.name === MANAGER)
      .forEach(function(element) {
        company += element.company_id + ",";
      });
    company = company.slice(0, -1);
    await axios
      .get(`companies/${company}/conversations/states?active=false`)
      .then(({ data }) => {
        if (data.success) {
          //
          dispatch({
            type: CANCEL_MESSAGE_DASHBOARD,
            payload: data.payload
          });
        }
      });
  };
};

export const updateConversationState = (conversation_id, new_state_name) => {
  return (dispatch, getState) => {
    const { messageDashboard } = getState().conversation;
    let foundConversation = messageDashboard.find(
      m => m.conversation_id === conversation_id
    );
    foundConversation.current_state_name = new_state_name;
    let newMessages = messageDashboard.filter(
      m => m.conversation_id !== conversation_id
    );
    newMessages.push(foundConversation);
    dispatch({
      type: INIT_MESSAGE_DASHBOARD,
      payload: newMessages
    });
  };
};
//
export const removeMessage = id => {
  return (dispatch, getState) => {
    dispatch({
      type: REMOVE_MESSAGE_DASHBOARD,
      payload: id
    });
  };
};

export const getCanceledConversationsFile = (from, until) => {
  return async (dispatch, getState) => {
    const { authUser } = getState().auth;
    let userRoles = authUser.roles;
    let company = "";
    userRoles
      .filter(c => c.name === MANAGER)
      .forEach(function(element) {
        company += element.company_id + ",";
      });
    company = company.slice(0, -1);
    let queryParams = "";
    if (from) {
      queryParams += "&from=" + from;
    }
    if (until) {
      queryParams += "&until=" + until;
    }
    await axios
      .get(
        `companies/${company}/conversations/states?active=false${queryParams}`
      )
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: DATA_CANCEL_DASHBOARD,
            payload: data.payload
          });
          //

        }
      });
  };
};

//*****************   F.G.H - Agente Status New     **************/
export const getAgentsConectedStates = () => {
  return async (dispatch, getState) => {
    const { authUser } = getState().auth;
    let userRoles = authUser.roles;
    let company = "";
    userRoles
      .filter(c => c.name === MANAGER)
      .forEach(function(element) {
        company += element.company_id + ",";
      });
    company = company.slice(0, -1);
    await axios
      .get(`companies/${company}/agentsconected/states`)
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            //type: INIT_MESSAGE_DASHBOARD,
            type: INIT_MESSAGE_AGENTE_STATUS,
            payload: data.payload
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: data.error
          });
        }
      })
      .catch(function(error) {
        dispatch({
          type: FETCH_ERROR,
          payload: error.message
        });
        // ===============================
        // captura de error
        // ===============================
        let functionName = "getAgentsConectedStates";
        let capturaErr = error;
        envioGlobalError(capturaErr, functionName);
        // ===============================
        if (error.response !== undefined && error.response.status === 401) {
          dispatch(logout());
        }
      });
  };
};
