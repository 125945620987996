import { INIT_URL, ASSIGNED_USERS, SHOW_USER_DETAIL, ASSIGNED_ROLES_CHECK, HIDE_USER_DETAIL } from '../../constants/ActionTypes';

const INIT_STATE = {
	initURL: '',
	userAssigned: [],
	showUserDetail: false,
	assigendRolesCheck: []
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case INIT_URL: {
			return { ...state, initURL: action.payload };
		}
		case ASSIGNED_USERS: {
			return {
				...state,
				usersAssigned: action.payload
			};
		}
		case SHOW_USER_DETAIL: {
			return {
				...state,
				showUserDetail: true,
				containerUserDetail: action.payload
			};
		}
		case HIDE_USER_DETAIL: {
			return {
				...state,
				showUserDetail: false,
				containerUserDetail: undefined
			};
		}
		case ASSIGNED_ROLES_CHECK: {
			return {
				...state,
				assigendRolesCheck: action.payload
			};
		}
		default:
			return state;
	}
};
