import React from 'react';
import { connect } from 'react-redux';

import './itemStateTickets.css';

import ItemTicket from '../ItemTicket/ItemTicket';

class ItemStateTickets extends React.Component {
	constructor(props) {
		super(props);
	}

	async componentWillMount() {}
	addInput() {
	}
	handleDelete( index, valueState, id, paramId) {
		const { itemTicket } = this.props;
		let values = [ ...itemTicket ];
		let result;
		let ref = this;
		if (valueState === '1') {
			if (values.length > 1) {
				result = values.filter((x, item) => item !== index);
				ref.props.connectChildChange(result, 1);
				} else {
				values[0].param_label = '';
				ref.props.connectChildChange(values, 1);
			}
		} else if (valueState === '2') {
			result = values.filter((x, item) => item !== index);
			ref.props.connectChildChange(result, 2);
		} else if (valueState === '3') {
			result = values.filter((x, item) => item !== index);
			ref.props.connectChildChange(result, 3);
		} else if (paramId === 18) {
			if (values.length > 1) {
				result = values.filter((x, item) => item !== index);
				ref.props.connectChildChange(result, 18);
			} else {
				values[0].param_label = '';
				values[0].param_value = '';
				ref.props.connectChildChange(values, 18);
			}
		} else if (paramId === 19) {
			if (values.length > 1) {
				result = values.filter((x, item) => item !== index);
				ref.props.connectChildChange(result, 19);
			} else {
				values[0].param_label = '';
				values[0].param_value = '';
				ref.props.connectChildChange(values, 19);
			}
		}
	}
	handleChangeInput(event, index) {
		let valuesList = [ ...this.props.itemTicket ];

		let value = event.target.value;
		if (valuesList[index].param_id === 18) {
			valuesList[index].param_value = value;
			this.props.connectChildChange(valuesList, 18);
		} else if (valuesList[index].param_id === 19) {
			valuesList[index].param_value = value;
			this.props.connectChildChange(valuesList, 19);
		} else if (valuesList[index].param_value === '1') {
			valuesList[index].param_label = value;
			this.props.connectChildChange(valuesList, 1);
		} else if (valuesList[index].param_value === '2') {
			valuesList[index].param_label = value;
			this.props.connectChildChange(valuesList, 2);
		} else if (valuesList[index].param_value === '3') {
			valuesList[index].param_label = value;
			this.props.connectChildChange(valuesList, 3);
		}
	}
	render() {
		const { label, itemTicket, handleadd } = this.props;
		if (itemTicket.length > 0) {
		}
		let listState = [];
		let ref = this;
		if (Object.keys(this.props).length > 0) {
			if (itemTicket.length > 0) {
				itemTicket.map(function(item, index) {
					let newValue = item.param_label;
					let paramId = item.param_id;
					let newParamValue = item.param_value;
					if (label === 'Incidencia' || label === 'Requerimiento') {
						newValue = item.param_value;
						newParamValue = item.param_value + '-' + item.id;
					}

					listState.push(
						<ItemTicket
							key={index}
							value={newValue}
							id={item.id}
							handleDelete={(event) => {
								ref.handleDelete(event, index, newParamValue, item.id, paramId);
							}}
							handleChangeInput={(event) => {
								ref.handleChangeInput(event, index);
							}}
						/>
					);
				});
			}
		}

		return (
			<div className="container_ticket">
				<div className="row">
					<div className="col s5">
						<p className="ticket_label">{label}</p>
					</div>
					<div className="col s7">
						<div className="container-field">
							<div className="flex-container">{listState}</div>
							<div className="container_ticket_add">
								<p className="ticket_add_state" onClick={handleadd}>
									+ Agregar campo
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = ({}) => {};

export default connect(mapStateToProps, {})(ItemStateTickets);
