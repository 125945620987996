import {
	FETCH_ERROR,
	FETCH_START,
	FETCH_SUCCESS,
	INIT_URL,
	INIT_QUICK_REPLIES,
	INIT_WELCOME_GREETING,
	INIT_AUTO_GREETING,
	INIT_MESSAGE,
	START_TYPING,
	SIGNOUT_USER_SUCCESS,
	INCREMENT,
	TIMER,
	/* STOP_TIMER,*/
	RESET,
	STOP_TYPING,
	SET_INPUT,
	ADD_MESSAGE,
	SHOW_EMOJI,
	SUCCESS_UPLOAD,
} from '../../constants/ActionTypes';
import axios from 'util/Api';
import { socketTyping } from '../actions/Socket';
import { QUICKREPLY, WELCOMEGREETING, AUTOGREETING, ANALYST } from '../../constants/Constants';

export const setInitUrl = (url) => {
	return {
		type: INIT_URL,
		payload: url
	};
};



function getCompanyIdFromConversationId(conversation_id, conv){
	let conversations = conv;
	let company_id = "0000";
	for(let i = 0; i < conversations.length; i++){
	  if(conversations[i].conversation_id == conversation_id){
		company_id = conversations[i].company_id;
	  }
	}
	return company_id;
  }

async function download(idFile,company_id,file_name) {
	const res = await axios.get('companies/' + company_id + '/files/' + idFile+'-'+file_name);
	return res;
}

async function getUrlFromFileId(file_id,company_id,file_name){
  let downloadResponsePure = await download(file_id,company_id,file_name);  
  let downloadResponse = downloadResponsePure.data;
  let url = "";
  if(downloadResponse.payload){
    url = downloadResponse.payload.file_url
  }
  return url;
}

function getParameterSync(company_id, parameter_name) {
	return axios.get('companies/' + company_id + '/replies/' + parameter_name);
}

export const getParameters = (parameter_name) => {
	return async (dispatch, getState) => {
		dispatch({
			type: FETCH_START
		});
		const { authUser } = getState().auth;
		let roles = authUser.roles;
		let quick_reply_total = [];
		let welcome_greeting_total = [];
		let auto_greeting_total = [];

		const promises = roles.filter((r) => r.name === ANALYST).map(async function(element) {
			let company_id = element.company_id;

			let parameters = await getParameterSync(company_id, parameter_name);
			let quick_reply = parameters.data.payload.filter((item) => item.parameter_name === QUICKREPLY);
			let welcome_greeting = parameters.data.payload.filter((item) => item.parameter_name === WELCOMEGREETING);
			let auto_greeting = parameters.data.payload.filter((item) => item.parameter_name === AUTOGREETING);

			let quick_replay_concat = quick_reply_total.concat(quick_reply);
			quick_reply_total = quick_replay_concat;

			let welcome_greeting_concat = welcome_greeting_total.concat(welcome_greeting);
			welcome_greeting_total = welcome_greeting_concat;

			let auto_greeting_concat = auto_greeting_total.concat(auto_greeting);
			auto_greeting_total = auto_greeting_concat;
		});

		await Promise.all(promises);

		dispatch({
			type: INIT_QUICK_REPLIES,
			payload: quick_reply_total
		});
		dispatch({
			type: INIT_WELCOME_GREETING,
			payload: welcome_greeting_total
		});
		dispatch({
			type: INIT_AUTO_GREETING,
			payload: auto_greeting_total
		});
	};
};

export const getMessages = (agent_id) => {
	return (dispatch, getState) => {
		dispatch({
			type: FETCH_START
		});
		axios
			.get('agents/' + agent_id + '/conversations/messages')
			.then(async ({ data }) => {				
				if (data.success) {
					await Promise.all(data.payload.map(async (element) => {
						if(element.message_type == 'FILE'){
							let company_id = getCompanyIdFromConversationId(element.conversation_id,JSON.parse(JSON.stringify(getState().conversation.conversations)));
							let url = await getUrlFromFileId(element.file_id,company_id,element.file_name);
							element.file_url = url;
							return element;
						}else{
							return element;
						}
					}));
					dispatch({ type: FETCH_SUCCESS });
					dispatch({ type: INIT_MESSAGE, payload: data.payload });

					/*Conversaciones pendientes*/
					let { messages } = getState().chat;
					let messagesFilter = messages.filter(
						(item) => item.user_type === 'END-USER' && item.status === 'SENT'
					);
					let conversationsFilter = Array.from(
						new Set(messagesFilter.map(({ conversation_id }) => conversation_id))
					);

					let title = 'Smart Agent';
					if (conversationsFilter.length === 0) {
						document.title = title;
					} else {
						document.title = '(' + conversationsFilter.length + ') ' + title;
					}
					/* */
				} else {
					dispatch({
						type: FETCH_ERROR,
						payload: data.error
					});
				}
			})
			.catch(function(error) {
				dispatch({
					type: FETCH_ERROR,
					payload: error.message
				});
				if (error.response != undefined && error.response.status === 401) {
					dispatch(logout());
				}
			});
	};
};

export const startTypingIndicator = (parameter) => {
	return (dispatch, getState) => {
		const { timerRef, isTyping } = getState().chat;
		if (!isTyping) {
			dispatch(socketTyping(parameter.conversation_id, 'startTyping'));
			dispatch({
				type: START_TYPING
			});
		}

		clearInterval(timerRef);
		dispatch({
			type: RESET
		});
		dispatch({
			type: TIMER,
			payload: null
		});

		dispatch({
			type: TIMER,
			payload: setInterval(() => {
				dispatch({
					type: INCREMENT
				});
				const { second } = getState().chat;
				if (second === 4) dispatch(stopTypingIndicator(parameter));
			}, 1000)
		});
	};
};

export const stopTypingIndicator = (parameter) => {
	return (dispatch, getState) => {
		const { timerRef } = getState().chat;
		dispatch({
			type: STOP_TYPING
		});
		dispatch(socketTyping(parameter.conversation_id, 'stopTyping'));
		clearInterval(timerRef);
		dispatch({
			type: RESET
		});
	};
};

export const setInput = (message_text) => {
	return (dispatch) => {
		dispatch({
			type: SET_INPUT,
			payload: message_text
		});
	};
};

export const showEmoji = (isShowEmoji) => {
	return (dispatch) => {
		dispatch({
			type: SHOW_EMOJI,
			payload: isShowEmoji
		});
	};
};

export const updateMessage = (conversation_id) => {
	return (dispatch, getState) => {
		const { messages } = getState().chat;
		let newMessages = [];
		let count = 0;
		for (const element of messages) {
			if (
				element.conversation_id === conversation_id &&
				element.user_type === 'END-USER' &&
				element.status === 'SENT'
			) {
				element.status = 'READ';
				count++;
			}
			newMessages.push(element);
		}
		if (count > 0)
			dispatch({
				type: INIT_MESSAGE,
				payload: newMessages
			});
	};
};

export const uploadFile = (dataBinary, fileName, parameters,company_id) => {
	return (dispatch, getState) => {
		dispatch({
			type: FETCH_START
		});
		dispatch({
			type: ADD_MESSAGE,
			payload: parameters
		});
		parameters.file_url = '';
		let parameter = {
			data_binary: dataBinary,
			file_name: fileName,
			company_id: company_id
		};
		axios
			.post('files', parameter)
			.then(({ data }) => {
				if (data.success) {
					dispatch({
						type: SUCCESS_UPLOAD,
						payload: data.payload.dataBinary
					});
					parameters.file_url = data.payload.dataBinary.Location;
					parameters.file_id = data.payload.file_id;
					dispatch(addMessage(parameters));
				} else {
					dispatch({
						type: FETCH_ERROR,
						payload: data.error
					});
				}
			})
			.catch(function(error) {
				dispatch({
					type: FETCH_ERROR,
					payload: error.message
				});
				if (error.response != 'undefined' && error.response.status === 401) {
					dispatch(logout());
				}
			});
	};
};

export const addMessage = (parameter) => {
	return (dispatch, getState) => {
		dispatch({
			type: FETCH_START
		});
		axios
			.post('messages', parameter)
			.then(({ data }) => {
				if (data.success) {
					dispatch({
						type: FETCH_SUCCESS
					});
				} else {
					dispatch({
						type: FETCH_ERROR,
						payload: data.error
					});
				}
			})
			.catch(function(error) {
				dispatch({
					type: FETCH_ERROR,
					payload: error.message
				});
				if (error.response != undefined && error.response.status === 401) {
					dispatch(logout());
				}
			});
	};
};

export const logout = () => {
	return (dispatch) => {
		dispatch({
			type: FETCH_START
		});
		localStorage.removeItem('token');
		dispatch({
			type: FETCH_SUCCESS
		});
		dispatch({
			type: SIGNOUT_USER_SUCCESS
		});
	};
};

export const updateHistorialRead = (conversation_id) => {
	return (dispatch, getState) => {
		dispatch({
			type: FETCH_START
		});
		var parameter = {
			state: 'READ',
			user_type: 'ANALYST'
		};

		axios
			.put('conversations/' + conversation_id + '/messages/states', parameter)
			.then(({ data }) => {
	
			})
			.catch(function(error) {
				dispatch({
					type: FETCH_ERROR,
					payload: error.message
				});
				if (error.response != undefined && error.response.status === 401) {
					dispatch(logout());
				}
			});
	};
};
