import React from 'react';
import { connect } from 'react-redux';

import './itemDetailPlatform.css';
import {} from '../../../appRedux/actions/Parameters';

class ItemDetailPlatform extends React.Component {
	constructor(props) {
		super(props);
		this.M = window.M;
	}

	render() {
		const { label, handleChangeEndpoint, description } = this.props;

		return (
			<div className="row">
				<div className="col s5">
					<label className="admin-parameters__title-form" htmlFor="">
						{label}
					</label>
				</div>
				<div className="col s7">
					<div className="admin-parameters__container">
						<div className="admin-parameters_inputs">
							<input
								className="input-default"
								required
								value={description}
								onChange={handleChangeEndpoint}
							/>
						</div>
						<span className="admin-parameters_asterisk">*</span>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = ({ auth, users, parameters }) => {
	const { authUser } = auth;
	const { platforms } = parameters;

	return {
		authUser,
		platforms
	};
};

export default connect(mapStateToProps, {})(ItemDetailPlatform);
