import React from 'react';
import { connect } from 'react-redux';
import { showConversation } from '../../../appRedux/actions/Conversation';

import { getAssignedCompanies, showContainerReplies, showAdminReplies } from '../../../appRedux/actions/Companies';
//showConversation

import './containerParameters.css';
import { removeAllSocket, connectSocketsDashboard } from '../../../appRedux/actions/Socket';
import SessionValidation from '../SessionValidation/SessionValidation';
import ClientParameters from '../ClientParameters/ClientParameters';
import ConfigurationWatson from '../ConfigurationWatson/ConfigurationWatson';
import ConfigurationTickets from '../ConfigurationTickets/ConfigurationTickets';
import ParametersPSS from '../parameters-pss/ParametersPSS/ParametersPSS';
import ConfigurationApi from '../parameters-api/ConfigurationApi/ConfigurationApi';

class ContainerParameters extends React.Component {
	constructor(props) {
		super(props);
		this.M = window.M;
	}
	componentDidMount() {
		this.M.Tabs.init(this.Tabs);
	}

	render() {

		return (
			<div className="row  ml-10">
				<div className="col s12">
					<div className="admin-parameters-navbar">
						<p className="prueba-navbar"> Configuración de parámetros</p>
					</div>
				</div>
				<div className="col s12 ">
					<div className="admin-parameters__body">
						<div className="row ">
							<div className="col s6 m4 l4 pl-0 ">
								<div className="admin-parameters__container-companies">
									<div className="col s12 mr-0">
										<ul
											className="tabs prueba-tabs"
											ref={(Tabs) => {
												this.Tabs = Tabs;
											}}
										>
											<a className="admin-parameters__title">
												<p className="mt-0 mb-0 pl-0">Parámetros generales</p>
											</a>
											<li className="tab col s12 admin-parameters__tab">
												<a className="admin-parameters__item active" href="#vigencia">
													<p className="mt-0 mb-0">Vigencia de sesión</p>
												</a>
											</li>
											<a className="admin-parameters__title">
												<p className="mt-0 mb-0 pl-0">Parámetros por cliente</p>
											</a>
											<li className="tab col s12 admin-parameters__tab">
												<a className="admin-parameters__item" href="#configuracionPlataforma">
													<p className="mt-0 mb-0"> Configuración de plataforma</p>
												</a>
											</li>
											<li className="tab col s12 admin-parameters__tab">
												<a className="admin-parameters__item" href="#watson">
													<p className="mt-0 mb-0">Configuración watson</p>
												</a>
											</li>
											<li className="tab col s12 admin-parameters__tab">
												<a className="admin-parameters__item" href="#tickets">
													<p className="mt-0 mb-0">Configuración de estados de tickets</p>
												</a>
											</li>
											<li className="tab col s12 admin-parameters__tab">
												<a className="admin-parameters__item" href="#pss">
													<p className="mt-0 mb-0">Parámetros PSS</p>
												</a>
											</li>
											<li className="tab col s12 admin-parameters__tab">
												<a className="admin-parameters__item" href="#parametros-api">
													<p className="mt-0 mb-0">Parámetros API</p>
												</a>
											</li>
										</ul>
									</div>
								</div>
							</div>
							<div className="col s6 m8 l8 ">
								<div id="vigencia" className="col s6">
									<SessionValidation />
								</div>

								<div id="configuracionPlataforma" className="col s6">
									<ClientParameters />
								</div>
								<div id="watson" className="col s6">
									<ConfigurationWatson />
								</div>
								<div id="tickets" className="col s6">
									<ConfigurationTickets />
								</div>
								<div id="parametros-api" className="col s6">
									<ConfigurationApi />
								</div>
								<div id="pss" className="col s6">
									<ParametersPSS />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = ({ auth, conversation, companies }) => {
	const { authUser } = auth;
	const { showContainer, containerInfo } = conversation;
	const { companiesAssigned, containerReplies, containerAdminReplies, showAdmin } = companies;
	return {
		authUser,
		companiesAssigned,
		showContainer,
		containerInfo,
		containerReplies,
		containerAdminReplies,
		showAdmin
	};
};

export default connect(mapStateToProps, {
	getAssignedCompanies,
	showContainerReplies,
	showConversation,
	removeAllSocket,
	connectSocketsDashboard,
	showAdminReplies
})(ContainerParameters);
