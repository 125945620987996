import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  USER_DATA,
  USER_TOKEN_SET,
  PERMISSIONS,
  STATUS_CODE
} from "../../constants/ActionTypes";
import axios from "util/ApiAuth";
import axiosapi from "util/Api";
import * as CryptoJS from "crypto-js";
import { removeAllSocket } from "../actions/Socket";
/*
const express = require("express");

const app = express();

app.use(express.json());

app.use((error,req,res,next,message) => {
	res.status(400).json({
		status: 'error',
		message: message.error,
	});
});*/

//const { body, validationResult } = require('express-validator');


export const setInitUrl = url => {
  return {
    type: INIT_URL,
    payload: url
  };
};

export const userSignIn = ({ email, password, os, browser }) => {
  return async dispatch => {
    dispatch({ type: FETCH_START });

    var cryptoPassword = CryptoJS.AES.encrypt(password, "12345678");

   /* body('email').isEmail().normalizeEmail(),
    body('password').isLength({
        min: 6
    }),
    (req, res) => {
        const errors = validationResult(req);

        if (!errors.isEmpty()) {
            return res.status(400).json({
                success: false,
                errors: errors.array()
            });
        }

        res.status(200).json({
            success: true,
            message: 'Login successful',
        })
    }*/

    var dataPost = {
      email: email,
      password: cryptoPassword.toString(),
      os: os,
      browser: browser
    };

    try {
      let response = await axios.post("auth/login", dataPost);
      let data = response.data;
      if (data.result) {
        localStorage.setItem("token", data.token.access_token);
        axios.defaults.headers.common["access-token"] =
          "Bearer " + data.token.access_token;
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: USER_TOKEN_SET, payload: data.token.access_token });

      } else {
        dispatch({ type: FETCH_ERROR, payload: data.error });
      }
    } catch (error) {
      if (error.response !== undefined && error.response.status !== undefined) {
        dispatch({ type: STATUS_CODE, payload: error.response.status });
      }

      dispatch({ type: FETCH_ERROR, payload: error.message });
     
    }
  };
};

export const getUser = values => {
  return (dispatch, getState) => {
    dispatch({ type: FETCH_START });

   
    axios
      .post("auth/me")
      .then(({ data }) => {
        if (data.result) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: USER_DATA, payload: data.user });
          dispatch(userMenu());
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });

      });
  };
};

export const userSignOut = () => {
  return (dispatch, getState) => {
    dispatch({ type: FETCH_START });

    setTimeout(() => {
      localStorage.removeItem("token");
      dispatch({ type: FETCH_SUCCESS });
      dispatch(removeAllSocket());
      dispatch({ type: SIGNOUT_USER_SUCCESS });
    }, 100);
  };
};

//=============================================
//Send Token Logout
//==============================================
export const SendLogout = () => {
  return (dispatch, getState) => {
    dispatch({ type: FETCH_START });
    const token = localStorage.getItem("token");
    console.log("====================================");
    console.log("prbando nuevo endpoint");
    console.log("====================================");
    axios
      .delete(`sessions/${token}`)
      .then(({ data }) => {})
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const userMenu = user_id => {
  return (dispatch, getState) => {
    dispatch({ type: FETCH_START });
    const { authUser } = getState().auth;
    axiosapi
      .get("users/" + user_id + "/roles/permissions")
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS });
          if (data.payload.length > 0) {
            dispatch({
              type: PERMISSIONS,
              payload: data.payload
            }); 
          }
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};
