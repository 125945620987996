import {
	INIT_URL,
	FETCH_START,
	FETCH_ERROR,
	FETCH_SUCCESS,
	ASSIGNED_USERS,
	SHOW_USER_DETAIL,
	HIDE_USER_DETAIL,
	LOAD_SUCCESS,
	LOAD_LOADING,
	LOAD_ERROR
} from '../../constants/ActionTypes';
import {} from '../../constants/Constants';

import axios from 'util/Api';
import { envioGlobalError } from '../../helpers/ErrorHelper';

export const setInitUrl = (url) => {
	return {
		type: INIT_URL,
		payload: url
	};
};
export const getAssignedUser = () => {
	return (dispatch) => {
		dispatch({ type: FETCH_START });
		axios
			.get('users')
			.then(({ data }) => {
				if (data.success) {
					dispatch({
						type: FETCH_SUCCESS
					});
					dispatch({
						type: ASSIGNED_USERS,
						payload: data.payload
					});
		
				} else {
					dispatch({
						type: FETCH_ERROR,
						payload: data.error
					});
				}
			})
			.catch(function(error) {
				dispatch({
					type: FETCH_ERROR,
					payload: error.message
				});
				// ===============================
				// captura de error y nombre de la funcion
				// ===============================
				let functionName = 'getAssignedUserName';
				let capturaErr = error;
				envioGlobalError(capturaErr, functionName);
				// ===============================
			});
	};
};

export const removeUserDetail = (parameters) => {
	console.log('HIde user detail - parameters');

	return (dispatch, getState) => {
		dispatch({
			type: HIDE_USER_DETAIL
		});
	};
};

export const getUserDetail = (parameters) => {
	return (dispatch, getState) => {
		dispatch({ type: FETCH_START });
		axios
			.get('users/' + parameters.key)
			.then(({ data }) => {
				if (data.success) {
					dispatch({
						type: FETCH_SUCCESS
					});
					dispatch({
						type: SHOW_USER_DETAIL,
						payload: data.payload
					});
				} else {
					dispatch({
						type: FETCH_ERROR,
						payload: data.error
					});
				}
			})
			.catch(function(error) {
				dispatch({
					type: FETCH_ERROR,
					payload: error.message
				});
				// ===============================
				// captura de error
				// ===============================
				let functionName = 'getUserDetailName';
				let capturaErr = error;
				envioGlobalError(capturaErr, functionName);
				// ===============================
			});
	};
};

export const putUserDetail = (parameters, listRoles) => {
	return (dispatch) => {
		dispatch({
			type: FETCH_START
		});
		dispatch({
			type: LOAD_LOADING
		});
		axios
			.put('users/' + parameters.id, parameters)
			.then(({ data }) => {
				if (data.success) {
					dispatch({
						type: FETCH_SUCCESS
					});
					dispatch({
						type: LOAD_SUCCESS
					});
					dispatch(putUserRoles(parameters.id, listRoles, 1));

				} else {
					dispatch({
						type: LOAD_ERROR,
						error: 'djdjd'
					});
					dispatch({
						type: FETCH_ERROR,
						payload: data.error
					});
				}
			})
			.catch(function(error) {
				dispatch({
					type: FETCH_ERROR,
					payload: error.message
				});
				// ===============================
				// captura de error
				// ===============================
				let functionName = 'putUserDetailName';
				let capturaErr = error;
				envioGlobalError(capturaErr, functionName);
				// ===============================
			});
	};
};

export const putNewUserDetail = (parameters, listRoles) => {
	return (dispatch) => {
		dispatch({ type: FETCH_START });
		dispatch({
			type: LOAD_LOADING
		});
		axios
			.post('users', parameters)
			.then(({ data }) => {
				if (data.success) {
					dispatch({ type: FETCH_SUCCESS });
					dispatch({ type: LOAD_SUCCESS });
					dispatch(putUserRoles(data.payload.id, listRoles, 1));

	
				} else {
					dispatch({ type: FETCH_ERROR, payload: data.error });
					dispatch({
						type: LOAD_ERROR,
						error: 'djdjd'
					});
				}
			})
			.catch(function(error) {
				dispatch({
					type: FETCH_ERROR,
					payload: error.message
				});
				// ===============================
				// captura de error
				// ===============================
				let functionName = 'putNewUserDetailName';
				let capturaErr = error;
				envioGlobalError(capturaErr, functionName);
				// ===============================
			});
	};
};

/*actualizar la sección de roles */
export const putUserRoles = (user_id, parameters, newState) => {
	return (dispatch) => {
		dispatch({ type: FETCH_START });
		axios
			.put('users/' + user_id + '/roles', parameters)
			.then(({ data }) => {
				if (data.success) {
					dispatch({ type: FETCH_SUCCESS });

		
					if (newState === 1) {
						dispatch(getAssignedUser());
					}
				} else {
					dispatch({ type: FETCH_ERROR, payload: data.error });
				}
			})
			.catch(function(error) {
				dispatch({
					type: FETCH_ERROR,
					payload: error.message
				});
				// ===============================
				// captura de error
				// ===============================
				let functionName = 'putUserRolesName';
				let capturaErr = error;
				envioGlobalError(capturaErr, functionName);
				// ===============================
			});
	};
};
