import React from 'react';
import './container404.css';
import page404 from '../../../assets/images/page-404.png';
class Container404 extends React.Component {
	render() {
		return (
			<div className="Rectangle">
				<img src={page404} className="Imagen" alt="" />
				<p className="Inicia-una-conversac">
					{' '}
					¡Ups! Hemos registrado un problema con esta sección<br /> Puedes intentar reiniciar la página o
					volver a<br /> ingresar en unos minutos.
				</p>
			</div>
		);
	}
}

export default Container404;
