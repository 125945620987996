import React from "react";
import "./boxrequest.css";
import {
  getConversationInfo,
  attendConversation,
  showConversation,
  getThresholds
} from "../../../appRedux/actions/Conversation";
import CustomMoment from "../CustomMoment/CustomMoment";
import { connect } from "react-redux";

import { ANALYST } from "../../../constants/Constants";

class BoxRequest extends React.Component {
  constructor(props) {
    super(props);
    this.title = React.createRef();
    this.effectCount = React.createRef();

  }

  handleAttend(e, parameters) {
    e.preventDefault();

    const { conversationInfo } = this.props;
    let cant = this.getCountQueue(conversationInfo);
    if (cant != 0) {
      this.props.attendConversation(parameters);


    }
  }

  getRamdomWelcomeMessage() {
    const { welcomeGreetings } = this.props;
    let index = Math.floor(Math.random() * 3);
    let welcomeGreeting = welcomeGreetings[index];
    let msg = welcomeGreeting;
    return msg;
  }

  getMinDate(conversationsQueue) {
    let min = null;
    for (let i = 0; i < conversationsQueue.length; i++) {
      if (min == null) {
        min = conversationsQueue[i].start_date;
      }
      if (min > conversationsQueue[i].start_date) {
        min = conversationsQueue[i].start_date;
      }
    }

    return min;
  }

  getMaxDate(conversationsQueue) {
    let max = null;
    for (let i = 0; i < conversationsQueue.length; i++) {
      if (max == null) {
        max = conversationsQueue[i].end_date;
      }
      if (max < conversationsQueue[i].end_date) {
        max = conversationsQueue[i].end_date;
      }
    }
    return max;
  }

  getCountQueue(conversationsQueue) {
    let count = 0;
    for (let i = 0; i < conversationsQueue.length; i++) {
      count = count + parseInt(conversationsQueue[i].count_conversations);
    }
    return count;
  }

  render() {
    const {
      conversationInfo,
      authUser
    } = this.props;


    let message = {
      conversation_id: "",
      user_id: authUser.id,
      user_type: ANALYST,
      user_name: "",
      message_text: this.getRamdomWelcomeMessage()
    };
    let parameters = {
      agent_id: authUser.id,
      status: "bussy",
      data: message
    };
    return (
      <div className="container-request">
        <div className="container-time">
          <div className="">
            <p className="mb-0  title-time"> Más antiguo </p>{" "}
            <span className="last-time">
              <CustomMoment
                interval={1000}
                date={this.getMinDate(conversationInfo)}
              />
            </span>
          </div>
          <div className="" id="counter-queue" ref={this.effectCount}>
            <span> {this.getCountQueue(conversationInfo)} </span>{" "}
          </div>
          <div className="">
            <p className="mb-0  title-time"> Reciente </p>
            <span className="first-time">
              <CustomMoment
                interval={1000}
                date={this.getMaxDate(conversationInfo)}
              />
            </span>
          </div>
        </div>
        <p className="margin-btn"> Solicitudes nuevas </p>{" "}
        <button
          onClick={e => {
            this.handleAttend(e, parameters);
          }}
          className="waves-effect waves-light btn btn-request prueba-margen"
        >
          atender solicitud
        </button>
      </div>
    );
  }
}

const mapStateToProps = ({ conversation, auth, chat }) => {
  const {
    conversationInfo,
    containerInfo,
    thresholds
  } = conversation;
  const { authUser } = auth;
  const { welcomeGreetings } = chat;
  return {
    conversationInfo,
    containerInfo,
    authUser,
    welcomeGreetings,
    thresholds
  };
};

export default connect(mapStateToProps, {
  getConversationInfo,
  attendConversation,
  showConversation,
  getThresholds
})(BoxRequest);
