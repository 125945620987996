import React from 'react';
import { connect } from 'react-redux';
import ContainerDefaultPlatform from '../ContainerDefaultPlatform/ContainerDefaultPlatform';
import ItemDetailPlatform from '../ItemDetailPlatform/ItemDetailPlatform';
import ItemStateTickets from '../ItemStateTickets/ItemStateTickets';
import Modal from '../Modal/Modal';
import './clientParameters.css';
import {
	getAllCompanies,
	getPlatformsDefault,
	getPlatformsCompany,
	getParametersPlatform,
	putParametersPlatform,
	putEndpointPlatform,
	getPlatformStates,
	putPlatformStates
} from '../../../appRedux/actions/Parameters';


class ClientParameters extends React.Component {
	constructor(props) {
		super(props);
		this.M = window.M;
		this.state = {
			allCompanies: [],
			platformsDefault: [],
			platformsCompany: [],
			platform_endpoint: '',
			platform_id: '',
			platform_name: '',
			allParametersPlatforms: [],
			filterParametersPlatform: [],
			showElemet: false,
			endPoint: '',
			companyId: '',
			otherParameters: [],
			showButtonSave: false,
			showModal: false,
			platformUnique: '',
			parametersPlatformStates: '',
			filterIncident: [],
			filterRequeriment: []
		};
		this.select = React.createRef();
		this.M = window.M;
	}
	componentDidMount() {
		//activación del modal
		var elems = document.querySelectorAll('.modal');
		var instance = this.M.Modal.init(elems);
	}
	async componentWillMount() {
		await this.props.getPlatformsDefault();
		await this.props.getAllCompanies();

		const { listAllCompanies, platformsDefault } = this.props;

		this.setState({
			allCompanies: listAllCompanies,
			platformsDefault: platformsDefault
		});
	
	}
	async handleSelectCompany(event) {
		let company_id = event.target.value;

		await this.props.getPlatformsCompany(company_id);

		await this.props.getParametersPlatform(company_id);
		await this.props.getPlatformStates(company_id);

		const { platformsCompany, parametersPlatform, parametersPlatformStates } = this.props;

		let urlPlatformCompany;
		let platformId;
		if (platformsCompany) {
			urlPlatformCompany = platformsCompany.platform_endpoint;
			platformId = platformsCompany.platform_id;
		} else {
			urlPlatformCompany = ' ';
			platformId = '';
		}

		this.setState({
			showButtonSave: true,
			platform_endpoint: urlPlatformCompany,
			allParametersPlatforms: parametersPlatform,
			platform_id: platformId,
			platformsCompany: platformsCompany,
			companyId: company_id,
			showElemet: true,
			parametersPlatformStates: parametersPlatformStates
		});
		// autoselccionar la empresa
		let platformUnique;
		let nodes;
		if (platformsCompany === undefined || platformsCompany === null) {
			this.select.current.childNodes[0].selected = true;
		} else {
			platformUnique = platformsCompany.platform_name;
			nodes = this.select.current.childNodes;
			this.setState({
				platformUnique: platformUnique
			});
			for (var i = 0; i < nodes.length; i++) {
				if (nodes[i].value === this.state.platformUnique) {
					nodes[i].selected = true;
				}
			}
		}


		let filterParametersPlatform = this.state.allParametersPlatforms.filter(
			(element) => element.required_for_platform === this.state.platform_id
		);
		this.setState({
			filterParametersPlatform: filterParametersPlatform
		});

		let filterParametersStates = this.state.parametersPlatformStates.filter(
			(element) => element.required_for_platform === this.state.platform_id
		);
		let filterIncident;
		let filterRequeriment;
		if (filterParametersStates.length > 0) {
			filterIncident = filterParametersStates.filter((element) => element.param_id === 18);
			filterRequeriment = filterParametersStates.filter((element) => element.param_id === 19);
		}
		await this.setState({
			filterIncident: filterIncident,
			filterRequeriment: filterRequeriment
		});


		let reft = this;
	}
	async handleSelectPlatform(event) {
		let company_id = this.state.companyId;
		await this.props.getParametersPlatform(company_id);
		const { parametersPlatform } = this.props;
		this.setState({
			otherParameters: parametersPlatform
		});
		let value = event.target.value;
		this.setState({
			platformUnique: value
		});
		let platformDefault = this.state.platformsDefault;
		let platformsCompany = this.state.platformsCompany;
		// filter parameters
		let idFilterPlatform;
		platformDefault.map(function(i) {
			if (i.name === value) {
				idFilterPlatform = i.id;
			}
		});
		let filterbyplatform = this.state.otherParameters.filter(
			(item) => item.required_for_platform === idFilterPlatform
		);
		//filter endpoint
		let newEndpoint;
		if (platformsCompany) {
			if (platformsCompany.platform_name === value) {
				newEndpoint = platformsCompany.platform_endpoint;
			} else {
				newEndpoint = '';
			}
		}

		this.setState({
			filterParametersPlatform: filterbyplatform,
			platform_endpoint: newEndpoint
		});

	}

	handleChangeEndpoint(event, i, label) {
		let ref = this;

		let filterChangeParamters = ref.state.filterParametersPlatform;
		let value = event.target.value;
		if (filterChangeParamters[i].param_description === label) {
			filterChangeParamters[i].param_value = value;
		}
		this.setState({

			filterParametersPlatform: filterChangeParamters
		});
	}
	handleChangeEndpoints(event) {
		this.setState({
			platform_endpoint: event.target.value
		});
	}
	handleCloseModal() {
		this.setState({
			showModal: false
		});
	}
	handleadd(e, state) {
		if (state === 18) {
			let filterIncident = [ ...this.state.filterIncident ];
			filterIncident.push({
				id: '',
				company_id: '',
				company_name: '',
				platform_id: this.state.filterIncident[0].platform_id,
				platform_name: '',
				param_id: 18,
				param_name: '',
				param_description: '',
				param_label: '',
				param_value: '',
				required_for_platform: '',
				company_platform_id: '',
				param_group_id: '',
				param_group_name: ''
			});
			this.setState({
				filterIncident: filterIncident
			});
		} else if (state === 19) {
			let filterRequeriment = [ ...this.state.filterRequeriment ];
			filterRequeriment.push({
				id: '',
				company_id: '',
				company_name: '',
				platform_id: this.state.filterRequeriment[0].platform_id,
				platform_name: '',
				param_id: 19,
				param_name: '',
				param_description: '',
				param_label: '',
				param_value: '',
				required_for_platform: '',
				company_platform_id: '',
				param_group_id: '',
				param_group_name: ''
			});
			this.setState({
				filterRequeriment: filterRequeriment
			});
		}
	}
	connectChildChange(params, type) {
		if (type === 18) {
			this.setState(
				{
					filterIncident: params
				},
				() => console.log('Updated filterIncident', this.state)
			);
		} else if (type === 19) {
			this.setState(
				{
					filterRequeriment: params
				},
				() => console.log('Updated filterRequeriment', this.state)
			);
		}
	}
	//save
	async handleSaveParametersPlatform(event) {
		this.setState({
			showModal: true
		});
		event.preventDefault();
		const { platformsCompany } = this.props;
		let datafilter = this.state.filterParametersPlatform;
		let groupData = [];
		let groupDataEndpoint = [];
		let dataItem;
		let mel;
		let company_id = this.state.companyId;
		datafilter.map(function(item) {
			dataItem = {
				param_id: item.param_id,
				label: item.param_label,
				value: item.param_value,
				platform_id: item.required_for_platform
			};
			groupData.push(dataItem);
		});
		let newPlatformId;
		let ref = this;
		this.state.platformsDefault.map(function(item) {
			if (ref.state.platformUnique === item.name) {
				newPlatformId = item.id;
			}
		});
		let dataEndpoint = {
			base_endpoint: this.state.platform_endpoint,
			platform_id: newPlatformId
		};
		let dataFilterIncident;
		let groupFilterIncident = [];
		if (this.state.filterIncident || this.state.filterRequeriment) {
			let resultFilterIncident = this.state.filterIncident;
			let resultFilterRequeriment = this.state.filterRequeriment;
			let allParameterStates = [].concat(resultFilterIncident, resultFilterRequeriment);
			allParameterStates.map(function(item) {
				dataFilterIncident = {
					param_id: item.param_id,
					label: item.param_label,
					value: item.param_value,
					platform_id: item.platform_id
				};
				groupFilterIncident.push(dataFilterIncident);
			});
		}

		// endpoint
		if (groupFilterIncident.length > 0) {
			await this.props.putPlatformStates(company_id, groupFilterIncident);
		}
		//await this.props.putPlatformStates(company_id, groupFilterIncident);
		this.props.putEndpointPlatform(company_id, dataEndpoint);
		//parameters
		this.props.putParametersPlatform(company_id, groupData);
	}
	createIncident() {
		const { label } = this.props;
		if (this.state.filterIncident === undefined) {
			return <p>La plataforma escogida no requiere esta configuración</p>;
		} else {
			return (
				<ItemStateTickets
					label="Incidencia"
					itemTicket={this.state.filterIncident ? this.state.filterIncident : []}
					handleadd={(event) => {
						this.handleadd(event, 18);
					}}
					connectChildChange={(paramState, type) => {
						this.connectChildChange(paramState, type);
					}}
				/>
			);
		}
	}
	createRequeriment() {
		if (this.state.filterRequeriment === undefined) {
			return '';
		} else {
			return (
				<ItemStateTickets
					label="Requerimiento"
					itemTicket={this.state.filterRequeriment ? this.state.filterRequeriment : []}
					handleadd={(event) => {
						this.handleadd(event, 19);
					}}
					connectChildChange={(paramState, type) => {
						this.connectChildChange(paramState, type);
					}}
				/>
			);
		}
	}
	//
	render() {
		const { loadingSave, saveSuccessuful, saveError } = this.props;

		let refs = this;
		let optionsCompanies = [];
		let optionsPlatforms = [];
		let optionFilterParameters = [];
		let platformsDefault = this.state.platformsDefault;
		let filterParameters = this.state.filterParametersPlatform;
		// select compañias
		if (this.state.allCompanies.length > 0) {
			this.state.allCompanies.map(function(itemCompany) {
				optionsCompanies.push(
					<option key={itemCompany.id} value={itemCompany.company_uuid} defaultValue={itemCompany.name}>
						{itemCompany.name}
					</option>
				);
			});
		}
		// select plataformas
		if (platformsDefault.length > 0) {
			platformsDefault.map(function(itemCompany) {
				optionsPlatforms.push(
					<option
						key={itemCompany.id}
						id={itemCompany.id}
						name={itemCompany.id}
						data-id={itemCompany.id}
						value={itemCompany.name}
						defaultValue={itemCompany.name}
					>
						{itemCompany.name}
					</option>
				);
			});
		}
		if (filterParameters.length > 0) {
			filterParameters.map(function(itemParameter, index) {
				optionFilterParameters.push(
					<ItemDetailPlatform
						key={index}
						label={itemParameter.param_description}
						description={itemParameter.param_value === null ? '' : itemParameter.param_value}
						handleChangeEndpoint={(event) => {
							refs.handleChangeEndpoint(event, index, itemParameter.param_description);
						}}
					/>
				);
			});
		}

		let ref = this;
		let template;
		let templateRequeriment;
		
		return (
			<div>
				<form
					onSubmit={(event) => {
						event.persist();
						this.handleSaveParametersPlatform(event);
					}}
				>
					<div className="admin-parameters_container-session ">
						<div className="admin-parameters__session-header">
							<p className="admin-parameters__session-title">Configuración de la plataforma</p>
							{this.state.showButtonSave && (
								<div>
									<input
										className="btn waves-effect admin-parameters__save-input btn-position-r "
										type="submit"
										value="Guardar"
									/>
								</div>
							)}
							{

							}

							<Modal
								showModal={this.state.showModal}
								loadingSave={loadingSave}
								saveSuccessuful={saveSuccessuful}
								saveError={saveError}
								handleCloseModal={(event) => {
									ref.handleCloseModal(event);
								}}
							/>
						</div>
						<div className="admin-parameters__background">
							<div className=" admin-parameters__container-select">
								<select
									className="browser-default admin-parameters__client-default"
									onChange={(event) => this.handleSelectCompany(event)}
								>
									<option className="" value="" disabled selected>
										Seleccione una empresa
									</option>

									{optionsCompanies}
								</select>
							</div>
						</div>
						<div>
							{this.state.showElemet ? (
								<div className="mt-20">
									<div className="row">
										<div className="col s5">
											<label className="admin-parameters__title-form" htmlFor="">
												Plataforma asignada
											</label>
										</div>
										<div className="col s7">
											<select
												ref={this.select}
												className="browser-default admin-parameters__client-default"
												onChange={(event) => {
													event.persist();
													this.handleSelectPlatform(event);
												}}
											>
												<option name="0" className="" value="" disabled selected>
													Seleccione una plataforma
												</option>
												{optionsPlatforms}
											</select>
										</div>
									</div>

									<ItemDetailPlatform
										label="URL de la plataforma"
										name="platform_endpoint"
										description={this.state.platform_endpoint}
										handleChangeEndpoint={(event) => {
											refs.handleChangeEndpoints(event);
										}}
									/>

									{optionFilterParameters}

									<div>
										<p class="mt-0  text-blue-low ">
											Filtro de estados de tickets a mostrar al usuario
										</p>
										<p>
											En esta sección podrás configurar los estados visibles del ticket para los
											usuarios
										</p>
										{this.createIncident()}
										{this.createRequeriment()}
									</div>
								</div>
							) : (
								<ContainerDefaultPlatform />
							)}
						</div>
					</div>
				</form>
			</div>
		);
	}
}

const mapStateToProps = ({ auth, parameters }) => {
	const { authUser } = auth;
	const {
		listAllCompanies,
		platformsDefault,
		platformsCompany,
		parametersPlatform,
		parametersPlatformStates,
		loadingSave,
		saveSuccessuful,
		saveError
	} = parameters;

	return {
		authUser,
		listAllCompanies,
		platformsDefault,
		platformsCompany,
		parametersPlatform,
		parametersPlatformStates,
		loadingSave,
		saveSuccessuful,
		saveError
	};
};

export default connect(mapStateToProps, {
	getAllCompanies,
	getPlatformsDefault,
	getPlatformsCompany,
	getParametersPlatform,
	putParametersPlatform,
	putEndpointPlatform,
	getPlatformStates,
	putPlatformStates
})(ClientParameters);
