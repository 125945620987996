import React from 'react';
import  './nameperson.css'
class NamePerson extends React.Component{

render(){
    return(
        <p className="name">{ this.props.fullName}</p>
    )
}



}

export default NamePerson;