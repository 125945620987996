import {
	INIT_URL,
	SHOW_ROLES,
	ITEM_SESSION,
	SHOW_ALL_COMPANIES,
	THRESHOLD_COMPANY,
	PARAMETERS_WATSON,
	SHOW_PLATFORMS_DEFAULT,
	SHOW_PLATFORMS_COMPANY,
	PARAMETERS_PLATFORM,
	LOAD_LOADING,
	LOAD_SUCCESS,
	LOAD_ERROR,
	PARAMETERS_STATE_TICKETS,
	PARAMETERS_PSS,
	PARAMETERS_API,
	PARAMETERS_PLATFORM_STATES
} from '../../constants/ActionTypes';

const INIT_STATE = {
	initURL: '',
	roles: [],
	listSession: [],
	listAllCompanies: [],
	listThresholdCompany: [],
	parametersWatson: [],
	platformsDefault: [],
	platformsCompany: [],
	parametersPlatform: [],
	parametersTickets: [],
	parametersPSS: [],
	loadingSave: false,
	saveSuccessuful: false,
	parametersApi: [],
	parametersPlatformStates: []
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case INIT_URL: {
			return { ...state, initURL: action.payload };
		}
		case SHOW_ROLES: {
			return {
				...state,
				roles: action.payload
			};
		}

		case SHOW_PLATFORMS_DEFAULT: {
			return {
				...state,
				platformsDefault: action.payload
			};
		}
		case SHOW_PLATFORMS_COMPANY: {
			return {
				...state,
				platformsCompany: action.payload
			};
		}

		case ITEM_SESSION: {
			return {
				...state,
				listSession: action.payload
			};
		}
		case SHOW_ALL_COMPANIES: {
			return {
				...state,
				listAllCompanies: action.payload
			};
		}
		case THRESHOLD_COMPANY: {
			return {
				...state,
				listThresholdCompany: action.payload
			};
		}
		case PARAMETERS_WATSON: {
			return {
				...state,
				parametersWatson: action.payload
			};
		}
		case PARAMETERS_PLATFORM: {
			return {
				...state,
				parametersPlatform: action.payload
			};
		}
		case PARAMETERS_STATE_TICKETS: {
			return {
				...state,
				parametersTickets: action.payload
			};
		}
		case PARAMETERS_PSS: {
			return {
				...state,
				parametersPSS: action.payload
			};
		}
		/**api */
		case PARAMETERS_API: {
			return {
				...state,
				parametersApi: action.payload
			};
		}
		case PARAMETERS_PLATFORM_STATES: {
			return {
				...state,
				parametersPlatformStates: action.payload
			};
		}
		case LOAD_LOADING: {
			return {
				...state,
				loadingSave: true,
				saveSuccessuful: false,
				saveError: false
			};
		}
		case LOAD_SUCCESS: {
			return {
				...state,
				loadingSave: false,
				saveSuccessuful: true,
				saveError: false
			};
		}
		case LOAD_ERROR: {
			return {
				...state,
				loadingSave: false,
				saveSuccessuful: false,
				saveError: true
			};
		}
		default:
			return state;
	}
};
