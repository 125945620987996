import React from 'react';
import MessageAgent from '../MessageAgent/MessageAgent';
import { connect } from 'react-redux';
import FileImage from '../../modulo-chat/FileImage/FileImage';
import FileDocs from '../../modulo-chat/FileDocs/FileDocs';
import { KEYWORDS } from '../../../constants/KeywordsButtons';
import { historialConversation } from '../../../appRedux/actions/Conversation';
import './messageContainer.css';
import 'moment/locale/es';
import moment from 'moment';
import axios from 'util/Api';

let curScrollPos;
let oldScroll;
let isBusy = false;
let shouldKeepScroll = false;

async function download(idFile,company_id) {
	const res = await axios.get('companies/' + company_id + '/files/' + idFile);
	return res;
}

async function getUrlFromFileId(file_id,company_id){
  let downloadResponsePure = await download(file_id,company_id);
  let downloadResponse = downloadResponsePure.data;
  let url = "";
  if(downloadResponse.payload){
    url = downloadResponse.payload.file_url
  }
  return url;
}

class MessageContainer extends React.Component {
	constructor() {
		super();
		this.bodyChat = React.createRef();
		this.bodyChats = React.createRef();
		this.getClass = this.getClass.bind(this);
	}

	scrollToBottom = () => {
		const items = this.bodyChat.childNodes;
		this.bodyChat.scrollTop = this.bodyChat.scrollHeight;
	};
	componentWillMount() {}

	componentDidMount() {
		this.bodyChat.scrollTop = this.bodyChat.scrollHeight;

		curScrollPos = this.bodyChat.scrollTop;
		oldScroll = this.bodyChat.scrollHeight - this.bodyChat.clientHeight;

		if (this.bodyChat.clientHeight >= this.bodyChat.scrollHeight) {
			const { historialList } = this.props;
			this.props.historialConversation(historialList);
		} else {

		}
	}
	componentWillReceiveProps() {}

	componentDidUpdate() {
		if (shouldKeepScroll) {
			let newScroll = this.bodyChat.scrollHeight - this.bodyChat.clientHeight;
			this.bodyChat.scrollTop = curScrollPos + (newScroll - oldScroll);
		} else {
			this.bodyChat.scrollTop = this.bodyChat.scrollHeight;
		}
	}
	componentWillUnmount() {}

	isButtonValue(s) {
		let splitValues = s.split('_');
		let flag = false;
		if (splitValues.length > 1) {
			for (let i = 0; i < splitValues.length; i++) {
				if (KEYWORDS.indexOf(splitValues[i]) > -1) {
					flag = true;
				}
			}
		}
		return flag;
	}

	capitalize(s) {
		if (typeof s !== 'string') return '';
		return s.charAt(0).toUpperCase() + s.slice(1);
	}

	formatButtonValue(s) {
		let splitValues = s.split('_');
		let resultText = '';
		for (let i = 0; i < splitValues.length; i++) {
			if (KEYWORDS.indexOf(splitValues[i]) < 0) {
				resultText = resultText + splitValues[i] + ' ';
			}
		}

		return this.capitalize(resultText);
	}

	getClass(userType) {
		let className = '';
		switch (userType) {
			case 'END-USER': {
				className = 'in';
				break;
			}
			case 'BOT': {
				className = 'BOT';
				break;
			}
			case 'ANALYST': {
				className = 'out';
				break;
			}
			default:
				className = userType;
		}

		return className;
	}

	async handleScroll(e) {
		if (document.getElementById('body-message').scrollTop === 0 && !isBusy) {
			const { historialList } = this.props;

			shouldKeepScroll = true;
			curScrollPos = this.bodyChat.scrollTop;
			oldScroll = this.bodyChat.scrollHeight - this.bodyChat.clientHeight;
			isBusy = true;
			await this.props.historialConversation(historialList);
			isBusy = false;

			shouldKeepScroll = false;
		}

		var dif = this.bodyChat.scrollHeight - this.bodyChat.scrollTop;
		if (dif - this.bodyChat.clientHeight > this.bodyChat.clientHeight) {
			document.getElementById('chatbot-scrolldown').classList.remove('hide-buttodown');
		} else {
			document.getElementById('chatbot-scrolldown').classList.add('hide-buttodown');
		}
	}

	scrollButton(e) {
		this.bodyChat.scrollTop = this.bodyChat.scrollHeight - this.bodyChat.clientHeight;
		document.getElementById('chatbot-scrolldown').classList.add('hide-buttodown');
	}

	render() {

		let messageContainer = [];
		const { messages } = this.props;
		let imageExtensions = [ 'jpg', 'png', 'ico', 'gif' ];
		const { containerInfo, historialList } = this.props;

		let childrensss = [];
		let variableLoading;
		let lastItemHistorials = historialList;

		if (this.props.loadingHistorial) {
			variableLoading = (
				<div>
					<div id="loading" className="loading hist">
						Loading & #8230;
					</div>
				</div>
			);
		}
		lastItemHistorials.map(function(item, i) {
			if (item.messages) {
				for (var items = item.messages.length - 1; items >= 0; items--) {
					let typeUser = item.messages[items].user_type;
					if (item.messages.includes[containerInfo.user.user_id]) {
					}
					//not only text "ACCEPT-REQUEST" "CAROUSEL" "QUICK-REPLIES"
					if (
						item.messages[items].message_type === 'TEXT' ||
						item.messages[items].message_type === 'ACCEPT-REQUEST'
					) {
						let ref = this;
						let className;

						switch (typeUser) {
							case 'END-USER': {
								className = 'in';
								break;
							}
							case 'BOT': {
								className = 'out';
								break;
							}
							case 'ANALYST': {
								className = 'out';
								break;
							}
							default:
								className = typeUser;
						}

						childrensss.unshift(
							<MessageAgent
								key={item.messages[items].id}
								message={item.messages[items].message_text}
								createdAt={item.messages[items].created_at}
								status={item.messages[items].status}
								user_type={item.messages[items].user_type}
								id={item.messages[items].id}
								message_type={item.messages[items].message_type}
								file_name={item.messages[items].file_name}
								direction={typeUser}
							/>
						);
					}
					// only file
					if (item.messages[items].message_type === 'FILE' || item.messages[items].message_type === 'file') {
						if (item.messages[items].file_name !== null) {
							let extensions = item.messages[items].file_name.split('.');
							let extension = extensions.pop().toLowerCase();
							if (imageExtensions.includes(extension)) {
								childrensss.unshift(
									<FileImage
										key={item.messages[items].id}
										file_url={item.messages[items].file_url}
										//file_url={url}
										message_id={item.messages[items].id}
										message={item.messages[items].message_text}
										createdAt={item.messages[items].created_at}
										status={item.messages[items].status}
										user_type={item.messages[items].user_type}
										id={item.messages[items].id}
										message_type={item.messages[items].message_type}
										file_name={item.messages[items].file_name}
										direction={typeUser}
									/>
								);
							} else {
								
								childrensss.unshift(
									<FileDocs
										key={item.messages[items].id}
										file_url={item.messages[items].file_url}
										//file_url={url}
										file_id={item.messages[items].file_id}
										message_id={item.messages[items].id}
										message={item.messages[items].message_text}
										createdAt={item.messages[items].created_at}
										status={item.messages[items].status}
										extension={extension}
										user_type={item.messages[items].user_type}
										id={item.messages[items].id}
										message_type={item.messages[items].message_type}
										file_name={item.messages[items].file_name}
										direction={typeUser}
									/>
								);
							}
						}
					}
				}
				let dateSpanish;
				if (item.messages[0]) {
					dateSpanish = moment(item.messages[0].created_at).locale('es');
					childrensss.unshift(
						<div>
							<p className="center-align date-hist"> {moment(dateSpanish).format('LL')} </p>{' '}
						</div>
					);
				} else {
					dateSpanish = '---';
				}
			}
		});

		let messagesFilter = messages.filter((item) => item.conversation_id === containerInfo.conversation_id);

		let messagesOrder = messagesFilter.sort(function(a, b) {
			return a.id - b.id;
		});


		messagesOrder.forEach( (message) => {
			if (message.message_type === 'TEXT') {
				if (this.isButtonValue(message.message_text)) {
					message.message_text = this.formatButtonValue(message.message_text);
				}

				messageContainer.push(
					<MessageAgent
						key={message.id}
						message={message.message_text}
						createdAt={message.created_at}
						status={message.status}
						user_type={message.user_type}
						id={message.id}
						message_type={message.message_type}
						file_name={message.file_name}
						direction={this.getClass(message.user_type)}
					/>
				);
			}
			if (message.message_type === 'FILE' || message.message_type === 'file') {
				if (message.file_name !== null) {
					let extensions = message.file_name.split('.');
					let extension = extensions.pop().toLowerCase();
					if (imageExtensions.includes(extension)){

						messageContainer.push(
							<FileImage
								key={message.id}
								file_url={message.file_url}
								//file_url={url}
								message_id={message.id}
								message={message.message_text}
								createdAt={message.created_at}
								status={message.status}
								user_type={message.user_type}
								id={message.id}
								message_type={message.message_type}
								file_name={message.file_name}
								direction={this.getClass(message.user_type)}
								allMessages={messagesFilter}
							/>
						);
					}else{

						
						messageContainer.push(
							<FileDocs
								key={message.id}
								file_url={message.file_url}
								//file_url={url}
								file_id={message.file_id}
								message_id={message.id}
								extension={extension.toLowerCase()}
								message_id={message.id}
								message={message.message_text}
								createdAt={message.created_at}
								status={message.status}
								user_type={message.user_type}
								id={message.id}
								message_type={message.message_type}
								file_name={message.file_name}
								direction={this.getClass(message.user_type)}
							/>
						);
					}
				}
			}
		});

		return (
			<div
				id="body-message"
				className="body-chat"
				ref={(el) => {
					this.bodyChat = el;
				}}
				onScroll={(e) => {
					this.handleScroll(e);
				}}
			>
				{' '}
				{
					<div id="hist-scroll" className="container-scroll">
						{' '}
						{variableLoading}
						{childrensss}{' '}
					</div>
				}{' '}
				<div className="div-after">
					<img
						src="https://canvia.service-now.com/ohmega-ic-avatar-agent.png"
						className="responsive-img img-agent-separator"
					/>
				</div>
				{messageContainer}
				<img
					onClick={(e) => {
						this.scrollButton(e);
					}}
					id="chatbot-scrolldown"
					className="responsive-img chatbot-scrolldown hide-buttodown"
					src="https://canvia.service-now.com/ohmega-ic-arrow-down.png"
					alt="btn-down"
				/>
			</div>
		);
	}
}

const mapStateToProps = ({ chat, conversation }) => {
	const { messages } = chat;
	const { containerInfo, historialList, loadingHistorial } = conversation;
	return {
		messages,
		containerInfo,
		historialList,
		loadingHistorial
	};
};

export default connect(mapStateToProps, {
	withRef: true,
	historialConversation
})(MessageContainer);
