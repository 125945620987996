import React, { Component } from "react";
import { connect } from "react-redux";
import HorizontalNav from "../HorizontalNav/HorizontalNav";
import ContainerList from "../../components/modulo-chat/ContainerList/ContainerList";
import ContainerReport from "../../components/container/ContainerReport/ContainerReport";
import ContainerWelcome from "../../components/container/ContainerWelcome/ContainerWelcome";
import ContainerWork from "../../components/dashboard/ContainerWork/ContainerWork";
import ContainerBlank from "../../components/container/ContainerBlank/ContainerBlank";
import Container404 from "../../components/container/Container404/Container404";
import ContainerChat from "../../components/modulo-chat/ContainerChat/ContainerChat";
import ContainerQuickReplies from "../../components/admin-quick-replies/ContainerQuickReplies/ContainerQuickReplies";
import ContainerUserConfiguration from "../../components/user-configuration/ContainerUserConfiguration/ContainerUserConfiguration";
import NotFound from "../../components/dashboard/ContainerWork/ContainerWork";
import ContainerParameters from "../../components/parameters-configuration/ContainerParameters/ContainerParameters";
/* F.G.H - Estado de analistas nuevo - 22-02-22 */
import ContainerAnalystStatus from "../../components/analyst-status/AnalystStatus";
import { userMenu } from "../../appRedux/actions/Auth";
import MenuLateral from "../../components/MenuLateral/MenuLateral";
import { BrowserRouter, Link } from "react-router-dom";
import { Redirect, Route, Switch } from "react-router-dom";

/*import {QUICKREPLY,AUTOGREETING,WELCOMEGREETING}from '../../constants/Constants'*/

import "./main.css";
import createBrowserHistory from "history/createBrowserHistory";
import Reportes from "../../components/Reportes/Reportes";

/*import IntlMessages from "util/IntlMessages";*/

const history = createBrowserHistory();
export class MainApp extends Component {
  constructor(props) {
    super(props);
    //let ref = this;
    //ref.socketMessage = ref.socketMessage.bind(this)
    // notificaciones no son soportadas en ie11
    var es_chrome =
      navigator.userAgent.toLocaleLowerCase().indexOf("chrome") > -1;
    if (es_chrome) {
      // notificaciones
      if (Notification.permission !== "denied") {
        Notification.requestPermission(function(permission) {});
      }
    }
  }
  componentDidMount() {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", function(event) {
      window.history.pushState(null, document.title, window.location.href);
    });
  }

  componentWillMount() {
    const { authUser } = this.props;
    this.props.userMenu(authUser.id);
  }

  render() {
    const { showContainer } = this.props;

    const containter = showContainer ? <ContainerChat /> : <ContainerBlank />;
    return (
      <div>
        <MenuLateral />
        <div className=" pt-0 ">
          <HorizontalNav
            offSound={this.props.offSound}
            notificationSound={this.notificationSound}
            notificationStop={this.notificationStop}
            notificationStart={this.notificationStart}
          />
        </div>
        <div className="row">
          <div>
            <Switch>
              <Route path="/main" render={() => <ContainerWelcome />} />
              <Route
                path="/CHAT"
                render={() => (
                  <div>
                    <div className="col s12 m4 l3 ">
                      <ContainerList />
                    </div>
                    <div className="col s12 m8 l9 ">{containter}</div>
                  </div>
                )}
              />
              <Route path="/REPORTS" render={() => <Reportes />} />
              <Route path="/DASHBOARD" render={() => <ContainerWork />} />
              <Route path="/ANALYST_STATUS" render={() => <ContainerAnalystStatus/>} />
              <Route
                path="/REPLIES-SETTINGS"
                render={() => <ContainerQuickReplies />}
              />
              <Route
                path="/USERS-SETTINGS"
                render={() => <ContainerUserConfiguration />}
              />
              <Route
                path="/COMPANIES-SETTINGS"
                render={() => <ContainerParameters />}
              />
              <Route path="*" render={() => <Container404 />} />
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth, conversation }) => {
  const { authUser, permissions } = auth;
  const { showContainer } = conversation;

  return { authUser, showContainer, permissions };
};
export default connect(mapStateToProps, {
  userMenu
})(MainApp);
