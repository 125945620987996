import React from 'react';
import './containerDetailsReplies.css';
import { connect } from 'react-redux';
import MessageReplie from '../MessageReplie/MessageReplie';
import { ALL } from '../../../constants/Constants';
import Addmessage from '../Addmessage/Addmessage';
import {
	showContainerReplies,
	getQuickReplies,
	showElementMessage,
	showOtherElementMessage
} from '../../../appRedux/actions/Companies';

class ContainerDetailsReplies extends React.Component {
	constructor(props) {
		super(props);
	}

	componentWillMount() {
		let ref = this;

		ref.props.getQuickReplies(ALL);
	}

	showElementMessage = (e, item) => {
		this.props.showElementMessage(e, item);
	};

	addGreetingReplies = (e, item) => {
		this.props.showElementMessage(e, item);
	};
	addWaitingReplies = (e, item) => {
		this.props.showElementMessage(e, item);
	};
	addFarewellgReplies = (e) => {
		console.log('third button');
	};

	render() {
		const {
			repliesWelcome,
			allReplies,
			autoGreetingsReplies,
			showElement,
			otherShowElement,
			quickRepliesGreeting,
			containerAdminReplies
		} = this.props;

		let nameCompany = containerAdminReplies.name;
		let welcomeRepliesList = [];
		let autoGreetingList = [];
		let greetingsReplies = [];
		let waitingReplies = [];
		let farewellReplies = [];
		let prueba = [];
		let containerWelcomeReplies = [];
		let containerAutogreeting = [];
		let containerWaiting = [];
		let containerFarewell = [];

		/*only welcome */
		if (repliesWelcome !== undefined) {
			repliesWelcome.forEach((reply) => {
				if (reply.company_id === containerAdminReplies.company_uuid) {
					containerWelcomeReplies.push(
						<div>
							<MessageReplie
								reply={reply}
								repliesWelcome= {repliesWelcome}
								autoGreetingsReplies={autoGreetingsReplies}
								allReplies={allReplies}
								value={reply.value}
								company_id={reply.company_id}
								id={reply.id}
								category_name={reply.category_name}
							/>
						</div>
					);
				}
			});
		}
		if (autoGreetingsReplies !== undefined) {
			autoGreetingsReplies.forEach((reply) => {
				if (reply.company_id === containerAdminReplies.company_uuid) {
					containerAutogreeting.push(
						<div>
							<MessageReplie
								reply={reply}
								repliesWelcome = {repliesWelcome}
								autoGreetingsReplies={autoGreetingsReplies}
								allReplies={allReplies}
								value={reply.value}
								company_id={reply.company_id}
								id={reply.id}
								category_name={reply.category_name}
							/>
						</div>
					);
				}
			});
		}
		if (allReplies !== undefined) {
			allReplies.forEach((reply) => {
				if (reply.company_id === containerAdminReplies.company_uuid) {
					if (reply.category_id === 1) {
						prueba.push(
							<div>
								<MessageReplie
									reply={reply}
									repliesWelcome = {repliesWelcome}
									autoGreetingsReplies={autoGreetingsReplies}
									allReplies={allReplies}
									value={reply.value}
									company_id={reply.company_id}
									id={reply.id}
									category_name={reply.category_name}
								/>
							</div>
						);
					} else if (reply.category_id === 2) {
						// waitingReplies
						containerWaiting.push(
							<MessageReplie
								reply={reply}
								repliesWelcome = {repliesWelcome}
								autoGreetingsReplies={autoGreetingsReplies}
								allReplies={allReplies}
								value={reply.value}
								company_id={reply.company_id}
								id={reply.id}
								category_name={reply.category_name}
							/>
						);
					} else if (reply.category_id === 3) {
						//	farewellReplies
						containerFarewell.push(
							<MessageReplie
								reply={reply}
								repliesWelcome = {repliesWelcome}
								autoGreetingsReplies={autoGreetingsReplies}
								allReplies={allReplies}
								value={reply.value}
								company_id={reply.company_id}
								id={reply.id}
								category_name={reply.category_name}
							/>
						);
					}
				}
			});

			welcomeRepliesList.push(
				<div className="admin-replies__bordered_bottom">
					{containerWelcomeReplies}
					<Addmessage
						key={1}
						itemMessage={5}
						showElement={showElement}
						count={1}
						allReplies={allReplies}
						repliesWelcome = {repliesWelcome}
						autoGreetingsReplies={autoGreetingsReplies}
						quickRepliesGreeting={quickRepliesGreeting}
					/>
				</div>
			);

			autoGreetingList.push(
				<div className="admin-replies__bordered_bottom">
					{containerAutogreeting}
					<Addmessage
						key={1}
						itemMessage={1}
						showElement={showElement}
						count={1}
						allReplies={allReplies}
						repliesWelcome = {repliesWelcome}
						autoGreetingsReplies={autoGreetingsReplies}
						quickRepliesGreeting={quickRepliesGreeting}
					/>
				</div>
			);
			greetingsReplies.push(
				<div className="admin-replies__bordered_bottom">
					{prueba}
					<Addmessage
						itemMessage={2}
						showElement={showElement}
						count={2}
						allReplies={allReplies}
						repliesWelcome = {repliesWelcome}
						autoGreetingsReplies={autoGreetingsReplies}
						quickRepliesGreeting={quickRepliesGreeting}
					/>
				</div>
			);
			waitingReplies.push(
				<div className="admin-replies__bordered_bottom">
					{containerWaiting}
					<Addmessage
						itemMessage={3}
						showElement={otherShowElement}
						count={3}
						allReplies={allReplies}
						repliesWelcome = {repliesWelcome}
						autoGreetingsReplies={autoGreetingsReplies}
						quickRepliesGreeting={quickRepliesGreeting}
					/>
				</div>
			);
			farewellReplies.push(
				<div>
					{containerFarewell}
					<Addmessage
						itemMessage={4}
						showElement={false}
						count={4}
						quickRepliesGreeting={quickRepliesGreeting}
						allReplies={allReplies}
						repliesWelcome = {repliesWelcome}
						autoGreetingsReplies={autoGreetingsReplies}
					/>
				</div>
			);
		}
		/**add message */

		return (
			<div className="container-replies-detail">
				<div className="admin-replies__company">
					<p>{nameCompany}</p>

				</div>
				<div>
					<p className="admin-replies__subtitle">Mensaje Bienvenida</p>
					<p className="admin-replies__text">
						En esta sección podrás configurar el mensaje de bienvenida automático del N1 al usuario
					</p>
					{welcomeRepliesList}
				</div>
				<div>
					<p className="admin-replies__subtitle">Mensaje Automático</p>
					<p className="admin-replies__text">
						En esta sección podrás configurar el mensaje de saludo inicial automático del N1 al usuario
					</p>
					{autoGreetingList}
				</div>
				<div>
					<p className="admin-replies__subtitle">Respuestas rápidas de N1</p>
					<p className="admin-replies__text">
						En esta sección podrás configurar las respuestas rápidas que los especialistas de nivel 1
						enviarán a los usuarios
					</p>
					<div>
						<p className="admin-replies__subtitle">Saludo</p>
						{greetingsReplies}
					</div>
					<div>
						<p className="admin-replies__subtitle">Mensaje en espera</p>
						{waitingReplies}
					</div>
					<div>
						<p className="admin-replies__subtitle">Mensaje de despedida</p>
						{farewellReplies}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = ({ conversation, auth, chat, companies }) => {
	const { containerInfo } = conversation;
	const { authUser } = auth;
	const {
		repliesWelcome,
		allReplies,
		autoGreetingsReplies,
		quickRepliesGreeting,
		showElement,
		otherShowElement,
		containerAdminReplies
	} = companies;
	return {
		containerInfo,
		authUser,
		repliesWelcome,
		allReplies,
		autoGreetingsReplies,
		showElement,
		otherShowElement,
		quickRepliesGreeting,
		containerAdminReplies
	};
};

export default connect(mapStateToProps, {
	showContainerReplies,
	getQuickReplies,
	showElementMessage,
	showOtherElementMessage
})(ContainerDetailsReplies);
