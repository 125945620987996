import {
	INIT_URL,
	FETCH_START,
	MANAGER,
	FETCH_ERROR,
	FETCH_SUCCESS,
	ASSIGNED_COMPANIES,
	SHOW_DETAILS_REPLIES,
	ADMIN_QUICK_REPLIES,
	ADMIN_AUTO_GREETING,
	SHOW_ELEMENT,
	REMOVE_REPLY,
	SHOW_OTHER_ELEMENT,
	QUICK_REPLY_GREETING,
	ADD_QUICK_REPLIES,
	ADD_AUTO_GREETING,
	ADD_REPLY_WELCOME,
	SHOW_ADMIN_REPLIES,
	SET_INPUT,
	CLEAN_HISTORIAL,
	SHOW_THRESHOLDS_MANAGER,
	ALL_COMPANIES,
	REPLIES_WELCOME
} from '../../constants/ActionTypes';
import { QUICKREPLY, WELCOMEGREETING, AUTOGREETING, GREETING, WAITING, FAREWELL } from '../../constants/Constants';

import axios from 'util/Api';
import { logout } from '../actions/Chat';
import { envioGlobalError } from '../../helpers/ErrorHelper';

export const setInitUrl = (url) => {
	return {
		type: INIT_URL,
		payload: url
	};
};
export const getAssignedCompanies = (user_id) => {
	return (dispatch) => {
		dispatch({
			type: FETCH_START
		});
		axios
			.get('users/' + user_id + '/companies?role=' + MANAGER)
			.then(({ data }) => {
				if (data.success) {
					dispatch({
						type: FETCH_SUCCESS
					});
					dispatch({
						type: ASSIGNED_COMPANIES,
						payload: data.payload
					});
				} else {
					dispatch({
						type: FETCH_ERROR,
						payload: data.error
					});
				}
			})
			.catch(function(error) {
				dispatch({
					type: FETCH_ERROR,
					payload: error.message
				});
				// ===============================
				// captura de error
				// ===============================
				let functionName = 'getAssignedCompaniesName';
				let capturaErr = error;
				envioGlobalError(capturaErr, functionName);
				// ===============================

				if (error.response !== undefined && error.response.status === 401) {
					dispatch(logout());
				}
			});
	};
};

//  ?
function getQuickRepliesSync(company_id) {
	return axios.get('companies/' + company_id + '/replies/All');
}
export const getQuickReplies = (company_id) => {
	return async (dispatch, getState) => {
		dispatch({
			type: FETCH_START
		});
		const { authUser } = getState().auth;
		let roles = authUser.roles;
		let allReplies_total = [];
		let quick_reply_total = [];
		let welcome_greeting_total = [];
		let auto_greeting_total = [];
		let quick_reply_greeting_total = [];
		let quick_reply_waiting_total = [];

		const promises = roles.filter((r) => r.name === MANAGER).map(async function(element) {
			let company_id = element.company_id;

			let parameters = await getQuickRepliesSync(company_id);

			let allReplies = parameters.data.payload;
			let welcome_greeting = parameters.data.payload.filter((item) => item.parameter_name === WELCOMEGREETING);
			let auto_greeting = parameters.data.payload.filter((item) => item.parameter_name === AUTOGREETING);
			/*type of quick_replies */
			let quick_reply = parameters.data.payload.filter((item) => item.parameter_name === QUICKREPLY);
			let quick_reply_greetings = quick_reply.filter((reply) => reply.category_name === GREETING);
			let quick_reply_waiting = quick_reply.filter((reply) => reply.category_name === WAITING);
			/* */
			let replies_concat = allReplies_total.concat(allReplies);
			allReplies_total = replies_concat;

			let welcome_greeting_concat = welcome_greeting_total.concat(welcome_greeting);
			welcome_greeting_total = welcome_greeting_concat;

			let auto_greeting_concat = auto_greeting_total.concat(auto_greeting);
			auto_greeting_total = auto_greeting_concat;
			/*only quick_replies */
			let quick_replay_concat = quick_reply_total.concat(quick_reply);
			quick_reply_total = quick_replay_concat;

			let quick_reply_greeting_concat = quick_reply_greeting_total.concat(quick_reply_greetings);
			quick_reply_greeting_total = quick_reply_greeting_concat;

			let quick_reply_waiting_concat = quick_reply_waiting_total.concat(quick_reply_waiting);
			quick_reply_waiting_total = quick_reply_waiting_concat;
		});

		await Promise.all(promises);

		dispatch({
			type: ADMIN_QUICK_REPLIES,
			payload: quick_reply_total
		});
		dispatch({
			type: ADMIN_AUTO_GREETING,
			payload: auto_greeting_total
		});
		dispatch({
			type: QUICK_REPLY_GREETING,
			payload: quick_reply_greeting_total
		});
		dispatch({
			type: REPLIES_WELCOME,
			payload: welcome_greeting_total
		});
	};
};

export const deleteQuickReply = (company_id, reply_id) => {
	return (dispatch) => {
		axios
			.delete('companies/' + company_id + '/replies/' + reply_id)
			.then(({ data }) => {
				if (data.success) {
					dispatch({
						type: REMOVE_REPLY,
						payload: reply_id
					});
				}
			})
			.catch(function(error) {
				dispatch({
					type: FETCH_ERROR,
					payload: error.message
				});

				if (error.response !== undefined && error.response.status === 401) {
					dispatch(logout());
				}
				// ===============================
				// captura de error
				// ===============================
				let functionName = 'deleteQuickReplyName';
				let capturaErr = error;
				envioGlobalError(capturaErr, functionName);
				// ===============================
			});
	};
};

export const createQuickReply = (company_id, reply) => {
	return (dispatch) => {
		dispatch({
			type: FETCH_START
		});
		axios
			.post('companies/' + company_id + '/replies', reply)
			.then(({ data }) => {
				if (data.success) {
					if (data.payload.parameter_name === 'WelcomeGreeting') {
						dispatch({
							type: ADD_REPLY_WELCOME,
							payload: data.payload
						});
					} else if (data.payload.parameter_name === 'AutoGreeting') {
						dispatch({
							type: ADD_AUTO_GREETING,
							payload: data.payload
						});
					} else if (data.payload.parameter_name === 'QuickReply') {
						dispatch({
							type: ADD_QUICK_REPLIES,
							payload: data.payload
						});
					}
				}
			})
			.catch(function(error) {
				dispatch({
					type: FETCH_ERROR,
					payload: error.message
				});
				// ===============================
				// captura de error
				// ===============================
				let functionName = ' createQuickReplyName';
				let capturaErr = error;
				envioGlobalError(capturaErr, functionName);
				// ===============================
				if (error.response !== undefined && error.response.status === 401) {
					dispatch(logout());
				}
			});
	};
};
export const showElementMessage = (e, item) => {
	return (dispatch) => {
		dispatch({
			type: SHOW_ELEMENT,
			payload: item
		});
	};
};
export const showOtherElementMessage = (e, item) => {
	return (dispatch) => {
		dispatch({
			type: SHOW_OTHER_ELEMENT,
			payload: item
		});
	};
};

export const showContainerReplies = (parameters) => {
	return (dispatch) => {
		dispatch({ type: SHOW_DETAILS_REPLIES });
	};
};

export const showAdminReplies = (parameters) => {

	return (dispatch) => {
		dispatch({
			type: CLEAN_HISTORIAL,
			payload: []
		});
		dispatch({
			type: SHOW_ADMIN_REPLIES,
			payload: parameters
		});
		dispatch({
			type: SET_INPUT,
			payload: ''
		});
	};
};

function getThresholdsManagerSync(company_id) {
	return axios.get('companies/' + company_id + '/thresholds');
}

export const getThresholdsManager = (company_id) => {
	return async (dispatch, getState) => {
		dispatch({
			type: FETCH_START
		});
		const { authUser } = getState().auth;
		let roles = authUser.roles;
		let rolesManager = roles.filter((ur) => ur.name === MANAGER);
		let thresholdsManager_total = [];
		const promises = rolesManager.map(async function(element) {
			let company_id = element.company_id;
			let thresholdsManager = await getThresholdsManagerSync(company_id);
			let thresholdsManagerList = thresholdsManager.data.payload;
			let thresholdsManager_concat = thresholdsManager_total.concat(thresholdsManagerList);
			});
		await Promise.all(promises);
		dispatch({
			type: SHOW_THRESHOLDS_MANAGER,
			payload: thresholdsManager_total
		});
	};
};

export const getCompanies = (user_id) => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		axios
			.get('companies')
			.then(({ data }) => {
				if (data.success) {
					dispatch({
						type: FETCH_SUCCESS
					});
					dispatch({
						type: ALL_COMPANIES,
						payload: data.payload
					});
				} else {
					dispatch({
						type: FETCH_ERROR,
						payload: data.error
					});
				}
			})
			.catch(function(error) {
				dispatch({
					type: FETCH_ERROR,
					payload: error.message
				});
				// ===============================
				// captura de error
				// ===============================
				let functionName = 'getCompaniesName';
				let capturaErr = error;
				envioGlobalError(capturaErr, functionName);
				// ===============================
			});
	};
};
