import React from 'react';
import { connect } from 'react-redux';

import './configurationApi.css';
import {
	getAllCompanies,
	getParametersApi,
	getPlatformsCompany,
	putParametersApi
} from '../../../../appRedux/actions/Parameters';

import ContainerDefaultPlatform from '../../ContainerDefaultPlatform/ContainerDefaultPlatform';

import ItemParametersApi from '../ItemParametersApi/ItemParametersApi';
import Modal from '../../Modal/Modal';

class ConfigurationApi extends React.Component {
	constructor(props) {
		super(props);
		const { parametersWatson } = this.props;
		this.state = {
			listCompanies: [],
			showResults: false,
			showButtonSave: false,
			showModal: false,
			parametersApi: [],
			allList: [],
			title: '',
			optionsParameters: [],
			optionsParametersSecond: [],
			key: '',
			value: [],
			valueCompany: '',
			newParameters: [],
			labelFirst: '',
			labelSection: '',
			platform_id: ''
		};
	}

	async componentWillMount() {
		await this.props.getAllCompanies();
		const { listAllCompanies, value } = this.props;
		let initialCompanies = [];
		initialCompanies = listAllCompanies.map((company) => {
			return company;
		});

		this.setState({
			listCompanies: initialCompanies,
			value: value
		});
	}
	componentWillReceiveProps(newProps) {
		this.setState({
			value: newProps.value
		});
	}
	async handleChangeParent(indexl, el, params, label) {

		let value;
		let parametersApi = { ...this.state.parametersApi };
		
		Object.keys(parametersApi).forEach((key, index) => {
			
			let newValue;
			if (key === label) {
				parametersApi[key] = params;

			}
		});

		
		await this.setState({
			parametersApi: parametersApi,
			labelFirst: label
		});
	}

	//change company
	async addSection(index, valores, labelSection) {
		await this.setState({
			/*value: valores,*/
			labelSection: labelSection
		});
		let parametersApi = { ...this.state.parametersApi };
		Object.keys(parametersApi).forEach((key, index) => {
			
			if (key === this.state.labelSection) {
				parametersApi[key] = valores;

			}
		});
		await this.setState({
			parametersApi: parametersApi
		});
	}

	async handleChangeCompany(event) {
		let valueCompany = event.target.value;
		await this.props.getParametersApi(valueCompany);
		await this.props.getPlatformsCompany(valueCompany);
		const { parametersApi, platformsCompany } = this.props;
		
		this.setState({
			showButtonSave: true,
			showResults: true,
			parametersApi: parametersApi,
			value: [],
			optionsParameters: [],
			platform_id: platformsCompany.platform_id
		});
		let firstItem;
		let newObject = {};
		let optionsParameters = [];
		let optionsParametersSecond = [];
		let ref = this;
		let result = this.state.parametersApi;
		ref.setState({
			/*optionsParameters: optionsParameters,*/
			valueCompany: valueCompany
		});
	}

	
	//

	//////change input

	handleCloseModal() {
		this.setState({
			showModal: false
		});
	}
	deleteParent(index, valores, label) {
		let parametersApi = { ...this.state.parametersApi };
		Object.keys(parametersApi).forEach((key, index) => {
			//value = parametersApi[key];
			if (key === label) {
				parametersApi[key] = valores;
			}
		});
		this.setState({
			parametersApi: parametersApi
		});
	}
	//save
	async handleSaveParemetersApi(event) {
		const { putParametersApi } = this.props;
		event.preventDefault();
		this.setState({
			showModal: true
		});
		//let parametersApi = { ...this.state.parametersApi };
		//let value;

		let groupData = [];
		let refs = this;
		let listParameters = this.state.parametersApi;
		Object.keys(listParameters).forEach((key, index) => {
			let newValue = listParameters[key];
			let dataItem = {};
			//let platformId = refs.state.
			let ref = refs;
			newValue.map(function(el, i) {
	
				if (
					(el.param_label === '' && el.param_value === '') ||
					(el.param_label === null && el.param_value === null)
				) {
				} else {
					dataItem = {
						param_id: el.param_id,
						label: el.param_label,
						value: el.param_value,
						platform_id: ref.state.platform_id
					};
					groupData.push(dataItem);
				}
			});
				});

			await this.props.putParametersApi(this.state.valueCompany, groupData);

	}

	render() {
		const { loadingSave, saveSuccessuful, saveError } = this.props;
		let optionsCompanies = [];
		let optionsWatson = [];
		if (this.state.listCompanies.length > 0) {
			this.state.listCompanies.map(function(itemCompany) {
				optionsCompanies.push(
					<option key={itemCompany.id} value={itemCompany.company_uuid} defaultValue={itemCompany.name}>
						{itemCompany.name}
					</option>
				);
			});
		}
		let optionsParameters = [];
		if (Object.keys(this.state.parametersApi).length > 0) {
			Object.keys(this.state.parametersApi).forEach((key, index) => {
				let value;
				let keys;

				value = this.state.value;
				keys = this.state.key;
				let paramId;
				this.state.parametersApi[key].map(function(id) {
					paramId = id.param_id;
				});
				optionsParameters.push(
					<ItemParametersApi
						key={index}
						label={key}
						paramId={paramId}
						valores={this.state.parametersApi[key]}
						handleChangeParent={this.handleChangeParent.bind(this, key, index)}
						addSection={this.addSection.bind(this, index)}
						deleteParent={this.deleteParent.bind(this, index)}
					/>
				);

			});
		}

		
		let ref = this;
		optionsWatson.push(
			<div className="row">
				<div className="col s12">
					<p className="mt-0  text-blue-low ">Parámetros API</p>
				</div>
				<div>{optionsParameters}</div>
			</div>
		);

		return (
			<form
				onSubmit={(e) => {
					this.handleSaveParemetersApi(e);
				}}
			>
				<div className="admin-parameters_container-session ">
					<div className="admin-parameters__session-header">
						<p className="admin-parameters__session-title">Configuración API</p>
						{this.state.showButtonSave && (
							<div>
								<input
									className="btn waves-effect admin-parameters__btn-save-input position-btn"
									type="submit"
									value="Guardar"
								/>
							</div>
						)}
						<Modal
							showModal={this.state.showModal}
							loadingSave={loadingSave}
							saveSuccessuful={saveSuccessuful}
							saveError={saveError}
							handleCloseModal={(event) => {
								ref.handleCloseModal(event);
							}}
						/>
					</div>
					<div className="admin-parameters__background">
						<div className=" admin-parameters__container-select">
							<select
								className="browser-default admin-parameters__client-default"
								onChange={(event) => this.handleChangeCompany(event)}
							>
								<option className="" datacompany="" value="" disabled selected>
									Seleccione una empresa
								</option>
								{optionsCompanies}
							</select>
						</div>
					</div>
					<div className="admin-parameters__container-thresholds">
						{this.state.showResults ? optionsWatson : <ContainerDefaultPlatform />}
					</div>
				</div>
			</form>
		);
	}
}

const mapStateToProps = ({ auth, parameters }) => {
	const { authUser } = auth;
	const { listAllCompanies, parametersApi, platformsCompany, loadingSave, saveSuccessuful, saveError } = parameters;

	return {
		authUser,
		listAllCompanies,
		parametersApi,
		platformsCompany,

		loadingSave,
		saveSuccessuful,
		saveError

		
	};
};

export default connect(mapStateToProps, { getAllCompanies, getParametersApi, getPlatformsCompany, putParametersApi })(
	ConfigurationApi
);
