import React from "react";
import "./containerWork.css";
import "./date.css";
import moment from "moment/min/moment-with-locales";
import excelIcon from "../../../assets/images/ic_excel.png";
import TableRow from "../TableRow/TableRow";
import DateDisplay from "../DateDisplay/DateDisplay";
import { connect } from "react-redux";
import CanvasJSReact from "../../../assets/vendors/canvasjs.react";

import {
  connectSocketsDashboard,
  removeAllSocket,
  disconnectSocket
} from "../../../appRedux/actions/Socket";
import { getThresholdsManager } from "../../../appRedux/actions/Companies";
import {
  getActiveConversationsStates,
  showConversation,
  getCanceledConversationsStates,
  getThresholds,
  getCanceledConversationsFile,
  getAgentStates
} from "../../../appRedux/actions/Conversation";

import Workbook from "react-excel-workbook";
import { saveAs } from "file-saver";
let ExcelJS = require("exceljs");

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

class ContainerWork extends React.Component {
  constructor() {
    super();
    this.M = window.M;
    this.myRef = React.createRef();
    this.dateUntil = React.createRef();
    this.dateFrom = React.createRef();
    this.chartActive = React.createRef();
  }
  async componentDidMount() {
    this.M.Tabs.init(this.Tabs);
    await this.props.removeAllSocket();
    this.props.connectSocketsDashboard();
    this.props.getActiveConversationsStates();
    this.props.getAgentStates();
    setInterval(() => {
      const { messageDashboard, thresholdsManager } = this.props;
      let activeConversationsCounts = countActiveConversations(
        messageDashboard,
        thresholdsManager
      );
      let chartConversations = buildActiveConversationsInfo(
        activeConversationsCounts
          ? activeConversationsCounts.numberFilterInQueue
          : 0,
        activeConversationsCounts
          ? activeConversationsCounts.numberFilterWaiting
          : 0,
        activeConversationsCounts
          ? activeConversationsCounts.numberFilterLate
          : 0,
        activeConversationsCounts
          ? activeConversationsCounts.numberFilterAttend
          : 0
      );
      if (this.chart) {
        this.chart.options.data[0] = chartConversations.data[0];
        this.chart.render();
      }
    }, 1000);
  }
  componentWillMount() {
    this.props.getThresholdsManager();
    this.props.getCanceledConversationsStates();
  }
  componentWillUnmount() {
    this.props.disconnectSocket();
  }
  handleDate(e) {}
  handleDownload(e) {
    e.preventDefault();
    let valueFrom = this.dateFrom.current.value;
    let valueUntil = this.dateUntil.current.value;
    this.props
      .getCanceledConversationsFile(valueFrom, valueUntil)
      .then(async () => {
        const { dataCancelDashboard } = this.props;
        let dataDashboard = dataCancelDashboard;
        let listData = [];
        dataDashboard.map(function(item, i) {
          listData.push(<Workbook.Column label="item" value="item" />);
        });
        var workbook = new ExcelJS.Workbook();
        var sheet = workbook.addWorksheet("My Sheet");
        sheet.columns = [
          {
            header: "Causa de abandono",
            key: "current_state_display_value",
            width: 25
          },
          {
            header: "Fecha de abandono",
            key: "finished_created_at",
            width: 25
          },
          { header: "Usuario", key: "user_name", width: 30 },
          {
            header: "Cliente",
            key: "company_name",
            width: 25
          },
          { header: "Correo", key: "user_email", width: 25, outlineLevel: 1 },
          {
            header: "Tiempo en espera",
            key: "queue_time",
            width: 25
          }
        ];

        dataDashboard.map(function(item, i) {
          let waitingTime;
          if (item.queue_time !== null) {
            let queue_hours = item.queue_time.hours ? item.queue_time.hours : 0;
            let queue_minutes = item.queue_time.minutes
              ? item.queue_time.minutes
              : 0;
            let queue_seconds = item.queue_time.seconds
              ? item.queue_time.seconds
              : 0;
            waitingTime =
              queue_hours + "h " + queue_minutes + "m " + queue_seconds + "s";
          }
          sheet.addRow({
            current_state_display_value: item.current_state_display_value,
            finished_created_at: item.finished_created_at,
            user_name: item.user_name,
            company_name: item.company_name,
            user_email: item.user_email,
            queue_time: waitingTime
          });
        });

        const but = await workbook.xlsx.writeBuffer();
        saveAs(new Blob([but]), "abandonos.xlsx");
      });
  }

  render() {

    let listMessages = [];
    const {
      messageDashboard,
      agentDashboard,
      thresholds,
      cancelmessageDashboard,
      thresholdsManager
    } = this.props;

    let activeConversationsCounts;
    let activeAgentsCount;
    let activeAgents = [];
    let connectedAgentsCount = 0;
    let connectedAgents =[];
    let freeAgents = [];
    let freeAgentsCount = 0

    if (agentDashboard){
      let filterConnectedAgents = agentDashboard.filter(item => item.connection_state == "CONNECTED")
      let agent_ids = filterConnectedAgents.map(function(obj) { return obj.user_id; });
      agent_ids = agent_ids.filter(function(v,i) { return agent_ids.indexOf(v) == i; });
      connectedAgents = agent_ids;
      connectedAgentsCount = connectedAgents.length;
    }
    if (messageDashboard) {
      activeConversationsCounts = countActiveConversations(
        messageDashboard,
        thresholds
      );
      if(activeConversationsCounts.numberFilterClosed===0){
        activeConversationsCounts.numberFilterClosed=messageDashboard.length
      }else{
        activeConversationsCounts.numberFilterClosed=activeConversationsCounts.numberFilterClosed
      }
      activeAgents = countActiveAgents(messageDashboard);
      activeAgentsCount = activeAgents.length;

      freeAgents = connectedAgents.filter(item => !activeAgents.includes(item));
      freeAgentsCount = freeAgents.length;
      createRowsForActiveConversations(
        messageDashboard,
        listMessages,
        thresholdsManager
      );
    }


    const chartConversations = buildActiveConversationsInfo(
      activeConversationsCounts
        ? activeConversationsCounts.numberFilterInQueue
        : 0,
      activeConversationsCounts
        ? activeConversationsCounts.numberFilterWaiting
        : 0,
      activeConversationsCounts
        ? activeConversationsCounts.numberFilterLate
        : 0,
      activeConversationsCounts
        ? activeConversationsCounts.numberFilterAttend
        : 0
    );
    let dataChartLabel;
    let dataAbandomentLabel;
    let dataConversations = chartConversations.data[0].dataPoints;

    let filterConversations = dataConversations.filter(item => item.y === 0);
 
    if (dataConversations.length === filterConversations.length) {
      dataChartLabel = (
        <p className="chart_label_canva">No hay conversaciones activas</p>
      );
    } else {
      dataChartLabel = (
        <CanvasJSChart
          //onRef={ref => (this.chart = ref)}
          className="canvas_dash"
          options={chartConversations}
        />
      );
    }

    let { numberCloseTimeOut, numberOtherClose } = countCanceledConversations(
      cancelmessageDashboard
    );
    const chartAbandoment = buildCanceledConversationsInfo(
      numberCloseTimeOut,
      numberOtherClose
    );
    let dataAbandoment = chartAbandoment.data[0].dataPoints;
    let filterdataAbandoment = dataAbandoment.filter(item => item.y === 0);
    if (dataAbandoment.length === filterdataAbandoment.length) {
      dataAbandomentLabel = (
        <p className="chart_label_canva">No hay conversaciones</p>
      );
    } else {
      dataAbandomentLabel = (
        <CanvasJSChart className="" options={chartAbandoment} />
      );
    }

    return (
      <div className="dashboard__container">
        <div className="row">
          <div>
            <div className="dashboard__header">
              <div className="col s8">                
                <span>Monitoreo en tiempo real de agente en Chat</span>
              </div>
              <div className="col s4">                 
                <DateDisplay></DateDisplay>
              </div>              
            </div>
          </div>
        </div>
        <div className ="row">
          <div className="col s12">
          <div class="row dashboard__counter">
            <div class="col custom-col grid-example">
              <p className="dashboard__counter_title">Agentes conectados</p>
              <p className="dashboard__counter_info">{connectedAgentsCount}</p>
            </div>
            <div class="col custom-col grid-example">
              <p className="dashboard__counter_title">Agentes libres</p>
              <p className="dashboard__counter_info">{freeAgentsCount}</p>
            </div>
            <div class="col custom-col grid-example">
              <p className="dashboard__counter_title">Agentes en chat</p>
              <p className="dashboard__counter_info">{activeAgentsCount}</p>
            </div>
            <div class="col custom-col grid-example">
              <p className="dashboard__counter_title two_lines">Chats esperando atención</p>
              <p className="dashboard__counter_info">{activeConversationsCounts? activeConversationsCounts.numberFilterInQueue: 0}</p>
            </div>
            <div class="col custom-col grid-example">
              <p className="dashboard__counter_title">Chats atendidos</p>
              <p className="dashboard__counter_info">{activeConversationsCounts? activeConversationsCounts.numberFilterClosed: 0}</p>
            </div>
            <div class="col custom-col grid-example">
              <p className="dashboard__counter_title">Chats abandonados</p>
              <p className="dashboard__counter_info">{numberCloseTimeOut + numberOtherClose}</p>
            </div>
            <div class="col custom-col grid-example-2">
              <p className="dashboard__counter_title">Chats totales</p>
              <p className="dashboard__counter_info">{numberCloseTimeOut + numberOtherClose + (activeConversationsCounts? activeConversationsCounts.numberFilterInQueue: 0)+ (activeConversationsCounts? activeConversationsCounts.numberFilterClosed: 0)}</p>
            </div>
          </div>
          </div>  
        </div>
        <div className="row">
          <div className="col s12">
            <ul
              className="tabs dashboard__tabs"
              ref={Tabs => {
                this.Tabs = Tabs;
              }}
            >
              <li className="tab dashboard__tab col s6">
                <a className=" dashboard__container-canvas" href="#test1">
                  <p className="dashboard__title">Monitor de conversaciones</p>
                  <div className="dashboard__canvas">
                    {dataChartLabel}
                    {/*<CanvasJSChart
                      onRef={(ref) => (this.chart = ref)}
                      className="canvas_dash"
                      options={chartConversations}

											/>*/}
                  </div>
                </a>
              </li>
              <li className="tab dashboard__tab col s6">
                <a className=" dashboard__container-canvas" href="#test2">
                  <p className="dashboard__title">Monitoreo de abandonos</p>
                  <div className="chart_abandoment">{dataAbandomentLabel}</div>
                </a>
              </li>
            </ul>
          </div>
          <div id="test1" className="col s12">
            <table className="dashboard__table">
              <tbody>
                <tr className="dashboard_table-header">
                  <th>ESTADO</th>
                  <th>AGENTE</th>
                  <th>CANAL</th>
                  <th>Grupo</th>
                  <th>Empresa</th>
                  <th className="table__two_rows">Tiempo esperando atención</th>
                  <th className="table__two_rows">Hora de inicio de conversación</th>
                  <th className="table__two_rows">Tiempo esperando respuesta</th>
                  <th className="table__two_rows">Tiempo Conversación</th>
                </tr>
                {listMessages}
              </tbody>
            </table>
          </div>
          <div id="test2" className="col s12">
            <div className="dashboard__container-date">
              <label className="dashboard__title" htmlFor="start">
                Filtro de búsqueda:
              </label>
              <form
                className="dashboard__container-form"
                onSubmit={e => {
                  this.handleDownload(e);
                }}
              >
                <div className="row">
                  <div className="col s4">
                    <label className="dashboard__title-filter">Desde</label>
                    <input
                      ref={this.dateFrom}
                      className="dashboard__input-filter"
                      type="date"
                      name="dateofbirth"
                      id="dateofbirth"
                    />
                  </div>
                  <div className="col s4">
                    <label className="dashboard__title-filter" htmlFor="">
                      Hasta
                    </label>
                    <input
                      ref={this.dateUntil}
                      className="dashboard__input-filter"
                      type="date"
                      name="dateofbirth"
                      id="dateofbirth"
                    />
                  </div>
                  <div
                    className="row text-center"
                    style={{ marginTop: "100px" }}
                  />
                </div>
                <div className="row">
                  <div className="col s12">
                    <img src={excelIcon} className="dashboard__excel-icon" />
                  </div>
                </div>
                <div className="row">
                  <div className="col s12">
                    <button
                      type="submit"
                      className="dashboard__download-button"
                    >
                      Descargar reporte
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function countCanceledConversations(cancelmessageDashboard) {
  let numberCloseTimeOut = 0;
  let numberOtherClose = 0;
  if (cancelmessageDashboard) {
    let filterCloseTimeOut = cancelmessageDashboard.filter(
      element => element.current_state_name === "closed-timeout"
    );
    let filterOtherClose = cancelmessageDashboard.filter(
      element => element.current_state_name === "canceled-refer"
    );
    numberCloseTimeOut = filterCloseTimeOut.length;
    numberOtherClose = filterOtherClose.length;
  }
  return { numberCloseTimeOut, numberOtherClose };
}

function countActiveAgents(messageDashboard){
  let filterConversationsNotNull = messageDashboard.filter(item => item.agent_id != null);
  let filterConversations = filterConversationsNotNull.filter(item => item.current_state_name !='closed-attended' && item.current_state_name !='closed-refered' && item.current_state_name !='closed-inactiveuser');
  let agent_ids = filterConversations.map(function(obj) { return obj.agent_id; });
  agent_ids = agent_ids.filter(function(v,i) { return agent_ids.indexOf(v) == i; });

  return agent_ids;
}

function countActiveConversations(messageDashboard, thresholdsManager) {
  let numberFilterAttend = 0;
  let numberFilterInQueue = 0;
  let numberFilterWaiting = 0;
  let numberFilterLate = 0;
  let numberFilterClosed = 0;
  messageDashboard.forEach(conversationState => {
    if (thresholdsManager.length > 0) {
      if (conversationState.current_state_name === "closed-attended" ||
        conversationState.current_state_name === "closed-refered" ||
        conversationState.current_state_name === "closed-inactiveuser") {
          numberFilterClosed++;
      }
      if (conversationState.current_state_name === "refering-n1") {
        numberFilterInQueue++;
      }
      if (conversationState.current_state_name === "attending-n1") {
        if (conversationState.last_message_created_at) {
          let now = moment();
          let last_message_time = moment(
            conversationState.last_message_created_at
          );
          let diff = last_message_time.diff(now);
          let diffDuration = moment.duration(diff);
          let rminutes = Math.abs(diffDuration.minutes());
          let rseconds = Math.abs(diffDuration.seconds());
          let resultSeconds = rminutes * 60 + rseconds;
          let threshold1 = thresholdsManager.find(
            t =>
              t.param_name === "THRESHOLD_WARNING_1" &&
              t.company_id === conversationState.company_id
          );
          let threshold2 = thresholdsManager.find(
            t =>
              t.param_name === "THRESHOLD_WARNING_2" &&
              t.company_id === conversationState.company_id
          );
          if (threshold1 && threshold2) {
            if (resultSeconds > threshold1.param_value) {
              if (resultSeconds > threshold2.param_value) {
                numberFilterLate++;
              } else {
                numberFilterWaiting++;
              }
            } else {
              numberFilterAttend++;
            }
          }
        } else {
          numberFilterAttend++;
        }
      }
    }
  });
  return {
    numberFilterInQueue,
    numberFilterLate,
    numberFilterWaiting,
    numberFilterAttend,
    numberFilterClosed
  };
}

function createRowsForActiveConversations(
  messageDashboard,
  listMessages,
  thresholdsManager
) {
  messageDashboard.map(function(id, i) {
    if(id.current_state_name == "closed-inactiveuser" ||
      id.current_state_name === "closed-refered" ||
      id.current_state_name === "closed-attended"){

      }else{
        listMessages.push(
          <TableRow
            key={id}
            id={id.id}
            items={id}
            agent_first_name={id.agent_first_name}
            agent_last_name={id.agent_last_name}
            state={id.current_state_display_value}
            name={id.user_name}
            corporation={id.corporation_name}
            company={id.company_name}
            company_id={id.company_id}
            initDate={id.conv_created_at}
            last_message_created_at={id.last_message_created_at}
            queue_time={id.queue_time}
            referring_created_at={id.referring_created_at}
            conversation_id={id.conversation_id}
            state_name={id.current_state_name}
            thresholdsManager={thresholdsManager}
            channelName={id.channel_name}
            initTimerChat={id.attending_created_at}
          />
        );

      }
    
  });
}

function buildCanceledConversationsInfo(numberCloseTimeOut, numberOtherClose) {
  return {
    exportEnabled: true,
    animationEnabled: true,
    data: [
      {
        type: "pie",
        startAngle: 75,
        toolTipContent: "<b>{label}</b>: {y}",
        legendText: "{label}",
        indexLabelFontSize: 22,
        indexLabel: "{label}:{y}",
        dataPoints: [
          {
            y: numberCloseTimeOut,
            label: "Fuera de tiempo",
            color: "#fd7c59"
          },
          {
            y: numberOtherClose,
            label: "Derivación cancelada",
            color: "#aecc75"
          }
        ]
      }
    ]
  };
}

function buildActiveConversationsInfo(
  numberFilterInQueue,
  numberFilterWaiting,
  numberFilterLate,
  numberFilterAttend
) {
  return {
    exportEnabled: true,
    animationEnabled: true,
    data: [
      {
        type: "pie",
        startAngle: 75,
        toolTipContent: "<b>{label}</b>: {y}",
        legendText: "{label}",
        indexLabelFontSize: 22,
        indexLabel: "{label}:{y}",
        dataPoints: [
          { y: numberFilterInQueue, label: "Esperando atención", color: "#b7b7b7" },
          { y: numberFilterWaiting, label: "Esperando respuesta", color: "#fad789" },
          { y: numberFilterLate, label: "Demora", color: "#fba375" },
          { y: numberFilterAttend, label: "Atendiendo", color: "#608aff" }
        ]
      }
    ]
  };
}

const mapStateToProps = ({ socket, conversation, auth, companies }) => {
  const { authUser } = auth;

  const {
    containerInfo,
    messageDashboard,
    agentDashboard,
    cancelmessageDashboard,
    thresholds,
    thresholdsManagersList,
    dataCancelDashboard
  } = conversation;
  const { thresholdsManager } = companies;
  return {
    authUser,
    messageDashboard,
    agentDashboard,
    containerInfo,
    cancelmessageDashboard,
    thresholds,
    thresholdsManagersList,
    dataCancelDashboard,
    thresholdsManager
  };
};

export default connect(mapStateToProps, {
  showConversation,
  getThresholds,
  getThresholdsManager,
  connectSocketsDashboard,
  removeAllSocket,
  getActiveConversationsStates,
  getAgentStates,
  getCanceledConversationsStates,
  getCanceledConversationsFile,
  disconnectSocket
})(ContainerWork);
