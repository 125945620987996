import axios from 'axios';


export default axios.create({
    baseURL: process.env.REACT_APP_URL_AUTH,
    headers: {
        'Content-Type': 'application/json',
        /*'Accept' : 'application/json',*/
        /*'Authorization' : 'Bearer <token_here>'*/

    }
});