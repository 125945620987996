import React from 'react';

class CirclePerson extends React.Component {
	getLetter(user_first_name, user_first_surname, logo) {
		let firt_letter = user_first_name === null ? '' : user_first_name.charAt(0).toUpperCase();

		let second_letter = user_first_surname === null ? '' : user_first_surname.charAt(0).toUpperCase();
		return firt_letter + second_letter;
		//  }
	}
	getClient() {}

	render() {
		const { logo } = this.props;
		return (
			<div className="circle-person">
				<img src={logo} alt="client" className="responsive-img" />
			</div>
		);
	}
}

export default CirclePerson;
