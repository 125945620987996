import {
	INIT_URL,
	ASSIGNED_COMPANIES,
	REPLIES_WELCOME,
	ADMIN_QUICK_REPLIES,
	ADMIN_AUTO_GREETING,
	SHOW_ELEMENT,
	REMOVE_REPLY,
	SHOW_OTHER_ELEMENT,
	QUICK_REPLY_GREETING,
	ADD_REPLY_WELCOME,
	ADD_QUICK_REPLIES,
	ADD_AUTO_GREETING,
	SHOW_ADMIN_REPLIES,
	SHOW_THRESHOLDS_MANAGER,
	ALL_COMPANIES
} from '../../constants/ActionTypes';

const INIT_STATE = {
	initURL: '',
	companiesAssigned: [],
	containerReplies: false,
	repliesWelcome: [],
	autoGreetingsReplies: [],
	allReplies: [],
	showElement: false,
	otherShowElement: false,
	quickRepliesGreeting: [],
	showAdmin: false,
	containerAdminReplies: [],
	containerInfo: [],
	thresholdsManager: [],
	allCompanies: []
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case INIT_URL: {
			return { ...state, initURL: action.payload };
		}
		case ASSIGNED_COMPANIES: {
			return {
				...state,
				companiesAssigned: action.payload
			};
		}
		case REPLIES_WELCOME: {
			return {
				...state,
				repliesWelcome: action.payload
			};
		}
		case ADMIN_QUICK_REPLIES: {
			return {
				...state,
				allReplies: action.payload
			};
		}
		case ADMIN_AUTO_GREETING: {
			return {
				...state,
				autoGreetingsReplies: action.payload
			};
		}
		case ADD_QUICK_REPLIES: {
			return {
				...state,
				allReplies: [ ...state.allReplies, action.payload ]
			};
		}
		case ADD_REPLY_WELCOME: {
			return {
				...state,
				repliesWelcome: [ ...state.repliesWelcome, action.payload ]
			};
		}
		case ADD_AUTO_GREETING: {
			return {
				...state,
				autoGreetingsReplies: [ ...state.autoGreetingsReplies, action.payload ]
			};
		}
		case QUICK_REPLY_GREETING: {
			return {
				...state,
				quickRepliesGreeting: action.payload
			};
		}
		case SHOW_ELEMENT: {
			return {
				...state,
				showElement: !state.showElement,
				otherShowElement: !state.otherShowElemet
			};
		}
		case SHOW_OTHER_ELEMENT: {
			return {
				...state,
				otherShowElement: !state.otherShowElemet
			};
		}
		case REMOVE_REPLY: {
			return {
				...state,
				//allReplies: state.allReplies.filter((item) => action.payload !== item)
				repliesWelcome: state.repliesWelcome.filter((x) => x.id !== action.payload),
				allReplies: state.allReplies.filter((x) => x.id !== action.payload),
				autoGreetingsReplies: state.autoGreetingsReplies.filter((x) => x.id !== action.payload)
			};
		}
		case SHOW_ADMIN_REPLIES: {
			return {
				...state,
				showAdmin: true,
				containerAdminReplies: action.payload
			};
		}
		case SHOW_THRESHOLDS_MANAGER: {
			return {
				...state,
				thresholdsManager: action.payload
			};
		}
		case ALL_COMPANIES: {
			return {
				...state,
				allCompanies: action.payload
			};
		}
		default:
			return state;
	}
};
