import {
	INIT_URL,
	INIT_QUICK_REPLIES,
	INIT_AUTO_GREETING,
	INIT_WELCOME_GREETING,
	ADD_MESSAGE,
	INIT_MESSAGE,
	START_TYPING,
	INCREMENT,
	RESET,
	TIMER,
	/*STOP_TIMER,*/
	STOP_TYPING,
	SET_INPUT,
	GET_AUTO_GREETING,
	GET_WELCOME_GREETING,
	SHOW_EMOJI,
	UPDATE_MESSAGE,
	INIT_PARAMETERS,
	SUCCESS_UPLOAD
} from '../../constants/ActionTypes';

const INIT_STATE = {
	quickReplies: [],
	autoGreetings: [],
	welcomeGreetings: [],
	parameters: [],
	autoGreeting: '',
	welcomeGreeting: '',
	messages: [],
	isTyping: false,
	second: 0,
	timerRef: null,
	message_text: '',
	isShowEmoji: false,
	dataFile: {}
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case INIT_PARAMETERS: {
			return {
				...state,
				parameters: action.payload
			};
		}

		case INIT_QUICK_REPLIES: {
			return {
				...state,
				quickReplies: action.payload
			};
		}

		case INIT_AUTO_GREETING: {
			return {
				...state,
				autoGreetings: action.payload
			};
		}

		case GET_AUTO_GREETING: {
			return {
				...state,
				autoGreeting: state.autoGreetings[Math.floor(Math.random() * state.autoGreetings.length)]
			};
		}

		case GET_WELCOME_GREETING: {
			return {
				...state,
				welcomeGreeting: state.welcomeGreetings[Math.floor(Math.random() * state.welcomeGreetings.length)]
			};
		}

		case INIT_WELCOME_GREETING: {
			return {
				...state,
				welcomeGreetings: action.payload
			};
		}

		case SUCCESS_UPLOAD: {
			return {
				...state,
				dataFile: action.payload
			};
		}

		case ADD_MESSAGE: {
			return {
				...state,
				messages: [ ...state.messages, action.payload ]
			};
		}

		case INIT_MESSAGE: {
			return {
				...state,
				messages: action.payload
			};
		}

		case START_TYPING: {
			return {
				...state,
				isTyping: true
			};
		}

		case STOP_TYPING: {
			return {
				...state,
				isTyping: false
			};
		}

		case INCREMENT: {
			return {
				...state,
				second: state.second + 1
			};
		}

		case RESET: {
			return {
				...state,
				second: 0
			};
		}

		case TIMER: {
			return {
				...state,
				timerRef: action.payload
			};
		}

		case SET_INPUT: {
			return {
				...state,
				message_text: action.payload
			};
		}

		case SHOW_EMOJI: {
			return {
				...state,
				isShowEmoji: action.payload
			};
		}

		case UPDATE_MESSAGE: {
			return {
				...state,
				messages: action.payload
			};
		}

		case INIT_URL: {
			return { ...state, initURL: action.payload };
		}

		default:
			return state;
	}
};
