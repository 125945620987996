import { INIT_URL, MESSAGE_DASHBOARD } from '../../constants/ActionTypes';

const INIT_STATE = {
	connects: [],
	initURL: '',
	messageDashboard: []
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case INIT_URL: {
			return { ...state, initURL: action.payload };
		}

		//PERMISSIONS

		default:
			return state;
	}
};
