import React from 'react';
import './containerblank.css';
import imageChat from '../../../assets/images/init-conversation.png';

class ContainerBlank extends React.Component {
	render() {
		return (
			<div className="Rectangle">
				<img src={imageChat} className="Imagen" alt="" />
				<p className="Inicia-una-conversac">Inicia una conversación dándole clic a "Atender solicitud"</p>
			</div>
		);
	}
}

export default ContainerBlank;
