import React from 'react';
import './containerDefaultPlatform.css';
import imagePlatform from '../../../assets/images/defaultPlatform.png';

class ContainerDefaultPlatform extends React.Component {
	render() {
		return (
			<div className="flex-center">
				<img src={imagePlatform} class="responsive-img " alt="default-platform" />
			</div>
		);
	}
}

export default ContainerDefaultPlatform;
