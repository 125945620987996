import React from 'react';
import './itemRoles.css';
import { connect } from 'react-redux';
import { getCompanies } from '../../../appRedux/actions/Companies';
import { getRoles } from '../../../appRedux/actions/Parameters';

import ReactSearchBox from 'react-search-box';

class ItemRoles extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			role_name: '',
			company_id: '',
			is_assigned: '',
			role_id: '',
		};
	}

	componentWillReceiveProps( newProps ) {

		this.setState({
			role_name: newProps.value.role_name,
			is_assigned: newProps.value.is_assigned,
			role_id: newProps.value.role_id,
			company_id: newProps.value.company_id
		});
	}

	componentWillMount() {
		const {  value } = this.props;

		this.setState({
			role_name: value.role_name,
			is_assigned: value.is_assigned,
			role_id: value.role_id,
			company_id: value.company_id
		});
	}

	render() {
		const {  onChange } = this.props;
		return (
			<div className="col s12 ">
						<div id="field" className="input-field-common input-field input-field-checkbox">
							<span>{this.state.role_name}</span>
							<label>
								<input
									ref={this.myCheckbox}
									dataroleid={this.state.role_id}
									id={this.state.role_name + '-' + this.state.company_id + '-' + this.state.role_id}
									name={this.state.role_name + this.state.company_id}
									className="filled-in"
									type="checkbox"
									checked={this.state.is_assigned}
									onChange={onChange}
								/>
								<span />
							</label>
						</div>
				</div>
		)
	}
}

const mapStateToProps = ({ auth, companies, users , parameters}) => {
	const { authUser } = auth;
	const { allCompanies } = companies;
	const { containerUserDetail } = users;
	const { roles } = parameters;
	return {
		authUser,
		allCompanies,
		containerUserDetail,
		roles
	};
};

export default connect(mapStateToProps, {
	getCompanies,
	getRoles
})(ItemRoles);
